import React, { useEffect, useState } from 'react'
import { AUTH_TOKEN_KEY, SESSION_KEY, USER_JSON_KEY, RETURN_KEY, saveJwt, redirectToReturnUrl } from './Utils'
import { ClientContextProvider } from './Context'
import Login from './components/login/Login'
import { ForgotPasswordPage } from 'pages/user/ForgotPasswordPage'
import { ResetPasswordPage } from 'pages/user/ResetPasswordPage'
import { ConfirmAccountPage } from 'pages/user/ConfirmAccountPage'
import UserApiService from 'utils/UserHttpUtils'

const handleLoginCallback = (tokenJson) => {
    saveJwt(tokenJson)
    sessionStorage.setItem(SESSION_KEY, tokenJson[USER_JSON_KEY].id) // Save only user id instead of all object in storage
    redirectToReturnUrl()
}

export default function LoginVerifier({ children }) {
    const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY)
    const [user, setUser] = useState(null)

    function clearLoginForm(e) {
        document.getElementById("login-form").reset();
    }

    if (!authToken) {
        const pathname = window.location.pathname
        const excludedPaths = ['', '/', '/login', '/forgot-password', '/reset-password', '/confirm-account'];
        if (!excludedPaths.includes(pathname)) {
            window.location.replace(`${origin}/login?${RETURN_KEY}=${pathname}`)
            return
        } else {
            if (pathname === '/forgot-password') {
                return <ForgotPasswordPage />
            }
            if (pathname === '/reset-password') {
                return <ResetPasswordPage />
            }
            if (pathname === '/confirm-account') {
                return <ConfirmAccountPage />
            }

            return <Login storeAuthTokens={handleLoginCallback} cancel={clearLoginForm} />
        }
    } else if (window.location.href.includes('/login')) {
        window.location.replace("/")
    }

    // When reload or refresh the page, it should load the user details from api and store into context
    useEffect(() => {
        const getLoggedInUser = async () => {
            try {
                const response = await UserApiService.fetchUser(sessionStorage.getItem(SESSION_KEY))
                setUser(response)
            } catch (error) {
                sessionStorage.clear()
                // Handle error, e.g., redirect to login page
                redirectToReturnUrl()
            }
        }

        if (authToken) {
            getLoggedInUser()
        }
    }, [authToken])


    return (
        user ? (
            <ClientContextProvider value={user}>
                {children}
            </ClientContextProvider>
        ) : (
            <></>
        )
    );

}
