import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BaseModalDialog from './BaseModalDialog'
import Box from '@mui/material/Box'
import { flexJustifyAlignCenter } from '../../../styles/GlobalSXProps'
import NTCButton from '../../inputs/buttons/NTCButton'
import React, { useContext, useEffect } from 'react'
import { ClientContext, ReauthenticationContext } from '../../../Context'
import FormTextControl from '../../inputs/forms/controls/FormTextControl'
import LoginIcon from '@mui/icons-material/Login'
import { FlashNotification } from './FlashNotification'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { AuthRoutes, REAUTHENTICATION_PATH } from 'utils/ServerRoutes'
import { SESSION_KEY } from '../../../Utils'

export default function Reauthenticator() {
    const { client } = useContext(ClientContext)
    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const theme = useTheme()
    const { t } = useTranslation()
    const reauthContext = useContext(ReauthenticationContext)

    useEffect(() => {
        if (document.location.pathname == REAUTHENTICATION_PATH) {
            reauthContext.setOpen(true)
        }
    }, [])

    return <>
        <ReauthenticationModal
            setShowNotification={setShowNotification}
            setNotificationData={setNotificationData}
            handleClose={() => {
                reauthContext.setOpen(false)
            }}/>
        {showNotification ?
            <FlashNotification open={showNotification}
                               setOpen={setShowNotification} message={notificationData.message}
                               severity={notificationData.severity}
                               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}/> :
            <></>}
    </>
}

const ReauthenticationModal = ({ setShowNotification, setNotificationData, handleClose }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const reauthContext = useContext(ReauthenticationContext)

    const showResponseStatus = (doShow, params): boolean => {
        setShowNotification(doShow)
        if (doShow) {
            setNotificationData({ message: params.message, severity: params.success ? 'success' : 'error' })
        }

        return true
    }

    // Re-set the user in the session to get the latest and correct user permissions
    const setUser = (user): boolean => {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(user))
        return true
    }

    const doLogin = async (data) => {
        const credentials = new URLSearchParams(Object.entries(data))
        const response = await fetch(`${origin}${AuthRoutes.user}?reauth=1`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: credentials.toString()
        })

        if (response.ok) {
            showResponseStatus(true, { success: true, entity: 'User', message: t('reauthenticate.success') }) &&
            setUser(await response.json()) && reauthContext.setOpen(false)
        } else {
            response.status == 401 ?
                response.json().then(data => {
                    const errorMessages = data['errors'].map(key => {
                        return t(key)
                    }).join('\n')
                    showResponseStatus(true, { success: false, entity: 'User', message: errorMessages })
                })
                :
                showResponseStatus(true, {
                    success: false,
                    entity: 'Login',
                    message: response.status === 404 ? t('errors.404') : t('errors.general')
                })
        }
    }

    return <BaseModalDialog open={reauthContext.open} handleClose={handleClose} maxWidth={'xl'}
                            modalTitle={t('reauthenticate.modalTitle')}>
        <Box>
            <Grid container display={'grid'}>
                {t('reauthenticate.info')}
                <Grid item sx={{ mt: 2, mb: 2, gap: '1.5rem', margin: '0.5rem', ...flexJustifyAlignCenter }}>
                    <ReauthenticationForm submitHandler={doLogin} cancelHandler={handleClose}/>
                </Grid>
            </Grid>
        </Box>
    </BaseModalDialog>
}

const ReauthenticationForm = ({ submitHandler, cancelHandler }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('forms.user.field.username.validation.required')),
        password: Yup.string().required(t('forms.user.field.password.validation.required'))
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    return <form className={'form'}>
        <Box onKeyDown={(event) => {event.key === 'Enter' ? handleSubmit(submitHandler)() : '' }}>
            <FormTextControl id={'username'} name={'username'}
                             label={t('forms.user.field.username.label')} {...{ register, errors }} />
            <FormTextControl id={'password'} name={'password'} type={'password'}
                             label={t('forms.user.field.password.label')} {...{ register, errors }} />

            <Box sx={{ mt: 3 }}>
                <NTCButton
                    onClick={cancelHandler}
                    text={t('common.actions.cancel')}
                    sx={{ minWidth: 150, marginRight: '1rem' }}/>
                <NTCButton onClick={handleSubmit(submitHandler)} text={t('common.actions.login')}
                           backgroundColor={theme.palette.custom.nomadRed}
                           endIcon={<LoginIcon/>}
                           sx={{ minWidth: 150 }}/>
            </Box>
        </Box>
    </form>
}
