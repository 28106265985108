import MenuItemsProps from './menuItemsProps'

const portalMenuItems: MenuItemsProps[] = [
    {
        id: 'overview',
        path: '/overview',
        class: 'dashboard-icon',
        iconId: 'overview',
        children: [],
        warning: false
    },
    {
        id: 'ntc_assets',
        path: '/ntc_assets',
        iconId: 'ntc_assets',
        warning: false
    },
    {
        id: 'users',
        path: '/users',
        iconId: 'users',
        children: [],
        warning: false
    },
    {
        id: 'roles',
        path: '/roles',
        iconId: 'roles',
        children: [],
        warning: false
    },
    {
        id: 'groups',
        path: '/groups',
        iconId: 'groups',
        children: [],
        warning: false
    },
    {
        id: 'trainings',
        path: '/trainings',
        iconId: 'trainings',
        children: [],
        warning: false
    },
    {
        id: 'organizations',
        path: '/organizations',
        iconId: 'organizations',
        children: [],
        warning: false
    }
]
export default portalMenuItems

