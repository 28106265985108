import { requestEndpoint } from '../HTTPUtils'
import { MediaItem } from 'generated/util/MediaItem'
import { MediaApiRoutes } from '../ServerRoutes'
import { HTTPMethod } from '../HttpType'

/**
 * HTTP request to post media item belongs to a wizard
 * @param data
 * @param controller
 */
export async function createMediaItem(parentId: string, formData: FormData, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${parentId}`
    return await requestEndpoint<FormData, MediaItem>(
        endpoint, formData, HTTPMethod.POST, controller
    )
}

/**
 * HTTP request to update any media in the system
 * @param data
 * @param controller
 */
export async function updateMediaItem(id: string, formData: FormData, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${id}`
    return await requestEndpoint<FormData, MediaItem>(
        endpoint, formData, HTTPMethod.PUT, controller
    )
}

/**
 * HTTP request to delete any media in the system
 * @param data
 * @param controller
 */
export async function deleteMediaItem(id: string, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${id}`
    return await requestEndpoint<string, any>(
        endpoint, id, HTTPMethod.DELETE, controller
    )
}

export async function getMediaItemByParentId(id: string, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${id}`
    return await requestEndpoint<string, MediaItem[]>(
        endpoint, id, HTTPMethod.GET, controller
    )
}

export async function updateMediaItemOrders(data: {
    parentId: string,
    media: MediaItem[]
}, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${data.parentId}/sort`

    return await requestEndpoint<MediaItem[], MediaItem[]>(
        endpoint, data.media, HTTPMethod.PUT, controller
    )
}

export async function downloadMediaItem(id, controller?: AbortController) {
    return fetch(`${MediaApiRoutes.base}/${id}/download`, { method: HTTPMethod.GET })
        .then(response => {
            if (response.ok) {
                return response.blob()
            } else {
                throw new Error(response.statusText)
            }
        })
}

export async function updateMediaItemCaption(data: { id: string, caption: string }, controller?: AbortController) {
    const endpoint = `${MediaApiRoutes.base}/${data.id}/caption`
    return await requestEndpoint<string, string>(
        endpoint, data.caption, HTTPMethod.PUT, controller
    )
}

// const MediaApiService = {
//     createMediaItem,
//     updateMediaItem,
//     deleteMediaItem,
//     getMediaItemByParentId,
//     updateMediaItemOrders,
//     downloadMediaItem,
//     updateMediaItemCaption
// }
// export default MediaApiService