import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useParams } from 'react-router-dom'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import { PageHeaderLayout } from 'components/layout/page/PageHeaderLayout'
import { useTranslation } from 'react-i18next'
import { EditOrganizationForm } from 'components/organizations/EditOrganizationForm'

function EditOrganizationPage(props) {
    const { t } = useTranslation()
    const params = useParams()
    const [showNotification, setNotification] = useState(false)
    const [notificationData, setNotificationData] = useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    return (
        <>
            <PageHeaderLayout title={`${t('common.actions.edit')} ${t('forms.organization.title')}`}
                              headerIcon={<EditIcon/>}>
                <EditOrganizationForm organizationId={params.id} showResponseStatus={showResponseStatus}/>
            </PageHeaderLayout>
            {showNotification ?
                <FlashNotification open={showNotification}
                                   setOpen={setNotification} message={notificationData.message}
                                   severity={notificationData.severity}/> :
                <></>}
        </>
    )
}

export default EditOrganizationPage