@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(fonts/Montserrat-Regular.ttf) format('truetype');
}

body.sb-show-main.sb-main-padded {
    padding: 0;
}

.list-item-icon {
    margin-left: 12rem;
    margin-right: 0;
}

.drawer-root {
    position: absolute;
    left: 0;
    top: 0;
    background: #141617;
    border-radius: 0 0.703rem 0.703rem 0;
    font-family: 'Montserrat', serif;
}

.drawer-root .Mui-selected path {
    stroke: #fff;
}

.drawer-root .Mui-selected .dashboard-icon path {
    fill: #fff;
}

.drawer-root .nav-menu-items a.Mui-selected {
    background: #CE1B22;
    border-radius: 0.352rem;
}

.drawer-root .nav-menu-items a.Mui-selected .MuiListItemIcon-root {
    opacity: 0.9;
    transform: scale(0.9);
}

.nav-menu-items .MuiTypography-root {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Montserrat', serif;
}

ul.multilevel {
    font-size: .645rem !important;
    padding-left: 1.25rem;
}

ul.multilevel.popup {
    padding-left: 0;
}

.MuiListItemIcon-root {
    padding-right: 8px;
    opacity: 0.7;
}

.MuiListItemIcon-root.offline svg {
    fill: red;
    margin-left: -.313rem;
}

.MuiListItemIcon-root.online svg {
    fill: green;
    margin-left: -.313rem;
}

.left-col-branding {
    position: absolute;
    color: #717171;
    width: 500px;
    left: -222px;
    bottom: 130px;
    font-size: 4em;
    text-transform: uppercase;
    font-weight: 700;
    transform: rotate(-90deg) scale(0.55);
    transition: opacity 0.6s ease-out;
    z-index: 1;
}

.left-col-branding span {
    font-size: 2rem;
    color: #9A9999;
}

/* MEDIA QUERIES */
@media only screen and (max-height: 700px) {

    /*  Try to keep the brand text from overlapping with the containers */
    .left-col-branding {
        font-size: 1.4em;
    }

    .left-col-branding span {
        font-size: 0.7em;
    }

    /* Shrink the containers */
    .container {
        transform: scale(0.7);
        margin: 0px 0;
    }
}