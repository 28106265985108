import React, { useContext, useEffect, useState } from 'react'
import { AssetList } from './AssetList'
import ConfirmModalDialog from 'components/display/modals/ConfirmModalDialog'
import { useTranslation } from 'react-i18next'
import { AssetForm } from './AssetForm'
import { AssetApiService } from 'utils/HTTP/AssetUtils'
import { handleException } from 'utils/HTTPUtils'
import { ClientContext, PageContext } from 'Context'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormMode } from 'components/inputs/forms/Forms'
import AssetTransferForm from './AssetTransferForm'

export const AssetManagement = ({ showResponseStatus }) => {

    const { t } = useTranslation()
    const [action, setAction] = useState(null)
    const { client } = useContext(ClientContext)

    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { setLoadingPanel } = useContext(PageContext)

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            if (pathname.includes('/edit')) {
                setAction(FormMode.EDIT)
            } else if (pathname.includes('/transfer')) {
                setAction('transfer')
            }
            setSelectedId(id)
        } else if (pathname.includes('create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/ntc_assets')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])


    const responses = AssetApiService.fetchAssets()

    const actionButtonHandler = (command: string, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (command === 'delete') {
            setOpenConfirmDialog(true)
        } else if (command === 'edit') {
            navigate(`${item.id}/edit`)
        } else if (command === 'create') {
            navigate(`create`)
        } else if (command === 'transfer') {
            navigate(`${item.id}/transfer`)
        }
    }

    const handleCloseModal = () => {
        responses.refetch()
        navigate('/ntc_assets')
    }

    const onActionClick = (action) => {
        let requestPromise
        setLoadingPanel(true)

        if (action === 'delete') {
            requestPromise = AssetApiService.deleteAsset(selectedId)
        }
        requestPromise
            .then(() => showResponseStatus({ success: true, entity: 'Asset', message: t(`common.notifications.${action}`, { name: t('forms.asset.title') }) }))
            .catch(async (ex: any) => {
                const errorMessage = await handleException(ex)
                showResponseStatus({ success: false, entity: 'Asset', message: errorMessage })
            })
            .finally(() => {
                setOpenConfirmDialog(false)
                handleCloseModal()
                setLoadingPanel(false)
            })
    }

    return <>
        <AssetList rows={responses.result || []}
            actionButtonHandler={actionButtonHandler} />

        {(() => {
            if (action === 'create' || action === 'edit') {
                return (
                    <AssetForm
                        assetId={selectedId}
                        mode={action}
                        showResponseStatus={showResponseStatus}
                        handleCloseModal={handleCloseModal}
                    />
                )
            }

            if (action === 'transfer') {
                return (
                    <AssetTransferForm
                        assetId={selectedId}
                        organizationId={client.org?.id}
                        showResponseStatus={showResponseStatus}
                        handleClose={handleCloseModal}
                    />
                )
            }


            if (action === 'delete') {
                return <ConfirmModalDialog
                    modalTitle={t(`common.actions.${action}`) + ' ' + t('forms.asset.title')}
                    open={openConfirmDialog}
                    maxWidth={'md'}
                    handleClose={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleCancel={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleConfirm={() => {
                        onActionClick(action)
                    }}
                    confirmMessage={t(`forms.asset.modal.confirm.${action}.message`)}
                />
            }

            return null
        })()}
    </>
}
