import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmModalDialog from 'components/display/modals/ConfirmModalDialog'
import { useLocation, useNavigate } from 'react-router-dom'
import OrganizationList from './OrganizationList'
import OrganizationApiService from 'utils/HTTP/OrganizationUtils'
import { OrganizationFormDialog } from './OrganizationForm'
import { FormMode } from 'components/inputs/forms/Forms'

export function OrganizationManagement({ showResponseStatus }) {
    const { t } = useTranslation()
    const responses = OrganizationApiService.fetchOrganizations()

    const [action, setAction] = useState<FormMode | null>(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()

    useEffect(() => {
        if (pathname.includes('/create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/organizations')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])

    const actionButtonHandler = (command, item) => {
        setSelectedId(item?.id)
        setAction(command)
        if (command === 'delete') {
            setOpenConfirmDialog(true)
        } else if (command === 'edit') {
            navigate(`/organizations/edit/${item?.id}`)
        } else if (command === 'create') {
            navigate(`/organizations/create`)
        }
    }
    const handleCloseModal = () => {
        navigate('/organizations')
        responses.refetch()
        setAction(null)
    }

    const onActionClick = (action) => {
        OrganizationApiService.deleteOrganization(selectedId).then(res => {
            showResponseStatus({ success: true, entity: 'Organization', message: t(`common.notifications.${action}`, { name: t('forms.organization.title')}) })
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Organization', message: ex.message })
        }).finally(() => {
            setOpenConfirmDialog(false)
            handleCloseModal()
        })
    }

    return (
        <>
            <OrganizationList items={responses.result || []} actionButtonHandler={actionButtonHandler} />
            {(action === 'create') ?
                <OrganizationFormDialog organizationId={selectedId} mode={action}
                    showResponseStatus={showResponseStatus}
                    organizations={responses.result}
                    handleCloseModal={handleCloseModal} /> : <></>}

            {action === 'delete' ? (
                <ConfirmModalDialog
                    modalTitle={t(`common.actions.delete`) + ' ' + t(`forms.organization.name`)}
                    open={openConfirmDialog}
                    maxWidth={'md'}
                    handleClose={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleCancel={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleConfirm={() => { onActionClick(action) }}
                    confirmMessage={t(`forms.organization.modal.confirm.delete.message`)} />) : <></>}
        </>
    )
}