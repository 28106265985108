import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModuleAdjuster } from 'components/modules/useModuleAdjuster'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import { ModuleGridLayout } from 'components/modules/ModuleGridLayout'
import { ClientContext } from 'Context'
import { UiModuleProps, UiModuleType } from 'components/modules/UiModuleProps'

// For now module config is hardcode, later it should come from API
// we will update this array on the basis of api response
const _modules: UiModuleProps[] = [
    {
        id: 'assetTracker',
        name: { value: `modules.map.type.assetTracker` },
        type: UiModuleType.ASSETTRACKER,
        config: { dimension: {}, id: 'assetTracker', layerSettings: { showLiveLocation: true, showRoutePath: true } }
    },
    {
        id: 'weather',
        name: { value: `modules.weather.name` },
        type: UiModuleType.WEATHER,
        config: {}
    },
    {
        id: 'news',
        name: { value: `modules.news.name` },
        type: UiModuleType.NEWS,
        config: {}
    }
]

function DashboardPage() {
    const [showNotification, setNotification] = useState(false)
    const [notificationData, setNotificationData] = useState({ message: '', severity: 'success' })
    const [modules, setModules] = useState<UiModuleProps[]>([])
    const [layouts, setLayouts, setStartAutoSave] = useModuleAdjuster(modules, 'portal-dashboard', true)

    const [layoutHeight, setLayoutHeight] = useState(0)     // EditableLayout
    const [editMode, setEditMode] = useState(false)         // EditableLayout


    const { client } = useContext(ClientContext)

    useEffect(() => {
        setStartAutoSave(false) // Auto saving should start only when item dragged or resized
        setModules(_modules)
    }, [])


    // @ts-ignore - it's saying mode doesn't exist
    const access = client.access


    return <>
        {showNotification &&
            <FlashNotification
                open={showNotification}
                setOpen={setNotification}
                message={notificationData.message}
                severity={notificationData.severity}
            />
        }
        <ModuleGridLayout
            modules={modules}
            layouts={layouts} setLayouts={setLayouts}
            layoutHeight={layoutHeight} setLayoutHeight={setLayoutHeight}
            editMode={editMode} setEditMode={setEditMode}
            setStartAutoSave={setStartAutoSave}
            emptyMsg={'modules.dashboard.empty'}
        />
    </>
}

export default DashboardPage
