import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlashNotification } from 'components/display/modals/FlashNotification'
import { ResetPassword } from 'components/user/ResetPassword'

export function ResetPasswordPage() {
    const { t } = useTranslation()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const reset_token = urlParams.get('reset_token');
    const email = urlParams.get('email');
    const name = urlParams.get('name');

    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    return (
        <>
            <div className={'form-container'}>
                <div className={'background'}>
                    <form className={'form reset-form'}>
                        <div className="image" />
                        <ResetPassword
                            showResponseStatus={showResponseStatus}
                            reset_token={reset_token}
                            user={{ email, name }}
                        />
                    </form>
                </div>
            </div>
            {showNotification ?
                <FlashNotification open={showNotification}
                    setOpen={setNotification} message={notificationData.message}
                    severity={notificationData.severity} /> :
                <></>}
        </>
    )
}
