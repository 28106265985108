import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientContext, PageContext, setClientSession, setImpersonating } from '../../Context'
import { Group } from 'generated/auth/Group'
import { OrganizationForm } from './OrganizationForm'
import { FormMode } from 'components/inputs/forms/Forms'
import UserList from 'components/user/UserList'
import Divider from '@mui/material/Divider'
import UserForm from 'components/user/UserForm'
import ConfirmModalDialog from '../display/modals/ConfirmModalDialog'
import UserApiService from 'utils/UserHttpUtils'
import { AssetApiService } from 'utils/HTTP/AssetUtils'
import OrganizationApiService from 'utils/HTTP/OrganizationUtils'
import { useNavigate } from 'react-router-dom'
import { CardHeader, Grid, useTheme } from '@mui/material'
import { getIconById } from 'static/svgs/icons'
import { UserRoleAssignment } from 'components/user/UserRoleAssignment'
import { handleException } from 'utils/HTTPUtils'
import { AssetList } from 'components/assets/AssetList'
import { AssetForm } from 'components/assets/AssetForm'
import AssetTransferForm from 'components/assets/AssetTransferForm'

function OrganizationInfo({ organizationId, showResponseStatus }) {
    const navigate = useNavigate()

    function handleCancel() {
        navigate(`/organizations`)
    }

    return (
        <OrganizationForm
            organizationId={organizationId}
            showResponseStatus={showResponseStatus}
            handleCancel={handleCancel}
            mode={FormMode.EDIT}
        />
    )
}

function ContentHeader({ theme, title, avatar, margin = null }) {
    return (
        <CardHeader avatar={avatar} title={title} sx={{
            borderBottom: theme.palette.custom.moduleBorder,
            '& svg': {
                maxWidth: '20px',
                fill: `${theme.palette.custom.menuIcon}`,
                color: `${theme.palette.custom.menuIcon}`,
                'path': {
                    fill: 'primary.breadCrumbColor'
                }
            },
            '& .MuiCardHeader-title': {
                fontWeight: 400,
                fontSize: 16
            },
            ...(margin && { margin })
        }} />
    )
}

function OrganizationUsers({ organizationId, showResponseStatus }) {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const [action, setAction] = useState(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { setLoadingPanel } = useContext(PageContext)
    const responses = OrganizationApiService.fetchOrgUsers(organizationId)

    const actionButtonHandler = (command, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (['delete', 'lock', 'unlock', 'confirm_account', 'impersonate'].includes(command)) {
            setOpenConfirmDialog(true)
        }
    }

    const handleCloseModal = () => {
        responses.refetch()
        setAction(null)
    }
    function filterItemById(id: any) {
        return responses.result?.find(user => user.id === id)
    }

    const onActionClick = (action) => {
        let requestPromise
        setLoadingPanel(true)
        if (action === 'delete') {
            requestPromise = UserApiService.deleteUser(selectedId)
        } else if (action === 'confirm_account') {
            requestPromise = UserApiService.resendAccountConfirmEmail({ user: { email: filterItemById(selectedId)?.email } })
        }
        else if (action === 'lock' || action === 'unlock') {
            requestPromise = UserApiService.updateUserLockStatus(selectedId, action === 'lock')
        } else if (action === 'impersonate') {
            requestPromise = UserApiService.impersonate(responses.result.find(user => user.id === selectedId))
        }

        requestPromise
            .then((res) => {
                if (action === 'impersonate') {
                    setClientSession(res.id)
                    setImpersonating(true)
                    window.location.href = '/'
                }
                showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.${action}`) })
            })
            .catch(async (ex: any) => {
                const errorMessage = await handleException(ex)
                showResponseStatus({ success: false, entity: 'User', message: errorMessage })
            })
            .finally(() => {
                setAction(null)
                setOpenConfirmDialog(false)
                handleCloseModal()
                setLoadingPanel(false)
            })
    }

    return (
        <>
            <ContentHeader
                title={t('common.actions.add') + '/' + t('common.actions.edit') + ' ' + t('forms.user.title')}
                theme={theme} avatar={getIconById('users')} />

            <UserList users={responses.result || []}
                actionButtonHandler={actionButtonHandler}
                page={'organization'}
            />

            {(() => {
                if (action === 'create' || action === 'edit') {
                    return (
                        <UserForm
                            userId={selectedId}
                            mode={action}
                            showResponseStatus={showResponseStatus}
                            handleCloseModal={handleCloseModal}
                            actionCallback={() => { }}
                            page={'organization'}
                            organizationId={organizationId}
                        />
                    )
                }

                if (['delete', 'lock', 'unlock', 'confirm_account', 'impersonate'].includes(action)) {
                    return <ConfirmModalDialog
                        modalTitle={t(`forms.user.modal.confirm.${action}.title`)}
                        open={openConfirmDialog}
                        maxWidth={'md'}
                        handleClose={() => {
                            setOpenConfirmDialog(false)
                            handleCloseModal()
                        }}
                        handleCancel={() => {
                            setOpenConfirmDialog(false)
                            handleCloseModal()
                        }}
                        handleConfirm={() => {
                            onActionClick(action)
                        }}
                        confirmMessage={t(`forms.user.modal.confirm.${action}.message`, { user: filterItemById(selectedId) })}
                    />
                }

                if (action === 'assign') {
                    return <UserRoleAssignment userId={selectedId}
                        handleCloseModal={handleCloseModal} showResponseStatus={showResponseStatus} organizationId={organizationId}
                    />
                }

                return null
            })()}
        </>)
}

const OrganizationAssets = ({ showResponseStatus, organizationId }) => {

    const theme = useTheme()
    const { t } = useTranslation()
    const [action, setAction] = useState(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { setLoadingPanel } = useContext(PageContext)

    const responses = OrganizationApiService.fetchOrgAssets(organizationId)

    const actionButtonHandler = (command: string, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (command === 'delete') {
            setOpenConfirmDialog(true)
        }
    }

    const handleCloseModal = () => {
        responses.refetch()
        setAction(null)
    }

    const onActionClick = (action) => {
        let requestPromise
        setLoadingPanel(true)

        if (action === 'delete') {
            requestPromise = AssetApiService.deleteAsset(selectedId)
        }
        requestPromise
            .then(() => showResponseStatus({ success: true, entity: 'Asset', message: t(`common.notifications.${action}`, { name: t('forms.asset.title') }) }))
            .catch(async (ex: any) => {
                const errorMessage = await handleException(ex)
                showResponseStatus({ success: false, entity: 'Asset', message: errorMessage })
            })
            .finally(() => {
                setOpenConfirmDialog(false)
                handleCloseModal()
                setLoadingPanel(false)
            })
    }

    return <>
        <ContentHeader
            title={t('common.actions.add') + '/' + t('common.actions.edit') + ' ' + t('forms.asset.title')}
            theme={theme} avatar={getIconById('assets')} margin={'40px 0px 20px'} />

        <AssetList rows={responses.result || []}
            actionButtonHandler={actionButtonHandler} page='organization' />

        {(() => {
            if (action === 'create' || action === 'edit') {
                return (
                    <AssetForm
                        organizationId={organizationId}
                        assetId={selectedId}
                        mode={action}
                        showResponseStatus={showResponseStatus}
                        handleCloseModal={handleCloseModal}
                    />
                )
            }

            if (action === 'transfer') {
                return (
                    <AssetTransferForm
                        assetId={selectedId}
                        organizationId={organizationId}
                        showResponseStatus={showResponseStatus}
                        handleClose={handleCloseModal}
                    />
                )
            }

            if (action === 'delete') {
                return <ConfirmModalDialog
                    modalTitle={t(`common.actions.${action}`) + ' ' + t('forms.asset.title')}
                    open={openConfirmDialog}
                    maxWidth={'md'}
                    handleClose={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleCancel={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleConfirm={() => {
                        onActionClick(action)
                    }}
                    confirmMessage={t(`forms.asset.modal.confirm.${action}.message`)}
                />
            }

            return null
        })()}
    </>
}


export function EditOrganizationForm({ organizationId, showResponseStatus }) {
    const { t } = useTranslation()
    console.log(organizationId)
    return (
        <>
            <OrganizationInfo {...{ organizationId, showResponseStatus }} />
            <OrganizationUsers organizationId={organizationId} showResponseStatus={showResponseStatus} />
            <OrganizationAssets organizationId={organizationId} showResponseStatus={showResponseStatus} />
        </>
    )
}