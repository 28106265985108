import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { english } from './translations/en/us'
import { spanish } from './translations/es/mx'
import { Language } from 'generated/client/Language'

i18n.use(initReactI18next).init({
    resources: {
        // Custom translation files located in browser/js/src/i18n/translations
        // en and es must match menu items in LanguageChanger.tsx for i18n to work
        [Language.EN_US]: english,
        [Language.ES_MX]: spanish
    },
    lng: Language.EN_US,
    fallbackLng: Language.EN_US,
    lowerCaseLng: true,
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if (format === 'uppercase') return value?.toUpperCase()
            return value
        }
    }
})
export default i18n
