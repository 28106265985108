import React, { useRef } from 'react'
import ReactMapGL from 'react-map-gl'
import { Box } from '@mui/material'
import { DefaultMap } from 'constants/Map'
import { MapConfigProps } from './mapControlProps'
import 'mapbox-gl/dist/mapbox-gl.css'
import './MapControl.css'

const BaseMapLayer = ({
    id,
    apiKey = DefaultMap.token,
    containerStyle,
    center = DefaultMap.center,
    styleType = DefaultMap.styleType,
    zoomLevel = DefaultMap.Zoom,
    mapRef,
    children
}: MapConfigProps) => {

    const [viewState, setViewState] = React.useState({
        ...center,
        zoom: zoomLevel
    })

    const handleMapLoad = () => {
        if (mapRef.current) {
            const map = mapRef.current.getMap()
            map.resize()
        }
    }

    return (
        <>
            <Box id={id}></Box>
            <ReactMapGL
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                initialViewState={{
                    ...center,
                    zoom: zoomLevel
                }}
                id={id}
                style={{ ...containerStyle }}
                mapboxAccessToken={apiKey}
                mapStyle={styleType}
                onLoad={() => handleMapLoad()}
            >
                {children}
            </ReactMapGL>

        </>

    )
}
export default BaseMapLayer