/**
 * The number of passwords we maintain in the password history
 */
export const PASSWORD_HISTORY_SIZE = 5

/**
 * A list of all the special password characters we allow
 */
export const SPECIAL_CHARS = '!@#\$%^&*()_='

/**
 * The shortest STIG compliant password we allow
 */
export const STIG_MINIMUM_PASSWORD_LENGTH = 15

/**
 * The shortest non-STIG compliant password we allow
 */
export const NON_STIG_MINIMUM_PASSWORD_LENGTH = 8

/**
 * The longest STIG compliant password we allow
 */
export const MAXIMUM_PASSWORD_LENGTH = 50

/**
 * The regular expression we use to test password format validity
 * Pulled from here:
 *  https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
 */
export const STIG_PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$' + SPECIAL_CHARS + '])[A-Za-z\\d$' + SPECIAL_CHARS + ']{' + STIG_MINIMUM_PASSWORD_LENGTH + ',' + MAXIMUM_PASSWORD_LENGTH + '}$')

/**
 * The regular expression we use to test password format validity
 * Pulled from here:
 *  https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
 */
export const NON_STIG_PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$' + SPECIAL_CHARS + '])[A-Za-z\\d$' + SPECIAL_CHARS + ']{' + NON_STIG_MINIMUM_PASSWORD_LENGTH + ',' + MAXIMUM_PASSWORD_LENGTH + '}$')
