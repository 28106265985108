import React from 'react'
// WARNING
// The implementation of this library only exists in class based react.
// There are no tutorials or documentation that show this library being used in a functional paradigm
// You must fully read and understand the library documentation in order to change this component
// Change this file at your own risk
// https://github.com/react-grid-layout/react-grid-layout
// https://isamatov.com/react-grid-layout-tutorial/
// https://stackoverflow.com/questions/35689302/programmatically-change-height-width-of-items-in-react-grid-layout
// Forward the react ref down a level to this component in order to concisely wrap our children components
export const NTCGridItem = React.forwardRef(({
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
    key,
    ...props
}, ref) => {
    return (
        // Must forward all props
        <div style={{ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp}
             onTouchEnd={onTouchEnd}>
            {children}
        </div>
    )
})
