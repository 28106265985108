/* Hide the scrollbar track */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Define the appearance of the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
}

/* Define the appearance of the scrollbar corner */
::-webkit-scrollbar-corner {
    background-color: transparent;
}

/* Adjust the size of the scrollbar */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.dev-env-Development {
    background-color: green;
    padding: 10px;
    border-radius: 5px;
}

.dev-env-Staging {
    background-color: yellow;
    padding: 10px;
    border-radius: 5px;
}

.dev-env-Production {
    display: none;
}