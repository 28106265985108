import React, { useContext, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { ClientContext } from 'Context'
import { Language } from 'generated/client/Language'
import UserApiService from 'utils/UserHttpUtils'

interface LanguageChangerProps {
    callback?: (command, value) => void
    showResponseStatus?: any
}

/**
 * The label to be displayed over the one way safety slider
 * @param callback function to be executed on callback
 */
const LanguageChanger = ({ callback, showResponseStatus }: LanguageChangerProps) => {
    const { t, i18n } = useTranslation()
    const { client, setClient } = useContext(ClientContext)
    const [language, setLanguage] = useState(i18n.language || client?.locale || Language.EN_US)

    const handleChange = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value
        setLanguage(selectedValue)
        i18n.changeLanguage(selectedValue)
        // Update user language and handle errors
        const { first_name, last_name, email, id } = client
        UserApiService.updateProfile({ user: { locale: selectedValue } }).then(res => {
            setClient({ ...client, ...res }) // Refresh client data once user information modified
            showResponseStatus({ success: true, entity: 'User', message: t('menu.languageUpdated') })
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'User', message: ex.message })
        })
    }

    return (
        <Select
            className={'lang-selector'}
            value={language}
            onChange={handleChange}
            variant="standard"
            disableUnderline
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            {Object.values(Language).map((lang: Language) => (
                <MenuItem key={lang} value={lang}>
                    {t(`lang.${lang.replace(/-/g, '.')}`)}
                </MenuItem>
            ))}
        </Select>
    )
}

export default LanguageChanger
