import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { toggle } from 'redux/booleanSlice'
import './SidebarLayout.css'
import { NavMenuItems } from './navbar/SideNavMenus'
import { NomadIcon } from 'static/svgs/icons'
import SideNav from './navbar/SideNav'

const SidebarLayout = ({ menuData, drawerWidth }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const drawerTracker = useSelector((state) => state.drawerTracker.value)
    return (
        <SideNav
            openSidebar={drawerTracker}
            toggleSidebar={() => dispatch(toggle())}
            logoIcon={<NomadIcon />}
            drawerWidth={drawerWidth}>
            <NavMenuItems currentPath={location.pathname} elementType={RouterLink} menu={menuData}
                drawerWidth={drawerWidth} />

            <div className={'left-col-branding'}>NTC <span>Portal</span></div>

        </SideNav>
    )
}
export default SidebarLayout

