import * as React from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'

export default function WeatherSearchInput({ setKeyword, keyword }) {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = React.useState(keyword)
    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const handleSearch = () => {
        setKeyword(inputValue)
    }

    return (
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('modules.weather.search')}
                inputProps={{ 'aria-label': t('modules.weather.search') }}
                value={inputValue}
                onChange={handleInputChange}
            />
            <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={handleSearch}>
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}