import React from 'react'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import { SystemSettingIcon } from 'static/svgs/icons'
import { PageHeaderLayout } from 'components/layout/page/PageHeaderLayout'
import { useTranslation } from 'react-i18next'
import { OrganizationManagement } from 'components/organizations/OrganizationManagement'

function OrganizationPage(props) {
    const { t } = useTranslation()
    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    return (
        <>
            <PageHeaderLayout title={`List`} headerIcon={<SystemSettingIcon/>}>
                <OrganizationManagement showResponseStatus={showResponseStatus}/>
            </PageHeaderLayout>
            {showNotification ?
                <FlashNotification open={showNotification}
                                   setOpen={setNotification} message={notificationData.message}
                                   severity={notificationData.severity}/> :
                <></>}
        </>
    )
}

export default OrganizationPage