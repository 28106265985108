import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
    name: 'themeToggle',
    initialState: {
        value: localStorage.getItem('themeTracker') ? localStorage.getItem('themeTracker') : 'lightTheme'
    },
    reducers: {
        toggleTheme: (state) => {
            if (state.value === 'lightTheme') {
                state.value = 'darkTheme'
            } else {
                state.value = 'lightTheme'
            }
            localStorage.setItem('themeTracker', state.value)
        }
    }
})
// Action creators are generated for each case reducer function
export const { toggleTheme } = themeSlice.actions
export default themeSlice.reducer
