import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { AssetApiService } from "../../utils/HTTP/AssetUtils"
import { Asset } from '../../generated/asset/Asset'
import { PageContext } from 'Context'
import './Asset.css'
import { ASSET, REMOTE_CONTROL, isAuthorized } from 'generated/auth/Permissions'
import { useTranslation } from 'react-i18next'

function AssetFrame() {
    const [ntcAsset, setNtcAsset] = useState<Asset>(null)
    const [jwt, setJwt] = useState(null)
    const { id } = useParams()
    const { setPageEntity } = useContext(PageContext)
    const { t } = useTranslation()

    useEffect(() => {
        loadJwt()
        loadAssetData()
    }, [id])

    var loadAssetData = () => {
        if (id != undefined && id != 'undefined') {
            AssetApiService.getAssetById(id).then(assetData => {
                setNtcAsset(assetData)
                setPageEntity(assetData)
            });
        }
    }

    var loadJwt = () => {
        if (id != undefined && id != 'undefined') {
            AssetApiService.getJwt(id).then(jwtData => {
                setJwt(jwtData)
            });
        }
    }

    // var sendJwtToIframe = () => {
    //     const jwtToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
    //     const iframe = document.getElementById("ntc-asset-iframe")
    //     const scriptTag = document.createElement("script");
    //     const jsCode = `
    //         window.addEventListener('message', (event) => {
    //             if (event.data) {
    //                 // console.log(event.data);
    //                 // alert("JWT received: \\n" + event.data['token'])
    //             }
    //         })
    //         `

    //     scriptTag.type = "text/javascript";
    //     scriptTag.text = jsCode;
    //     scriptTag.async = true;

    //     iframe.contentDocument.body.appendChild(scriptTag)
    //     iframe.contentWindow.postMessage({ token: jwtToken }, '*')
    // }

    return (
        <>
            {ntcAsset?.remote_host != null && isAuthorized(ASSET, REMOTE_CONTROL) ?
                <>
                    <h1>
                        Remote control of <span className="remote-control-asset-name">{ntcAsset.name}</span>
                        <br />
                        <span className="remote-control-asset-warning">{t('forms.asset.warning')}</span>
                    </h1>
                    <iframe
                        id="ntc-asset-iframe"
                        src={ntcAsset.remote_host + "?token=" + jwt}>
                    </iframe>
                </>
                : null
            }
        </>
    );
}

export default AssetFrame;