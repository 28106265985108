import React, { useContext, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { useTheme } from '@emotion/react'
import { PageContext } from 'Context'


export const LoadingPanel = () => {
    const theme = useTheme()
    const { loading } = useContext(PageContext)

    return (
        <Backdrop
            sx={{
                color: theme.palette.custom.nomadRed, zIndex: 1400,
                position: 'absolute', height: '100%', width: '100%',
                backgroundColor: theme.palette.custom.backDropColor
            }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

