import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { Layer, Source, useMap } from 'react-map-gl'
import { MapLinePathProps } from './mapControlProps'
import { MarkerPopup } from './MarkerLayer'
import Box from '@mui/material/Box'
import PopupNavigationIcons from './layers/PopupNavigationIcons'
import IconDesign from './layers/PopupNavigationIcons'

const MapLinePath = ({ layer, geoJson }: MapLinePathProps) => {
    const theme = useTheme()
    return (
        <Source id={`source-${layer.id}`} type={'geojson'} data={geoJson}>
            <Layer
                id={`line-${layer.id}`}
                type={'line'}
                layout={{
                    'line-join': layer.layout?.['line-join'] || 'round',
                    'line-cap': layer.layout?.['line-cap'] || 'round'
                }}
                paint={{
                    ...(layer.paint ?? {}),
                    'line-color': layer.paint?.['line-color'] || theme.palette.custom.sliderBlue,
                    'line-width': layer.paint?.['line-width'] || 2,
                }}
                minzoom={1}
                filter={['==', '$type', 'LineString']}
            />

            <Layer id={`line-text-${layer.id}`} type={'symbol'} minzoom={1} layout={{
                'symbol-placement': 'line',
                'text-field': '{title}',
                'text-size': 14
            }}></Layer>

            <Layer minzoom={1} id={`line-stop-${layer.id}`} type={'circle'}
                paint={{
                    'circle-radius': 20,
                    'circle-color': theme.palette.custom.nomadRed
                }}
                filter={['==', '$type', 'Point']}
            ></Layer>

            {/* <RouteStoppageDetail layerId={`line-stop-${layer.id}`} /> */}
        </Source>
    )
}

const MapLinesPaths = ({ mapLinePaths = [], selectedLinePath, setSelectedLinePath }) => {
    return (
        <>
            {mapLinePaths.map((linePath, index) => {
                return <MapLinePath key={index} {...linePath}></MapLinePath>
            })}
        </>
    )
}

const RouteStoppageDetail = ({ layerId }) => {
    const { current: map } = useMap()
    const theme = useTheme()
    const handleClosePopup = () => setFeature(null)
    const [feature, setFeature] = useState(null)

    map.on('click', `${layerId}`, (ev) => {
        setFeature(ev.features[0])
    })
    map.on('mouseenter', `${layerId}`, () => {
        map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', `${layerId}`, () => {
        map.getCanvas().style.cursor = 'grab'
        //setFeature(null)
    })

    return (
        <>
            {feature ? <MarkerPopup marker={feature} handleClosePopup={handleClosePopup}>
                <IconDesign feature={feature}></IconDesign>
            </MarkerPopup> : <></>}
        </>
    )
}

export {
    MapLinesPaths,
    MapLinePath
}
