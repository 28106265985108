import React from 'react'
import { Marker, Popup } from 'react-map-gl'
import { MapMarkerProps } from './mapControlProps'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PlaceIcon from '@mui/icons-material/Place'
import PulsingCircle from '../../../static/svgs/PulsingCircle'
import { useTheme } from '@mui/material'

const MarkerPopup = ({ children, marker, handleClosePopup }) => {
    const [longitude, latitude] = marker?.geometry?.['coordinates']

    return (
        marker &&
        <Popup
            longitude={longitude}
            latitude={latitude}
            closeButton={true}
            closeOnClick={false}
            anchor="top"
            onClose={() => handleClosePopup(false)}>
            {children}
        </Popup>
    )
}

const getMarkerIcon = (type, handleClick) => {
    /*
     TO DO
     Add more type as per requirement
    */
    return type === 'asset' ? <LocalShippingIcon onClick={handleClick}/> : type === 'pulsing' ? <PulsingCircle
        onClick={handleClick}/> : <PlaceIcon onClick={handleClick}/>
}

const MapMarker = ({ geometry, properties, handleClick, children }: MapMarkerProps) => {
    const theme = useTheme()
    const [longitude, latitude] = geometry['coordinates']
    return (
        <Marker
            color={theme.palette.custom.nomadRed}
            onClick={handleClick}
            longitude={longitude}
            latitude={latitude}>
            {/* {getMarkerIcon(properties['iconType'], handleClick)} */}
            {children}
        </Marker>
    )
}

const MapMarkers = ({ geoJson = { features: [] }, setSelectedMarker }) => {
    const handleClick = (marker) => {
        setSelectedMarker(marker)
    }

    return (
        <>
            {geoJson.features.map((marker, index) => (
                <MapMarker key={index}  {...marker} handleClick={() => handleClick(marker)}/>
            ))}
        </>
    )
}

export {
    MapMarkers,
    MarkerPopup,
    MapMarker,
    getMarkerIcon
}