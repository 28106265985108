import React, { useState } from 'react'
import { MapMarkers, MarkerPopup } from '../MarkerLayer'
import { Box, Chip, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { formatRelativeTime } from 'Utils'
import Link from '@mui/material/Link'
import { Feature, FeatureCollection } from 'geojson'
import { extractCoordinate } from '../utils'
import { getIconById } from 'static/svgs/icons'

// generateLiveLocation method used to generate the to point feature
// Get the last location from the list of locations
export function generateLiveLocation(assetLocation: any): Feature {
    const { locations, ...restProps } = assetLocation
    const lastLocation = locations[locations.length - 1]
    const latLong = extractCoordinate(lastLocation.coordinates)

    return {
        type: 'Feature',
        properties: {
            asset: {
                ...restProps,
                first_seen: lastLocation.first_seen,
                last_seen: lastLocation.last_seen,
            },
            iconSize: [40, 40],
            iconType: ''
        },
        geometry: {
            type: 'Point',
            coordinates: latLong
        }
    }
}

export function generateLiveLocations(assetLocations: any[]): FeatureCollection {
    return {
        type: 'FeatureCollection',
        'features': assetLocations.map(generateLiveLocation)
    }
}

const LiveLocationLayer = ({ geoJson, showMarkerPoup }) => {
    const [selectedMarker, setSelectedMarker] = useState(null)

    return (
        <>
            <MapMarkers {...{ selectedMarker, setSelectedMarker, geoJson }} />
            {showMarkerPoup && selectedMarker && (
                <MarkerPopup marker={selectedMarker} handleClosePopup={() => setSelectedMarker(null)}>
                    <MarkerPopupContent marker={selectedMarker} />
                </MarkerPopup>
            )}
        </>
    )
}

const MarkerPopupContent = ({ marker }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const asset = marker.properties.asset
    const coordinates = marker.geometry.coordinates

    return (
        <Box sx={{ backgroundColor: theme.palette.primary.main, padding: 1, minWidth: 200 }}>
            <Link href={`/ntc_assets/${asset.id}/status`} sx={{ color: theme.palette.custom.nomadRed, cursor: 'pointer' }}>
                <Typography variant="button" display="block"
                    color={theme.palette.custom.defaultText}>{asset.name}
                </Typography>
            </Link>

            <Typography variant={'caption'}
                color={theme.palette.custom.defaultText}>{asset.description}</Typography>
            <Divider sx={{ color: 'text.secondary' }} />
            <Box>
                <Typography variant="body2" color={theme.palette.custom.defaultText}>
                    {t(`modules.map.marker.${asset.online ? 'connected' : 'notConnected'}`)}
                </Typography>
                <Typography variant="body2" color={theme.palette.custom.defaultText}>
                    {t(`modules.map.marker.stationary`)} {`${formatRelativeTime(t, asset.last_seen)}`}
                </Typography>
                <Typography variant="body2" color={theme.palette.custom.defaultText}>
                    {t(`modules.map.marker.lastOnline`)} {formatRelativeTime(t, asset.last_online)}
                </Typography>
                <Typography variant="body2" color={theme.palette.custom.defaultText}>
                    {t(`modules.map.marker.latitude`)} {parseFloat(coordinates[0]).toFixed(2)}
                </Typography>
                <Typography variant="body2" color={theme.palette.custom.defaultText}>
                    {t(`modules.map.marker.longitude`)} {parseFloat(coordinates[1]).toFixed(2)}
                </Typography>
            </Box>
        </Box>
    )
}
export default React.memo(LiveLocationLayer)