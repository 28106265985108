import React from 'react'
import './Login.css'
import { AuthRoutes } from 'utils/ServerRoutes'
import { User } from 'generated/client/User'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import NTCButton from '../inputs/buttons/NTCButton'
import LoginIcon from '@mui/icons-material/Login'
import { Link, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { FlashNotification } from '../display/modals/FlashNotification'
import { useTranslation } from 'react-i18next'
import LogoutConfirmation from '../layout/page/LogoutConfirmation'
import { Theme } from '../../generated/client/Theme'
import { Language } from '../../generated/client/Language'
import { FriendlyHttpStatus } from 'utils/HTTP/FriendlyHttpStatuses'

interface LoginProps {
    storeAuthTokens: (payload) => void
    cancel?: () => void
}

/**
 * A basic react login page which is presented on the client if an auth token is not found
 */
export default function Login({ storeAuthTokens, cancel }: LoginProps) {
    const theme = useTheme()
    const { t } = useTranslation()
    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('forms.user.field.email.validation.required')),
        password: Yup.string().required(t('forms.user.field.password.validation.required'))
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    const onSubmit = async (credentials) => {
        try {
            const response = await fetch(`${origin}${AuthRoutes.user}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(credentials)
            })

            response.ok ? storeAuthTokens(await response.json()) :
                [400, 401].includes(response.status) ?
                    response.json().then(data => {
                        const errorMessages = data['error_description'].map(key => {
                            return t(key)
                        }).join('\n')
                        showResponseStatus({ success: false, entity: 'User', message: errorMessages })
                    })
                    :
                    showResponseStatus({
                        success: false,
                        entity: 'Login',
                        message: response.status === 401 ? 'Login failed' : `Login error: ${response.status}: ${FriendlyHttpStatus[response.status]}`
                    })
        } catch (ex) {
            console.log("Exception", ex)
        } finally {

        }
    }

    return <>
        <div className={'form-container'}>
            <div className={'background'}>
                <LogoutConfirmation />
                <form className={'form'} id={'login-form'}>
                    <div className="image" />

                    <Box onKeyDown={(event) => { event.key === 'Enter' ? handleSubmit(onSubmit)() : '' }}>
                        <FormTextControl id={'email'} name={'email'} autoComplete='new-email'
                            label={t('forms.user.field.email.label')} {...{ register, errors }} />
                        <FormTextControl id={'password'} name={'password'} type={'password'} autoComplete='new-password'
                            label={t('forms.user.field.password.label')} {...{ register, errors }} />
                        <Box sx={{ mt: 5 }}>
                            {cancel == null ?
                                <NTCButton onClick={handleSubmit(onSubmit)} text={t('common.actions.login')}
                                    endIcon={<LoginIcon />}
                                    sx={{ minWidth: 200 }} /> :
                                <>
                                    <NTCButton
                                        onClick={cancel}
                                        text={t('common.actions.cancel')}
                                        sx={{ minWidth: 150, marginRight: '1rem' }} />
                                    <NTCButton onClick={handleSubmit(onSubmit)} text={t('common.actions.login')}
                                        backgroundColor={theme.palette.custom.nomadRed}
                                        endIcon={<LoginIcon />}
                                        sx={{ minWidth: 150 }} />
                                </>
                            }
                        </Box>

                        <Box display={'flex'} justifyContent={'flex-end'} sx={{ mt: 5, cursor: 'pointer' }}>
                            <Link
                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                variant="body2"
                                onClick={() => {
                                    window.location.replace(`${origin}/forgot-password`)
                                }}>
                                <Typography sx={{ color: theme.palette.custom.menuBack }}>
                                    {t(`common.actions.forgotPassword`)}
                                </Typography>
                            </Link>

                        </Box>
                    </Box>

                </form>
            </div>
        </div>
        {showNotification ?
            <FlashNotification open={showNotification}
                setOpen={setNotification} message={notificationData.message}
                severity={notificationData.severity}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} /> :
            <></>}
    </>
}
