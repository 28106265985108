import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

/**
 * This method fixes the issue related to modal dialog visibility in HTML5 fullscreen mode.
 * Reference: https://github.com/mui/material-ui/issues/15618#issuecomment-1893503162
 */
function getContainer() {
    // Use the fullscreen element if in fullscreen mode; otherwise, use the document's body
    return document.fullscreenElement ?? document.body;
}

export default function MapDateFilter({ handleDateChange, label, defaultValue }) {
    const theme = useTheme()
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker defaultValue={dayjs(defaultValue)} onChange={handleDateChange} label={label}
                slotProps={{
                    popper: { container: getContainer() }
                }}
                sx={{
                    padding: 0,
                    height: '1rem',
                    fontSize: '0.75rem',
                    '& .Mui-focused': { color: `${theme.palette.custom.defaultText} !important` },
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: `${theme.palette.custom.defaultText} !important` }
                }} />
        </LocalizationProvider>
    )
}