import React, { useContext } from 'react'
import { Grid, useTheme } from '@mui/material'
import NTCButton from '../inputs/buttons/NTCButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { isAuthorized, USER, UPDATE, DELETE, CREATE } from 'generated/auth/Permissions'
import { useTranslation } from 'react-i18next'
import { ClientContext } from '../../Context'
import { User } from 'generated/client/User'
import { RenderActionItems } from 'components/renderActionItems/RenderActionItems'

interface UserListProps {
    users: User[]
    actionButtonHandler: any,
    page?: string
}

const UserList: React.FC<UserListProps> = ({
    users,
    actionButtonHandler,
    page = 'user'
}) => {

    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const { t } = useTranslation()
    const isOrg = page === 'organization'
    return <>
        <Grid container sx={{ pt: 2 }}>
            {users.map(user => (
                <RenderActionItems
                    key={user.id}
                    item={user}
                    itemType={'user'}
                    permissions={{
                        edit: { resource: USER, action: UPDATE },
                        lock: { resource: USER, action: UPDATE },
                        delete: { resource: USER, action: DELETE },
                        assign: { resource: USER, action: UPDATE },
                        confirm_account: { resource: USER, action: UPDATE },
                        impersonate: {}
                    }}
                    actionButtonHandler={actionButtonHandler}
                />
            ))}
        </Grid>
        <Grid container spacing={2} sx={{ pt: 2 }}>
            {isAuthorized(USER, CREATE) ?
                <Grid item xs={12} sm={6} md={2}>
                    <NTCButton
                        startIcon={<AddOutlinedIcon sx={{ color: theme.palette.custom.whiteText }} />}
                        text={`${t('common.actions.create')} ${t('forms.user.title')}`}
                        sx={{ px: 4, textTransform: 'none' }}
                        backgroundColor={theme.palette.custom.nomadRed}
                        onClick={() => actionButtonHandler('create')}
                    />
                </Grid> : <></>
            }
        </Grid>
    </>

}

export default UserList