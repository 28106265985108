import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Chip, Collapse, Grid, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { ClientContext } from 'Context'
import Box from '@mui/material/Box'
import { flexJustifySpaceBetween } from 'styles/GlobalSXProps'
import { ASSET, REMOTE_CONTROL, REMOTE_VIEW, UPDATE, isAuthorized, isSuperAdmin } from 'generated/auth/Permissions'
import NTCButton from 'components/inputs/buttons/NTCButton'
import AddIcon from '@mui/icons-material/Add'
import { DataGrid, GridActionsCellItem, GridColDef, gridClasses } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import { InputBase } from '@mui/material'
import { FormMode } from 'components/inputs/forms/Forms'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import { getIconById } from 'static/svgs/icons'

import { formatRelativeTime } from 'Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchange } from '@fortawesome/free-solid-svg-icons'

const getConnectionStatus = (params) => {
    const { t } = useTranslation()
    const online = params.value
    return (
        <Chip label={t(`modules.map.marker.${online ? 'connected' : 'notConnected'}`)} color={online ? 'success' : 'error'} />
    )
}

export const SearchControl = ({ keyword, setKeyword, type }) => {

    const { t } = useTranslation()
    return (
        <Paper
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, float: 'left' }}>
            <IconButton type='button' sx={{ p: '10px' }} aria-label={t(`forms.${type}.search`)}>
                <SearchIcon />
            </IconButton>
            <InputBase
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder={t(`forms.${type}.search`)}
                inputProps={{ 'aria-label': t(`forms.${type}.title`) }}
            />
        </Paper>
    )
}

export function AssetToolbar({ addEventHandler, keyword, setKeyword, isOrg }) {
    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const { t } = useTranslation()
    return (
        <Box sx={{ ...flexJustifySpaceBetween, mb: 2 }}>
            <Box>
                <SearchControl {...{ keyword, setKeyword, type: 'asset' }} />
            </Box>
            <Box>
                {isSuperAdmin(client) && !isOrg &&
                    <NTCButton backgroundColor={theme.palette.custom.nomadRed} onClick={() => addEventHandler('create')}
                        text={`${t('common.actions.create')} ${t('forms.asset.title')}`}
                        startIcon={<AddIcon sx={{ color: theme.palette.custom.whiteText }} />} />
                }
            </Box>
        </Box>
    )
}

function StarLinkDetail({ row, theme, t }) {
    const [open, setOpen] = useState(false)

    if (!row.show_starlink) {
        return null
    }

    const FieldValuePair = ({ label, value }) => (
        <Box display='grid'>
            <Typography variant='subtitle2' gutterBottom component='div'>{label}</Typography>
            <Typography variant='overline' gutterBottom component='div'>{value}</Typography>
        </Box>
    )

    return (
        <Box display={'grid'}>
            <Link onClick={() => setOpen(!open)} sx={{ color: theme.palette.custom.sliderBlue, cursor: 'pointer' }}>
                {open ? t('modules.news.showLess') : t('modules.news.showMore')}
            </Link>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1, display: 'flex', justifyContent: 'space-between', ml: -136, mt: 6 }}>
                    <FieldValuePair label={t(`forms.asset.field.serial_number.label`)} value={row.serial_number} />
                    <FieldValuePair label={t(`forms.asset.field.asset_id.label`)} value={row.asset_id} />
                    <FieldValuePair label={t(`forms.asset.field.business_unit.label`)} value={row.business_unit} />
                    <FieldValuePair label={t(`forms.asset.field.unit_number.label`)} value={row.unit_number} />
                    <FieldValuePair label={t(`forms.asset.field.customer_number.label`)} value={row.customer_number} />
                </Box>
            </Collapse>
        </Box>
    )
}

function getColumns({ client, theme, t, actionButtonHandler }
): GridColDef[] {
    let result = [
        {
            field: 'name',
            headerName: t('forms.asset.field.status.label'),
            width: 300,
            renderCell: (params) => {
                if (isAuthorized(ASSET, REMOTE_CONTROL) || isAuthorized(ASSET, REMOTE_VIEW)) {
                    return <Link onClick={() => window.location.replace(`/ntc_assets/${params.row.id}/status`)}
                        sx={{ color: theme.palette.custom.defaultText, cursor: 'pointer' }}>
                        {params.value}
                    </Link>
                } else {
                    return <>{params.value}</>
                }
            }
        },
        {
            field: 'description',
            headerName: t('forms.asset.field.description.label'),
            width: 300,
            renderCell: (params) => {
                return <>{params.value}</>
            }
        },
        {
            field: 'online',
            headerName: t('forms.asset.field.connected.label'),
            width: 200,
            renderCell: (params) => {
                return getConnectionStatus(params)
            }
        },
        {
            field: 'last_online',
            headerName: t('forms.asset.field.last_online.label'),
            width: 300,
            renderCell: (params) => {
                return <>{params.value ? formatRelativeTime(t, params.value) + ': ' + new Date(params.value).toLocaleDateString() + ' at ' + new Date(params.value).toLocaleTimeString() : 'Never'}</>
            }
        },
        {
            field: 'other_info',
            headerName: '',
            width: 150,
            renderCell: (params) => {
                return <StarLinkDetail row={params.row} theme={theme} t={t} />
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 200,
            getActions: (params) => {
                const actionButtons = []
                if (isAuthorized(ASSET, UPDATE)) {
                    actionButtons.push(<GridActionsCellItem
                        icon={<Tooltip title={t('common.actions.edit')} children={<EditIcon />} />}
                        label={t('common.actions.edit')}
                        onClick={() => actionButtonHandler(FormMode.EDIT, params.row)}
                    />)
                }
                if (isSuperAdmin(client)) {
                    actionButtons.push(<GridActionsCellItem
                        icon={<Tooltip title={t('common.actions.delete')} children={<DeleteIcon />} />}
                        label={t('common.actions.delete')}
                        onClick={() => { actionButtonHandler(FormMode.DELETE, params.row) }}
                    />)
                }

                if (isSuperAdmin(client)) {
                    actionButtons.push(<GridActionsCellItem
                        icon={<Tooltip title={t('common.actions.transfer')} children={<FontAwesomeIcon icon={faExchange} size={'sm'} />} />}
                        label={t('common.actions.transfer')}
                        onClick={() => { actionButtonHandler('transfer', params.row) }}
                    />)
                }
                return actionButtons
            }
        }
    ]

    if (isAuthorized(ASSET, REMOTE_CONTROL) && client.org.remote_control) {
        let remote_control_field = {
            field: 'remote',
            headerName: t('forms.asset.field.remote_control.label'),
            width: 300,
            renderCell: (params) => {
                if (params.row.online) {
                    return <>
                        <Link onClick={() => window.location.replace(`/ntc_assets/${params.row.id}/remote`)}
                            sx={{ color: theme.palette.custom.defaultText, cursor: 'pointer' }}>
                            {params.row.name}
                        </Link>
                        &nbsp;
                        {getIconById('remote_control')}
                    </>
                } else {
                    return <></>
                }
            }
        }

        // Insert the remote control column at the second position if allowed
        result.splice(1, 0, remote_control_field)
    }

    return result
}

const AssetList = ({ rows, actionButtonHandler, page = 'asset' }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const [filteredRows, setFilteredRows] = useState(rows)
    const [keyword, setKeyword] = useState('')
    const { client } = useContext(ClientContext)
    const isOrg = page === 'organization'
    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    // filter items based on keyword change
    useEffect(() => {
        if (!keyword.trim().length) return
        const filteredValue = rows.filter((item) => {
            return Object.values(item)?.some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase())
            )
        })
        setFilteredRows(filteredValue)
    }, [keyword])

    return (
        <>
            {<AssetToolbar addEventHandler={actionButtonHandler} {...{ keyword, setKeyword, isOrg }} />}
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    rows={filteredRows}
                    columns={getColumns({ client, theme, t, actionButtonHandler })}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20
                            }
                        }
                    }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                            'alignItems': 'baseline',
                            outline: 'transparent',
                        },
                        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.cell}:focus`]: {
                            outline: 'none',
                        },
                    }}
                />
            </Box>
            {isOrg &&
                <Grid mt={2}>
                    <NTCButton backgroundColor={theme.palette.custom.nomadRed} onClick={() => actionButtonHandler('create')}
                        text={`${t('common.actions.create')} ${t('forms.asset.title')}`}
                        startIcon={<AddIcon sx={{ color: theme.palette.custom.whiteText }} />} />
                </Grid>}
        </>
    )
}

export {
    AssetList
}