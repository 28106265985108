import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'generated/client/User'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import LoginIcon from '@mui/icons-material/Login'
import { Link, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import FormTextControl from 'components/inputs/forms/controls/FormTextControl'
import NTCButton from 'components/inputs/buttons/NTCButton'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import LogoutConfirmation from 'components/layout/page/LogoutConfirmation'
import { FriendlyHttpStatus } from 'utils/HTTP/FriendlyHttpStatuses'
import { AuthRoutes } from 'utils/ServerRoutes'
import '../../components/login/Login.css'

/**
 * A basic react login page which is presented on the client if an auth token is not found
 */
export function ForgotPasswordPage() {
    const theme = useTheme()
    const { t } = useTranslation()
    const [emailSent, setEmailSent] = useState(false)
    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('forms.user.field.email.validation.required')),
    })

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    const onSubmit = async (credentials) => {
        const payload = {
            user: { ...credentials, redirect_url: '/reset-password' }
        };
        try {
            const response = await fetch(`${origin}${AuthRoutes.resetPasswordLink}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;'
                },
                body: JSON.stringify(payload)
            })
            if (response.ok) {
                setEmailSent(true)
                showResponseStatus({ success: true, entity: 'Password', message: t('forms.user.field.password.sentResetLink') })
            } else {
                response.json().then(data => {
                    const errorMessages = Object.values(data['errors']).map((key: any | string | string[]) => key).join('\n')
                    showResponseStatus({ success: false, entity: 'User', message: errorMessages })
                })
            }
        } catch (ex) { }
    }

    return <>
        <div className={'form-container'}>
            <div className={'background'}>
                <form className={'form'}>
                    <div className="image" />

                    <Box onKeyDown={(event) => { event.key === 'Enter' ? handleSubmit(onSubmit)() : '' }}>
                        {!emailSent ?
                            <>
                                <FormTextControl id={'email'} name={'email'}
                                    label={t('forms.user.field.email.label')} {...{ register, errors }} />
                                <Box sx={{ mt: 5 }}>
                                    <NTCButton onClick={handleSubmit(onSubmit)} text={t('common.actions.resetPasswordLink')}
                                        backgroundColor={theme.palette.custom.nomadRed}
                                        endIcon={<LoginIcon />}
                                        sx={{ minWidth: 150 }} />
                                </Box>
                            </>
                            :
                            <Box mt={5}>
                                <Typography sx={{ color: theme.palette.custom.menuBack }}>{t('forms.user.field.password.sentResetEmailInfo')}</Typography>
                            </Box>
                        }
                        <Box display={'flex'} justifyContent={'flex-end'} mt={5}>
                            <Link
                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                variant="body2"
                                onClick={() => {
                                    window.location.replace(`${origin}/login`)
                                }}>
                                <Typography sx={{ color: theme.palette.custom.menuBack }}>
                                    {t(`common.actions.login`)}
                                </Typography>
                            </Link>
                        </Box>
                    </Box>

                </form>
            </div>
        </div>
        {showNotification ?
            <FlashNotification open={showNotification}
                setOpen={setNotification} message={notificationData.message}
                severity={notificationData.severity}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} /> :
            <></>}
    </>
}
