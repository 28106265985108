import * as React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { Breakpoint, useTheme } from '@mui/material'
import BaseModalDialog from './BaseModalDialog'
import Typography from '@mui/material/Typography'

interface ConfirmModalDialogProps {
    open: boolean,
    maxWidth?: Breakpoint,
    modalTitle?: string,
    handleClose,
    confirmMessage: string,
    helpMessage?: string,
    handleConfirm?,
    handleCancel?
}

export const DialogButtons = ({ onConfirmClick, onCancelClick }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    return <>
        <Button sx={{ color: theme.palette.custom.nomadRed }} onClick={onConfirmClick}>{t('common.actions.ok')}</Button>
        <Button sx={{ color: theme.palette.custom.defaultText }}
            onClick={onCancelClick}>{t('common.actions.cancel')}</Button>
    </>
}

export default function ConfirmModalDialog({
    open,
    maxWidth,
    handleClose,
    modalTitle,
    confirmMessage,
    handleConfirm,
    handleCancel
}: ConfirmModalDialogProps) {
    const { t } = useTranslation()
    return (
        <BaseModalDialog open={open} handleClose={handleClose} modalTitle={modalTitle} dialogButtons={
            <DialogButtons onConfirmClick={handleConfirm} onCancelClick={handleCancel} />} maxWidth={maxWidth}>
            <Typography>
                {confirmMessage}
            </Typography>
        </BaseModalDialog>
    )
}