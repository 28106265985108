import { marginHeight, rowHeight } from 'components/display/grid/NTCGridLayout'

// Function to calculate the size of a file, returns string used in MediaLibrary.tsx
export function calculateFileSize(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// This function coverts pixels to the number of grid rows which will fit the module
// https://github.com/react-grid-layout/react-grid-layout
// numberOfRows = (rowHeight * h) + (marginH * (h - 1))
// numberOfRows = ( elementHeightInPx + marginH) / (marginH + rowHeight)
export function calculateGridHeight(currentHeight) {
    return Math.ceil((currentHeight + marginHeight) / (marginHeight + rowHeight))
}

// Calculates the inner width of a display element using a React Ref and returns correct number of
// react-grid-layout rows element should be set to.
export function calculateInnerWidth(layoutContext, heightRef, id) {
    if (!layoutContext) return
    let height = heightRef?.current?.clientHeight
    // Add two to include module header
    layoutContext(id, calculateGridHeight(height) + 2)
}

export function getRandomId(size = 4) { return Math.floor(Math.random() * Math.pow(10, size)) + 1 }