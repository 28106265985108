import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { Box, Grid, useTheme } from '@mui/material'

import { PageContext } from 'Context'
import NTCButton from '../inputs/buttons/NTCButton'
import BaseModalDialog from '../display/modals/BaseModalDialog'
import { FormSelectControl } from 'components/inputs/forms/controls/FormSelectControl'

import OrganizationApiService from 'utils/HTTP/OrganizationUtils'
import { AssetApiService } from 'utils/HTTP/AssetUtils'
import { handleException } from 'utils/HTTPUtils'

interface AssetTransformProps {
    assetId: string
    organizationId: string
    handleClose: any
    showResponseStatus: any
}

const AssetTransferForm = ({ assetId, organizationId, handleClose, showResponseStatus }: AssetTransformProps) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const { setLoadingPanel } = useContext(PageContext)
    const [openFormDialog, setOpenFormDialog] = useState(true)

    const [asset, setAsset] = useState(null)
    const defaultValues = { asset_id: assetId, target_org_id: '' }
    const validationSchema = Yup.object().shape(
        {
            asset_id: Yup.string(),
            target_org_id: Yup.string().required(t('forms.asset.field.transfer_org.validation.required'))
        })

    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    })

    const organizationResponse = OrganizationApiService.fetchOrganizations()

    useEffect(() => {
        AssetApiService.getAssetById(assetId).then(res => {
            setAsset(res)
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Asset', message: ex.message })
        })
    }, [assetId])


    const onSubmit = async (data) => {
        setLoadingPanel(true)

        try {
            const res = await AssetApiService.transferOwner(data)
            showResponseStatus({
                success: true, entity: 'Asset',
                message: t(`common.notifications.transfer`, { name: t('forms.asset.title') })
            })
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'Asset', message: errorMessage })
        } finally {
            handleClose()
            setLoadingPanel(false)
        }
    }


    const prepareItemList = (options) => {
        return options.map(({ id, name }) => ({ value: id, label: `${name}` }))
    }

    function createOrganizationOptions() {
        const options = organizationResponse.result.filter(org => org.id !== organizationId)
        return prepareItemList(options)
    }

    return (
        <BaseModalDialog
            modalTitle={t(`common.actions.transfer`) + ' ' + t('forms.asset.title') + ' - ' + asset?.name}
            maxWidth='lg'
            open={openFormDialog}
            handleClose={() => {
                setOpenFormDialog(false)
                handleClose()
            }}>
            <Box px={3} py={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        {organizationResponse?.result?.length ?
                            <FormSelectControl id={'target_org_id'}
                                name={'target_org_id'}
                                options={createOrganizationOptions()}
                                defaultValue={''}
                                label={t('forms.asset.field.transfer_org.label')}
                                defaultOptionLabel={t('forms.asset.field.transfer_org.dropdownLabel')}
                                handleChange={(val) => { setValue('target_org_id', val) }}
                                {...{ control, errors }} /> : null}
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                    <Grid container spacing={3} justifyContent={'right'}>
                        <Grid item xs={12} sm={6}>
                            <NTCButton text={t('common.actions.cancel')} onClick={() => handleClose()} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NTCButton onClick={handleSubmit(onSubmit)}
                                text={t('common.actions.transfer')}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </BaseModalDialog>
    )
}

export default AssetTransferForm