import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'
import { FormMultiSelectControl } from 'components/inputs/forms/controls/FormMultiSelectControl'
import FormTextControl from 'components/inputs/forms/controls/FormTextControl'
import BaseModalDialog from 'components/display/modals/BaseModalDialog'
import { FormMode } from 'components/inputs/forms/Forms'
import NTCButton from 'components/inputs/buttons/NTCButton'
import { Group } from 'generated/auth/Group'

import UserApiService from 'utils/UserHttpUtils'
import GroupApiService from 'utils/HTTP/GroupUtils'
import { AssetApiService } from 'utils/HTTP/AssetUtils'
import { parseErrorMessages } from 'utils/HTTPUtils'

function groupValidationSchema(t) {
    return {
        id: Yup.string(),
        name: Yup.string().required(t('forms.group.field.name.validation.required')),
        selectedGroupOptions: Yup.array(),
        selectedUserOptions: Yup.array(),
        selectedAssetOptions: Yup.array()
    }
}

// Function to filter IDs by selected options
function filterIdsByOptions(options, dataItems) {
    return options?.filter(option => {
        return dataItems?.map(item => item.id)?.includes(option.value)
    }) || []
}

function prepareItemList(options) {
    return options?.map(({ id, name }) => ({ value: id, label: name }))
}

const GroupForm = ({ groupId, mode, showResponseStatus, handleCloseModal }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const [userOptions, setUserOptions] = useState([])
    const [assetOptions, setAssetOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const defaultValues = {
        id: '', name: '', selectedGroupOptions: [], selectedAssetOptions: [], selectedUserOptions: []
    }

    const [group, setGroup] = useState<Group>(null)
    const [formData, setFormData] = useState(defaultValues)

    const validationSchema = Yup.object().shape(groupValidationSchema(t))
    const { register, control, handleSubmit, setValue, trigger, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: formData,
        mode: 'all'
    })

    useEffect(() => {
        async function fetchData() {
            try {
                // Get all available groups, users and assets to populate the form dropdown
                const [users, assets, groups] = await Promise.all([
                    UserApiService.fetchAllUsers(),
                    AssetApiService.fetchAllAssets(),
                    GroupApiService.fetchAllGroups()
                ])

                const assetOptionList = prepareItemList(assets)
                const userDropdownList = prepareItemList(users)
                const groupDropdownList = prepareItemList(groups.filter(group => group.id !== groupId))  //Exclude parent group_id

                setUserOptions(userDropdownList)
                setAssetOptions(assetOptionList)
                setGroupOptions(groupDropdownList)

                if (!groupId) {
                    trigger()
                    return
                }

                const groupData = await GroupApiService.getGroupById(groupId)

                if (mode === FormMode.EDIT) {
                    setValue('id', groupId)
                }

                if (mode === FormMode.CLONE) {
                    groupData.name = `${groupData.name} (Copy)`
                }

                const selectedUserOptions = filterIdsByOptions(userDropdownList, groupData.users)
                const selectedAssetOptions = filterIdsByOptions(assetOptionList, groupData.ntc_assets)
                const selectedGroupOptions = filterIdsByOptions(groupDropdownList, groupData.children)

                setGroup(groupData)

                setFormData({
                    id: groupData.id,
                    name: groupData.name,
                    selectedAssetOptions,
                    selectedUserOptions,
                    selectedGroupOptions
                })

                setValue('id', groupData.id)
                setValue('name', groupData.name)
                setValue('selectedAssetOptions', selectedAssetOptions)
                setValue('selectedUserOptions', selectedUserOptions)
                setValue('selectedGroupOptions', selectedGroupOptions)

            } catch (ex) {
                showResponseStatus({ success: false, entity: 'Group', message: ex.message })
            }
        }

        fetchData()
    }, [groupId, mode])

    const onSubmit = async (data) => {
        let res = null
        const apiFunction = [FormMode.CLONE, FormMode.CREATE].includes(mode) ? GroupApiService.createGroup : GroupApiService.updateGroup
        try {
            const payload = {
                id: data.id,
                name: data.name,
                child_ids: data.selectedGroupOptions.map(user => user.value),
                user_ids: data.selectedUserOptions.map(user => user.value),
                ntc_asset_ids: data.selectedAssetOptions.map(asset => asset.value)
            }

            res = await apiFunction(payload)
            setGroup(res)
            showResponseStatus({ success: true, entity: 'Group', message: t(`common.notifications.${mode}`, { name: t('forms.group.title') }) })

        } catch (ex) {
            // TODO, Remove this exception parser once this pull request merged (https://github.com/NomadGCS/ntc-portal-webserver/pull/118)
            if (ex.json) {
                ex.json().then(data => {
                    const errorMessages = parseErrorMessages(data).join('\n')
                    showResponseStatus({ success: false, entity: 'Group', message: errorMessages })
                })
            } else {
                showResponseStatus({ success: false, entity: 'Group', message: ex.message })
            }
        } finally {
            handleCloseModal()
        }
    }

    const handleCancel = () => {
        setOpenFormDialog(false)
        handleCloseModal()
    }

    return (
        <BaseModalDialog
            modalTitle={t(`common.actions.${mode}`) + ' ' + t(`forms.group.title`)}
            maxWidth='md'
            open={openFormDialog}
            handleClose={() => handleCancel()}>

            <Box px={3} py={2}>

                <Grid container spacing={3}>
                    <input type='hidden' id='id' name='id' value={groupId || ''} {...register('id')} />

                    <Grid item xs={12} sm={12}>
                        <FormTextControl id={'name'} name={'name'} defaultValue={formData.name}
                            label={t('forms.group.field.name.label')}{...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormMultiSelectControl name={'selectedUserOptions'} label={t('forms.group.field.users.label')}
                            options={userOptions || []} {...{ control, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormMultiSelectControl name={'selectedAssetOptions'} label={t('forms.group.field.ntc_assets.label')}
                            options={assetOptions || []}{...{ control, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormMultiSelectControl name={'selectedGroupOptions'} label={t('forms.group.field.children.label')}
                            options={groupOptions || []}{...{ control, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} gap={2} sx={{ mt: 2 }} justifyContent={'end'} display={'flex'}>
                        <NTCButton text={t('common.actions.cancel')} onClick={handleCancel} />

                        <NTCButton disabled={!isValid && mode === FormMode.CREATE}
                            backgroundColor={theme.palette.custom.nomadRed}
                            text={t('common.actions.save')} onClick={handleSubmit(onSubmit)} />
                    </Grid>
                </Grid>
            </Box>
        </BaseModalDialog>
    )
}

export default GroupForm