import React, { createContext, useState } from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { Responsive, WidthProvider } from 'react-grid-layout'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import { NTCResizeHandle } from './NTCResizeHandle'
import { Box } from '@mui/material'

// WARNING
// The implementation of this library only exists in class based react.
// There are no tutorials or documentation that show this library being used in a functional paradigm
// You must fully read and understand the library documentation in order to change this component
// Change this file at your own risk
// https://github.com/react-grid-layout/react-grid-layout
// https://isamatov.com/react-grid-layout-tutorial/
// https://stackoverflow.com/questions/35689302/programmatically-change-height-width-of-items-in-react-grid-layout

// Create a responsive layout using react-grid-layout's width provider
const ResponsiveGridLayout = WidthProvider(Responsive)

// Create and export a Layout Context to be used in Asset
export const LayoutContext = createContext(null)

// This function finds the object in the layout array which is meant to be updated and updates that specific layout
export function handleLayoutUpdate(layout, id, height, maxHeight = 100, stateChangeCallback) {
    // needed to create a deep copy of the layout object
    let layoutCopy = JSON.parse(JSON.stringify(layout))
    // for each of the layout objects, ie) hvac, interlock, lighting
    layoutCopy.forEach((module) => {
        if (module.i === id) { // if the identifier is equal to the identifier ie.) hvac === hvac
            module['h'] = height // set the height to the passed in height
            module['maxH'] = maxHeight // set the maxHeight to the passed in maxHeight
        }
    })
    stateChangeCallback(layoutCopy) // Execute the state change callback to update the new grid
}

// Re-map the height of all grid items
export function resizeAllLayouts(layout, keyValue, stateChangeCallback) {
    const updatedLayout = layout.map((l) => ({ ...l, h: keyValue[l.i] }))
    stateChangeCallback(updatedLayout)
}

// Export marginH so that this is the single source of truth
// Needed because if updated it needs to update in all calculations and grid items
export const marginHeight = 70
export const marginWidth = 50
// Export rowHeight so that this is the single source of truth
export const rowHeight = 70

export default function NTCGridLayout({
    editMode = false,
    children,
    layout,
    onLayoutChange: onLayoutChangeCallback,
    onBreakpointChange = (bp, c) => { },
    setStartAutoSave = (val) => { }
}) {
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)
    const [preventCollision, setPreventCollision] = useState(false)
    return (
        <Box sx={{ position: 'relative', zIndex: 1 }}>
            <ResponsiveGridLayout
                // To improve UX, ability to drag and resize is only available in edit mode.
                isDraggable={editMode}
                isResizable={editMode}

                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                onBreakpointChange={onBreakpointChange}
                // The number of columns that exist on a large, medium, small, extra-small, and extra-extra-small screen
                cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
                autoSize={false} //not sure if this is needed, not needed because module has dynamic contents
                rowHeight={rowHeight}
                // Margins on the sides and bottoms of the grid
                margin={isMobile ? [0, marginHeight] : [marginWidth, marginHeight]}
                // The class name of the drag handle
                //  class exists in ModuleWrapper.tsx
                draggableHandle=".drag-handle"
                preventCollision={preventCollision}
                onLayoutChange={(newLayout) => { onLayoutChangeCallback(newLayout) }}
                // Assume layout is coming from parent, same layout for all screen sizes
                layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
                onDragStart={() => { setStartAutoSave(true) }}
                onResizeStart={() => { setStartAutoSave(true) }}

                // Use the custom resize handle instead of the default.  Need to toggle visibility for this to work
                // on Touch device.
                resizeHandle={
                    editMode ? <NTCResizeHandle /> : null
                }>

                {children}

            </ResponsiveGridLayout>
        </Box>
    )
}