import React, { useContext, useState } from 'react'
import ModuleWrapper from '../ModuleWrapper'
import { Box, Button, MobileStepper, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NewsItem } from './NewsItem'
import { fetchNews } from 'utils/HTTP/NewsHttpUtil'
import { UiModuleProps } from '../UiModuleProps'
import { NewsConfig } from '../UiModuleConfig'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { ClientContext } from 'Context'

const generateNewsQuery = (client) => {
    let keywords = client.news_keywords || ''

    // Array to hold state, city, and keywords (filtered to remove empty values)
    let components = [client.news_state, client.news_city, keywords].filter(value => value)

    // Join components with comma and space
    return components.join(', ')
}

const NewsFeed = ({ id, name, type, config }: UiModuleProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const [newsItems, setNewsItems] = useState([])
    const keywords = generateNewsQuery(client)
    const responses = fetchNews(client.news_country || '', keywords)

    function handleNewsTypeChange(type) {
        // TODO, When multiple type of news category needed
    }

    const [activeStep, setActiveStep] = React.useState(0)
    const [offset, setOffset] = React.useState(0)

    const maxSteps = Math.floor((responses?.result?.['results']?.length || 0) / 5)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setOffset((prevOffset) => prevOffset + 5)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        setOffset((prevOffset) => prevOffset - 5)
    }

    return <ModuleWrapper
        type={type}
        name={name}
        id={id}
        statusElement={null}
    >
        <Box>
            {responses?.result?.['results'].length ?
                <>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}

                        nextButton={
                            <Button size="small" onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                sx={{ color: theme.palette.custom.moduleTitle }}>
                                {t('common.actions.next')}
                                {theme.direction === 'rtl' ? (<KeyboardArrowLeft />) : (<KeyboardArrowRight />)}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack}
                                disabled={activeStep === 0}
                                sx={{ color: theme.palette.custom.moduleTitle }}>
                                {theme.direction === 'rtl' ? (<KeyboardArrowRight />) : (<KeyboardArrowLeft />)}
                                {t('common.actions.back')}
                            </Button>
                        }
                    />

                    <Box>
                        {[...Array(5)].map((e, index) => {
                            const currentIndex = offset + index
                            if (responses?.result?.['results'][currentIndex]) {
                                return (
                                    <NewsItem
                                        key={responses.result?.['results'][currentIndex].article_id}
                                        newsItem={responses.result?.['results'][currentIndex]}
                                    />
                                )
                            } else {
                                return null
                            }
                        })}
                    </Box>

                </> : <></>}
        </Box>
    </ModuleWrapper>
}

export default NewsFeed