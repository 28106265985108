// noinspection ES6PreferShortImport

/*
 * This file has been generated by the TypeScript generator.
 *
 * Any changes will be overwritten during the next generation phase.
 */

export interface Permission {
    id: string;
    path: string;
}


enum Resource {
    User = 'User',
    Role = 'Role',
    NtcAsset = 'NtcAsset',
}

enum Action {
    List = 'list',
    Read = 'read',
    Update = 'update',
    Create = 'create',
    Delete = 'delete',
    RemoteView = 'remote_view',
    RemoteControl = 'remote_control',
}

export const Permissions = [
    { resource: Resource.User, action: Action.List },
    { resource: Resource.User, action: Action.Read },
    { resource: Resource.User, action: Action.Update },
    { resource: Resource.User, action: Action.Create },
    { resource: Resource.User, action: Action.Delete },

    { resource: Resource.Role, action: Action.List },
    { resource: Resource.Role, action: Action.Read },
    { resource: Resource.Role, action: Action.Update },
    { resource: Resource.Role, action: Action.Create },
    { resource: Resource.Role, action: Action.Delete },

    { resource: Resource.NtcAsset, action: Action.List },
    { resource: Resource.NtcAsset, action: Action.Read },
    { resource: Resource.NtcAsset, action: Action.Update },
    { resource: Resource.NtcAsset, action: Action.Create },
    { resource: Resource.NtcAsset, action: Action.Delete },
    { resource: Resource.NtcAsset, action: Action.RemoteView },
    { resource: Resource.NtcAsset, action: Action.RemoteControl },
]
