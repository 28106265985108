import React from 'react'
import ReactDOM from 'react-dom/client'
import store from './redux/store'
import { Provider } from 'react-redux'
import './index.css'

async function importAppEnvironment() {
    return import('./App')
}

importAppEnvironment().then(({ default: Environment }) =>
    ReactDOM.createRoot(document.getElementById('root')).render(
        <Provider store={store}>
            <Environment />
        </Provider>
    )
)
