import * as React from 'react'
import { useState } from 'react'


import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import './SideNav.css'
import Link from '@mui/material/Link'
import { NomadWhiteIcon } from 'static/svgs/icons'
import { useTheme } from '@mui/material'
import BaseModalDialog from 'components/display/modals/BaseModalDialog'
import { useTranslation } from 'react-i18next'
import ContactUs from 'components/support/Contact'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}))

export default function SideNav({ drawerWidth, openSidebar, toggleSidebar, logoIcon, children }) {
    const theme = useTheme()
    const { t } = useTranslation()

    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)
    const [showSupportModal, setShowSupportModal] = useState(false);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer className='drawer-root'
                sx={{
                    width: `${drawerWidth}px` || '200px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: `${drawerWidth}px` || '200px',
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.custom.menuBack,
                        borderRadius: '0px 11.25px 11.25px 0px'
                    }
                }}
                variant={isMobile ? 'temporary' : 'persistent'}
                anchor={'left'}
                open={openSidebar}
                onClose={toggleSidebar}>
                <DrawerHeader className='header-left' sx={{ display: 'block', justifyContent: 'flex-start' }}>
                    <Box sx={{ float: 'right', marginTop: '1.1rem' }}>
                        <IconButton aria-label={'Toggle Menu'} sx={{ color: 'white' }} onClick={toggleSidebar}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box sx={{
                            cursor: 'pointer',
                            '& svg': { width: 130, position: 'absolute', height: 35, top: 50, left: 20 }
                        }}>
                            <Link aria-label='Back to Overview' href={'/overview'}>
                                <NomadWhiteIcon />
                            </Link>
                        </Box>
                    </Box>
                </DrawerHeader>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    {children}

                    {/*  TOGGLE FOR CONTACT SUPPORT MODAL  */}
                    <Box sx={{ color: 'white', alignSelf: 'center', fontSize: '0.8em', marginBottom: '2em' }}>
                        <a style={{ cursor: 'pointer', opacity: 1.0 }} onClick={() => {
                            setShowSupportModal(true);
                        }}>
                            {t('contact.linkText')}
                        </a>
                    </Box>
                </Box>
            </Drawer>

            {/*  SUPPORT MODAL  */}
            <BaseModalDialog
                modalTitle={'Contact NTC Support'}
                maxWidth={'xl'}
                backgroundColor={theme.palette.custom.contactUsBackground}
                open={showSupportModal}
                handleClose={() => {
                    setShowSupportModal(false)
                }}>
                <ContactUs />
            </BaseModalDialog>
        </Box>
    )
};
