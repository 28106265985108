import React, { useEffect, useState } from "react";
import { Box, Stack, useTheme } from '@mui/material'
import { marginWidth } from "./NTCGridLayout";
import IOSSwitch from "../../inputs/switches/IOSSwitch";


/*
 *  Retrieve any translation and height values for a DOM element which will be used in calculating
 *  the overall height for this layout.
 *
 *  Adapted from here:
 *  https://stackoverflow.com/questions/42267189/how-to-get-value-translatex-by-javascript
 */
function getTranslateValues(element) {
    const style = window.getComputedStyle(element)
    const matrix = new DOMMatrixReadOnly(style.transform)

    // get bounding height
    let boundingBox = element.getBoundingClientRect();

    return {
        height: boundingBox.height,
        translateX: matrix.m41,
        translateY: matrix.m42
    }
}

/**
 * This calculates the height of the react-grid-layout container to solve the issue where there is no way to
 * add anything underneath a react-grid-layout without setting the height on the parent container manually.
 * The demos on 'react-grid-layout' do this but don't expose how they are calculating the height hence this
 * function was created to do it.
 *
 * If you want to use the space underneath a 'react-grid-layout' element, then this function needs to be called.
 */
export function calculateParentHeight(cssSelector: string) {

    // Bail if no css selector to identified child elements was provided.
    if (cssSelector === "") return;

    // Search the DOM for all matching elements, find largest translate value.
    const gridItems = document.querySelectorAll(cssSelector);
    let largestTranslate = 0;
    let largestHeight = 0;

    // Loop through the list of DOM elements and find the largest TranslateY value
    // (which is how far down the page this item is).
    [...gridItems].map((item) => {
        const elementStats = getTranslateValues(item);
        const translateY = elementStats.translateY;

        if (translateY && translateY > largestTranslate) {
            largestTranslate = translateY;
            largestHeight = elementStats.height;
        }
    })

    // Calculate the gaps in react-grid-layout items
    const EXTRA_SPACE = 100;    // This is the number that seemed to work to get good results most of the time.

    // Set the ntc-grid parents height manually
    return largestTranslate + largestHeight + EXTRA_SPACE
}


export default function EditableLayout({readOnly = true, height = 0, onUpdate, children}) {
    const [editMode, setEditMode] = useState(false);
    const [parentStyle, setParentStyle] = useState('unset')
    const [parentHeight, setParentHeight] = useState(0)

    let theme = useTheme()

    /**
     *  Allows the HOC to set the edit mode
     *  Ex. going to a new page, edit mode should be turned off
     */
    useEffect(() => {
        setEditMode(!readOnly);
    }, [readOnly]);


    /**
     *  Allows the HOC to set the height
     *  The Editable Layout doesn't know anything about the children being passed in so
     *  assume HOC knows how to calculate the height because HOC knows the css selectors to use to get child elements.
     */
    useEffect(() => {
        setParentHeight(height);
    }, [height]);


    /**
     *  When in edit mode, draw a border around the editable container.
     *  Hide border when not in edit mode.
     */
    useEffect(() => {
        if (editMode) {
            setParentStyle( `2px dashed ${theme.palette.custom.editableLayoutEditBorder}`)
        }
        else {
            setParentStyle( `2px dashed rgba(0, 0, 0, 0.0)`)
        }
    }, [editMode]);

    /**
     *  Reverse the editMode, and update the HOC
     */
    const toggleEditMode = () => {
        setEditMode(!editMode);
        onUpdate(!editMode);
    }

    const SWITCH_EDIT_ENABLED = "Layout - Editable";
    const SWITCH_EDIT_DISABLED = "Layout - Locked";
    const INSTRUCTIONS_EDIT_DISABLED = "Need to re-order items on this page?  Click here ";
    const INSTRUCTIONS_EDIT_ENABLED = "Done re-ordering items on this page?  Click here ";

    return (
        <Box>
            {/*  TOP EDIT SWITCH - Only visible in Edit Mode */}
            <Stack className={'fade-in'}
                   alignItems={'center'}
                   justifyContent={'center'}
                   marginTop={'5px'}
                   direction={"row"}
                   sx={{transition: 'width 0.3s',
                   position: 'relative',
                   zIndex: '0'
                }}
            >
                <label>
                    {editMode ? INSTRUCTIONS_EDIT_ENABLED : INSTRUCTIONS_EDIT_DISABLED }
                    &nbsp;
                </label>
                <Box sx={{
                        fontSize: '0.9em',
                        marginRight: `${marginWidth}px`,
                        transition: 'width 0.3s',
                        backgroundColor: `${theme.palette.custom.editableLayoutControlBackground}`,
                        border: `1px solid ${theme.palette.custom.editableLayoutControlBorder}`,
                        padding: '4px',
                        borderRadius: '6px'
                    }}
                >
                    <IOSSwitch checked={editMode} onClick={toggleEditMode}/>
                    <label style={{ color: `${theme.palette.custom.defaultText}`}}>
                        &nbsp;
                        {editMode ? SWITCH_EDIT_ENABLED : SWITCH_EDIT_DISABLED}
                    </label>
                </Box>
            </Stack>

            {/*  CONTAINER WITH BORDER  & CHILDREN */}
            <Box sx={{minHeight: parentHeight, transition: 'min-height 0.5s ease-in',
                border: parentStyle, marginTop: '5px', borderRadius: '4px' }}>
                {children}
            </Box>

            {/*  BOTTOM EDIT SWITCH */}
            <Stack className={'fade-in'}
                   alignItems={'center'}
                   justifyContent={'center'}
                   marginTop={'5px'}
                   direction={"row"}
                   sx={{transition: 'width 0.3s',
                   position: 'relative',
                   zIndex: '0'   // This should be behind its children or it will look strange as the container height changes
                }}
            >
                <label>
                    {editMode ? INSTRUCTIONS_EDIT_ENABLED : INSTRUCTIONS_EDIT_DISABLED }
                    {/*Need to reorder systems on this page?  Click here */}
                    &nbsp;
                </label>
                <Box sx={{
                        fontSize: '0.9em',
                        marginRight: `${marginWidth}px`,
                        transition: 'width 0.3s',
                        backgroundColor: `${theme.palette.custom.editableLayoutControlBackground}`,
                        border: `1px solid ${theme.palette.custom.editableLayoutControlBorder}`,
                        padding: '4px',
                        borderRadius: '6px',
                    }}
                >
                    <IOSSwitch checked={editMode} onClick={toggleEditMode}/>
                    <label style={{ color: `${theme.palette.custom.defaultText}`}}>
                        &nbsp;
                        {editMode ? SWITCH_EDIT_ENABLED : SWITCH_EDIT_DISABLED}
                    </label>
                </Box>
            </Stack>
        </Box>
    )
}