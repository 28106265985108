import React from 'react'
import { ThemeProvider } from '@mui/material'

/**
 * A custom theme provider identical to MUI's theme provider
 */
// This theme provider must be used in asset instead of declaring a new theme provider in asset to carry over colors from Theme.ts
export const NTCThemeProvider = ({ children, theme }) => (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
)
