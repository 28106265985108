import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import { FormLabel, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import ImageUploading from 'react-images-uploading'
import { useForm } from 'react-hook-form'
import { ClientContext, PageContext } from '../../Context'
import { User } from 'generated/client/User'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import { useTranslation } from 'react-i18next'
import NTCButton from '../inputs/buttons/NTCButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserValidationSchema } from '../user/UserForm'
import { getMediaItemByParentId } from '../../utils/HTTP/MediaUtils'
import { MediaApiRoutes } from '../../utils/ServerRoutes'
import UserApiService from 'utils/UserHttpUtils'
import * as MediaApiService from 'utils/HTTP/MediaUtils'
import { handleException } from 'utils/HTTPUtils'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#5197DE',
        color: '#5197DE',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
}))

interface PersonalInformationProps {
    showResponseStatus: any,
    actionHandler: any
}

const PersonalInformation = ({
    showResponseStatus,
    actionHandler = (p) => console.log(p)
}: PersonalInformationProps) => {

    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const [images, setImages] = useState([])
    const [user, setUser] = useState<User>()
    const [expanded, setExpanded] = React.useState(true)

    const validationSchema = Yup.object().shape({
        ...UserValidationSchema(t)
    })

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            first_name: '', last_name: '', email: '', phone: ''
        },
        mode: 'all'
    })

    useEffect(() => {
        if (!client.id) return
        getUserInfo()
        // getUserImage()

    }, [client.id])

    const changeProfilePhoto = async (media) => {
        let formData = new FormData()

        formData.append('caption', media[0].file.name)
        formData.append('publicFilename', media[0].file.name)
        formData.append('sortIndex', '0')
        formData.append('attachment', media[0].file)

        // Delete the existing image,
        MediaApiService.createMediaItem(client.id, formData).then((res) => {
            setImages(prevState => [...prevState, res])
            showResponseStatus({ success: true, entity: 'Media', message: 'Media upload successfully!' })
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Media', message: ex.message })
            console.log(ex)
        })
        setImages(media)
    }

    function getUserInfo() {
        UserApiService.fetchUserProfile().then((res: User) => {
            setValue('id', res.id)
            setValue('email', res.email)
            setValue('first_name', res.first_name)
            setValue('last_name', res.last_name)
            setValue('phone', res.phone)
            setUser(res)
            trigger()
        }).catch(ex => console.log(ex))
    }

    function getImagesByUserId(id) {
        return getMediaItemByParentId(id)
    }

    function getUserImage() {
        getImagesByUserId(client.id).then(res => {
            if (!res.length) return

            const mediaId = res[res.length - 1].id
            setImages([{ data_url: `${MediaApiRoutes.base}/${mediaId}/download` }])
        }).catch(ex => console.log(ex))
    }

    const handleDelete = () => {
        getImagesByUserId(client.id).then(res => {
            if (!res.length) return
            res.map(mediaItem => MediaApiService.deleteMediaItem(mediaItem.id))
            setImages([])
        }).catch(ex => console.log(ex))
    }

    const onSubmit = (data) => {
        setLoadingPanel(true)
        // Remove fields from data that we don't allow updating on the server
        delete (data['id'])
        delete (data['email'])

        UserApiService.updateProfile({ user: data }).then(res => {
            setUser(res)
            setClient({ ...client, ...res }) // Refresh client data once user information modified
            showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.edit`) })
        }).catch(async (ex: any) => {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'User', message: errorMessage })
        }).finally(()=> {
            setLoadingPanel(false)
        })
    }

    const getUserFields = (user) => {
        return [
            {
                id: 'first_name',
                label: t('forms.user.field.firstName.label'),
                defaultValue: user?.first_name,
                required: true
            },
            {
                id: 'last_name',
                label: t('forms.user.field.lastName.label'),
                defaultValue: user?.last_name,
                required: true
            },
            {
                id: 'email',
                label: t('forms.user.field.email.label'),
                defaultValue: user?.email,
                required: true,
                readonly: true
            },
            {
                id: 'phone',
                label: t('forms.user.field.phone.label'),
                defaultValue: user?.phone,
                required: false
            }
        ]
    }

    return (
        <Accordion expanded={expanded} onChange={(ev, expanded) => { setExpanded(expanded) }}>
            <AccordionSummary
                sx={{ backgroundColor: theme.palette.custom.appbarHeader }} expandIcon={<ExpandMoreIcon />}>
                <FormControl>
                    <FormLabel sx={{ fontWeight: 'bold', color: theme.palette.custom.defaultText }}>Personal
                        Information</FormLabel>
                </FormControl>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container display={'none'}>
                    <Stack direction="row" alignItems="center" sx={{ marginBottom: '30px' }}>
                        <StyledBadge overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot">
                            <Avatar
                                sx={{ width: '100px', height: '100px' }} alt="image"
                            // src={images[0]?.data_url} 
                            />
                        </StyledBadge>
                        <Stack direction="column" sx={{ marginLeft: '15px' }}>
                            <ImageUploading
                                value={images}
                                onChange={changeProfilePhoto}
                                dataURLKey="data_url"
                                acceptType={['jpg', 'png', 'jpeg']}
                            >
                                {({
                                    onImageUpload,
                                    dragProps
                                }) => (
                                    <Button
                                        startIcon={<UploadIcon sx={{ color: '#5197DE' }} />}
                                        color="info"
                                        size="small"
                                        variant="outlined"
                                        sx={{ marginBottom: '7px' }}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        {!!images[0] ? 'Update Image' : 'Upload Image'}
                                    </Button>
                                )}
                            </ImageUploading>
                            {!!images[0] &&
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={() => handleDelete()}
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    sx={{ marginTop: '10px' }}
                                >
                                    Delete Image
                                </Button>
                            }
                        </Stack>
                    </Stack>
                </Grid>
                {user &&
                    <Grid container spacing={2} pl={2} sx={{ mt: 2 }}>
                        <Grid container spacing={1}>
                            {getUserFields(user).map((field, index) => (
                                <Grid item xs={12} sm={6} key={field.id}>
                                    <FormTextControl
                                        id={field.id}
                                        name={field.id}
                                        readonly={field.readonly}
                                        defaultValue={field.defaultValue}
                                        label={field.label}
                                        required={field.required}
                                        {...{ register, errors }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={1} justifyContent="end"
                            sx={{ marginTop: '7px', padding: '0px 16px' }}>
                            <Grid item>
                                <NTCButton onClick={() => {
                                    reset(user)
                                    setExpanded(false)
                                }} text={t('common.actions.cancel')} />
                            </Grid>
                            <Grid item>
                                <NTCButton
                                    onClick={handleSubmit(onSubmit)} text={t('common.actions.save')}
                                    backgroundColor={theme.palette.custom.nomadRed}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </AccordionDetails>
        </Accordion>
    )
}

export default PersonalInformation
