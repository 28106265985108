import React from 'react'
import { Grid } from '@mui/material'
import WeatherHeader from './WeatherHeader'

const WeatherLayout = ({title, children, sectionSubHeader = null }) => {
  return (
    <Grid container mb={4}>
      <Grid item xs={12} mb={2}>
        <WeatherHeader title={title} />
          {sectionSubHeader || null}
      </Grid>
      {children}
    </Grid>
  )
}

export default WeatherLayout