import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material'
// @ts-ignore
import { ErrorMessage } from '@hookform/error-message'

interface FormTextControlProps {
    register?: any,
    errors?: any,
    id: string,
    name: string,
    label: string,
    defaultValue?: string,
    placeholder?: string,
    readonly?: boolean,
    type?: string,
    required?: boolean
    autoComplete?: string
}

const FormTextControl = ({
    register,
    errors,
    name,
    id,
    label,
    defaultValue,
    placeholder,
    readonly,
    type,
    required = true,
    autoComplete = ''
}: FormTextControlProps) => {
    const theme = useTheme()
    const isError = !!errors[name]
    const textColor = isError ? theme.palette.custom.nomadRed : `${theme.palette.custom.moduleTitle} !important`

    return (
        <Fragment>
            <TextField
                autoComplete={autoComplete}
                InputLabelProps={{ shrink: true }}
                type={type}
                disabled={readonly}
                required={required}
                id={id}
                name={name}
                label={label}
                fullWidth
                variant={'standard'}
                defaultValue={defaultValue}
                placeholder={placeholder}
                margin={'dense'}
                {...register(name)}
                error={isError}
                sx={{
                    '& .MuiFormLabel-root': { color: textColor },
                    '& fieldset': { borderColor: textColor },
                    '& .MuiInput-underline::after': { borderBottomColor: theme.palette.custom.defaultText }
                }}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <Typography sx={
                    { color: theme.palette.custom.nomadRed }}>
                    {message}
                </Typography>}
            />

        </Fragment>
    )
}

export default FormTextControl