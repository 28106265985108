import { HTTPMethod } from '../HttpType'
import { requestEndpoint } from '../HTTPUtils'
import useFetch from './useFetch'

const organizationRoutes = '/orgs'

/**
 * HTTP request to get the list of organizations, use this function where refresh required specially on List pages
 * @param controller
 */
function fetchOrganizations(param = {}) {
    return useFetch<any, any[]>(organizationRoutes, param)
}

// Get all users belongs to a organization
function fetchOrgUsers(id: string, param?: any) {
    const endpoint = `${organizationRoutes}/${id}/users`
    return useFetch<any, any[]>(endpoint, param)
}

// Get all assets belongs to a organization
function fetchOrgAssets(id: string, param?: any) {
    const endpoint = `${organizationRoutes}/${id}/ntc_assets`
    return useFetch<any, any[]>(endpoint, param)
}

// Get all roles belongs to a organization
async function fetchAllOrgRoles(id: string, param?: any) {
    const endpoint = `${organizationRoutes}/${id}/roles`
    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.GET, param
    )
}

async function getOrganizationById(id, param?: any) {
    const endpoint = `${organizationRoutes}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.GET, param
    )
}

/**
 * Submit the organization basic information to create the entry
 * @param data
 * @param controller
 */
async function createOrganization(data: any, param?: any) {
    const endpoint = organizationRoutes
    const objectifyData = { org: { ...data, id: undefined } }
    return await requestEndpoint<any, any>(
        endpoint, objectifyData, HTTPMethod.POST, param
    )
}

/**
 * Submit the organization basic information to update the entry
 * @param data
 * @param controller
 */
async function updateOrganization(data: any, param?: any) {
    const endpoint = `${organizationRoutes}/${data.id}`
    const objectifyData = { org: { ...data, id: undefined } }

    return await requestEndpoint<any, any>(
        endpoint, objectifyData, HTTPMethod.PUT, param
    )
}

/**
 *
 * @param id
 * @param controller
 */
async function deleteOrganization(id: string, param?: any) {
    const endpoint = `${organizationRoutes}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.DELETE, param
    )
}

const OrganizationApiService = {
    fetchOrganizations,
    getOrganizationById,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    fetchOrgUsers,
    fetchAllOrgRoles,
    fetchOrgAssets,
}

export default OrganizationApiService