export function distinct<T>(array: T[]): T[] {
    return [...new Set(array)]
}

// Function to check if an object is in local storage, if not returns the default value
export function checkLocalStorage(localStorageKey, defaultValue) {
    try {
        const value = localStorage.getItem(localStorageKey)
        return value ? JSON.parse(value) : defaultValue
    } catch (e) {
        return defaultValue
    }
}

export function deepStrictEquals(obj1, obj2) {

    if (obj1 === obj2) {
        return true
    }

    const type = typeof obj1
    if (type !== 'object' || type !== typeof obj2) {
        return false
    }

    const entries = Object.entries(obj1)
    if (entries.length !== Object.keys(obj2).length) {
        return false
    }

    for (const [key, value] of entries) {
        if (!deepStrictEquals(value, obj2[key])) {
            return false
        }
    }

    return true
}

// Returns event only if key press is enter key
export function handleConfirmKeyPress(event, updateFunction, value) {
    if (event.key === 'Enter' || event.key === 'Escape') {
        updateFunction(value)
    }
}
