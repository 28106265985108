import * as React from 'react'
import { Fragment, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { Avatar, Menu, MenuItem, useTheme } from '@mui/material'
import { ExpandMoreIcon, getIconById } from 'static/svgs/icons'
import ListItemIcon from '@mui/material/ListItemIcon'
import IOSSwitch from '../../inputs/switches/IOSSwitch'
import LanguageChanger from '../../inputs/dropdowns/LanguageChanger'
import { useTranslation } from 'react-i18next'
import { ClientContext } from 'Context'
import { Theme } from 'generated/client/Theme'
import { AssetListDrawer } from '../../assets/AssetListDrawer'
import { getMediaItemByParentId } from '../../../utils/HTTP/MediaUtils'
import { MediaApiRoutes } from 'utils/ServerRoutes'
import UserApiService from 'utils/UserHttpUtils'
import { t } from 'i18next'

interface ProfileMenuProps {
    profileName?: string,
    profileTitle?: string,
    profileImage?: string,
    isMobile: boolean,
    showUserInfo?: boolean,
    elementId?: string,
    children?,
    lastLogin: string
    isCommandNode?: boolean
}

interface ProfileMenuItemsProps {
    callback?
    items?
}

const ProfileMenu = ({
    profileName,
    profileTitle,
    profileImage,
    isMobile,
    showUserInfo = true,
    elementId,
    children,
    lastLogin
}: ProfileMenuProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [profileImageUrl, setProfileImageUrl] = useState('')
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    function getUserImage() {
        getMediaItemByParentId(client.id).then(res => {
            if (!res.length) return

            const mediaId = res[res.length - 1].id
            setProfileImageUrl(`${MediaApiRoutes.base}/${mediaId}/download`)
        }).catch(ex => console.log(ex))
    }

    function formatTimestamp(milliseconds: String): String {
        return `${new Date(lastLogin).toLocaleDateString()}, ${new Date(lastLogin).toLocaleTimeString()}`
    }

    useEffect(() => {
        //getUserImage()
    }, [])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mr: '26px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}
                onClick={handleClick}
                aria-label={'Profile Menu'}
                aria-controls={open ? elementId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>

                <Box sx={{ fontSize: '.75rem', fontWeight: '700', mr: 1 }}>
                    {lastLogin ? `${t('menu.lastLogin')}: ${formatTimestamp(lastLogin)}` : t('menu.firstLoginMessage')}
                </Box>
                {/* <Box sx={{ paddingLeft: '1rem' }}>
                    <Avatar alt={profileName} src={profileImage || profileImageUrl} sx={isMobile ? {
                        width: '28px',
                        height: '28px',
                        mr: '10px',
                        borderRadius: '100%',
                        border: '1px solid',
                        borderColor: 'white'
                    } :
                        {
                            width: '42px',
                            height: '42px',
                            borderRadius: '100%',
                            border: '1px solid #10A6FA'
                        }} />
                </Box> */}

                {showUserInfo && (
                    <Box display={'grid'} sx={{ margin: '0 0.65rem' }}>
                        <Box component="label"
                            sx={{ fontSize: '.75rem', fontWeight: '700', margin: 0 }}>
                            {profileName}
                        </Box>
                        <Box component="span" sx={{
                            fontSize: '0.6rem',
                            fontWeight: '400',
                            display: 'block'
                        }}>
                            {profileTitle}
                        </Box>
                    </Box>)
                }
                <Box sx={{ '& svg': { width: '1.5rem', height: '1.5rem', fill: theme.palette.custom.breadcrumb } }}>
                    <ExpandMoreIcon />
                </Box>

            </Box>
            {/* <Box>
                <AssetListDrawer />
            </Box> */}
            {elementId &&
                <DropDownMenu open={open} handleClose={handleClose} anchorEl={anchorEl} elementId={elementId}>
                    {children}
                </DropDownMenu>
            }
        </Box>
    )
}

const DropDownMenu = ({ children, open, handleClose, anchorEl, elementId }) => {
    const theme = useTheme()
    return (
        <Menu
            anchorEl={anchorEl}
            id={elementId}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    background: theme.palette.custom.menuBack,
                    color: theme.palette.custom.menuText,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        backgroundColor: theme.palette.custom.menuBack,
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    },
                    '& svg': {
                        fill: theme.palette.custom.menuIcon,
                        height: 20,
                        width: 20
                    },
                    '& .lang-selector': {
                        backgroundColor: theme.palette.custom.menuBack,
                        color: theme.palette.custom.menuText
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {children}
        </Menu>
    )
}

const ProfileMenuItems = ({ items = [], callback }: ProfileMenuItemsProps) => {
    const { t } = useTranslation()

    return (
        <Fragment>
            {items.map((item, index) => (
                <MenuItem
                    aria-label={t(`menu.${item.id}`)}
                    onClick={(ev) => callback(ev, item.id)}
                    key={`${item.id}-${index}`}
                    divider={true}
                    autoFocus={true}
                >
                    {item.iconId && <ListItemIcon>{getIconById(item.iconId)}</ListItemIcon>}
                    {t(`menu.${item.id}`)}
                    {item.content && <item.content />}
                </MenuItem>
            ))}
        </Fragment>
    )
}

interface ProfileMenuListProps {
    isDarkMode: boolean,
    toggleDarkMode: any
    isCommandNode: boolean,
    callback?: any,
    showResponseStatus?: any
}

const ProfileMenuDefaultList = ({
    isDarkMode,
    toggleDarkMode,
    isCommandNode,
    callback,
    showResponseStatus
}: ProfileMenuListProps) => {
    const profileSettingsItem = { id: 'profileSettings', iconId: 'settings', content: null }
    const darkModeItem = {
        id: 'darkMode',
        iconId: 'darkmode',
        content: () => <ThemeSwitch {...{ toggleDarkMode, isDarkMode, callback, showResponseStatus }} />
    }
    const languageItem = {
        id: 'language',
        iconId: 'language',
        content: () => (
            <Box paddingLeft={4}>
                <LanguageChanger {...{ callback, showResponseStatus }} />
            </Box>
        )
    }
    const logoutOrLoginItem = isCommandNode
        ? { id: 'login', iconId: 'login', content: null }
        : { id: 'logout', iconId: 'logout', content: null }

    return [profileSettingsItem, darkModeItem, languageItem, logoutOrLoginItem]
}

const ThemeSwitch = ({ isDarkMode, toggleDarkMode, callback, showResponseStatus }) => {
    const { client } = useContext(ClientContext)
    const [checked, setChecked] = React.useState(isDarkMode)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.checked
        setChecked(event.target.checked)
        // Map the selected theme to the corresponding enum value
        const newTheme = selectedValue ? Theme.DARK : Theme.LIGHT
        toggleDarkMode && toggleDarkMode()
        // Update user theme
        const { first_name, last_name, email, id } = client
        UserApiService.updateProfile({ user: { theme: newTheme } }).then(res => {
            callback('theme', newTheme)
            showResponseStatus({ success: true, entity: 'User', message: t('menu.themeUpdated') })
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'User', message: ex.message })
        })
    }
    return (
        <Box paddingLeft={4}>
            <IOSSwitch checked={checked} onChange={handleChange} />
        </Box>
    )
}

export {
    ProfileMenu,
    ProfileMenuItems,
    ProfileMenuDefaultList,
    DropDownMenu
}