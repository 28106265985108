import React, { lazy } from 'react'
import { useTranslation } from 'react-i18next'

import { ASSET, LIST, CREATE, UPDATE, REMOTE_CONTROL, REMOTE_VIEW, ROLE, USER, isAuthorized, isSuperAdmin, GROUP, READ } from 'generated/auth/Permissions'

//  lazy load components
const DashboardPage = lazy(() => import('../pages/dashboard/DashboardPage'))
const UserProfilePage = lazy(() => import('../pages/user/UserProfilePage'))
const RolePage = lazy(() => import('../pages/role/RolePage'))
const UserPage = lazy(() => import('../pages/user/UserPage'))
const EditOrganizationPage = lazy(() => import('../pages/organizations/EditOrganizationPage'))
const OrganizationPage = lazy(() => import('../pages/organizations/OrganizationPage'))
const AssetListPage = lazy(() => import('../pages/assets/AssetListPage'))
const AssetFrame = lazy(() => import('../pages/assets/AssetFrame'))
const AssetStatus = lazy(() => import('../pages/assets/AssetStatus'))
const TrainingPage = lazy(() => import('../pages/trainings/TrainingPage'))
const GroupPage = lazy(() => import('../pages/groups/GroupPage'))

export const NotFoundPage = () => {
    const { t } = useTranslation()
    return (
        <div>
            <h1>{t('errors.general')}</h1>
            <p>{t('errors.404')}</p>
        </div>
    )
}

export function getRouteDefinitions(client: any) {
    return [
        { path: '/', component: DashboardPage, authorize: true },
        { path: '/overview', component: DashboardPage, authorize: true },
        { path: '/dashboard', component: DashboardPage, authorize: true },
        { path: '/profile', component: UserProfilePage, authorize: true },
        {
            path: '/roles',
            component: RolePage,
            authorize: isAuthorized(ROLE, LIST),
            children: [
                { path: 'create', component: RolePage, authorize: isAuthorized(ROLE, CREATE) },
                { path: ':id/edit', component: RolePage, authorize: isAuthorized(ROLE, UPDATE) },
                { path: ':id/clone', component: RolePage, authorize: isAuthorized(ROLE, CREATE) }
            ]
        },
        {
            path: '/users',
            component: UserPage,
            authorize: isAuthorized(USER, LIST),
            children: [
                { path: 'create', component: UserPage, authorize: isAuthorized(USER, CREATE) },
                { path: ':id/edit', component: UserPage, authorize: isAuthorized(USER, UPDATE) },
                { path: ':id/assign', component: UserPage, authorize: isAuthorized(USER, UPDATE) }
            ]
        },
        {
            path: '/ntc_assets',
            component: AssetListPage,
            authorize: isAuthorized(ASSET, LIST),
            children: [
                { path: 'create', component: AssetListPage, authorize: isAuthorized(ASSET, CREATE) },
                { path: ':id/edit', component: AssetListPage, authorize: isAuthorized(ASSET, UPDATE) },
                { path: ':id/transfer', component: AssetListPage, authorize: isSuperAdmin(client) },
            ]
        },
        { path: '/ntc_assets/:id/remote', component: AssetFrame, authorize: isAuthorized(ASSET, REMOTE_CONTROL), children: [] },
        { path: '/ntc_assets/:id/status', component: AssetStatus, authorize: isAuthorized(ASSET, REMOTE_VIEW), children: [] },
        {
            path: '/trainings',
            component: TrainingPage,
            authorize: isSuperAdmin(client),
            children: [
                { path: 'create', component: TrainingPage, authorize: isSuperAdmin(client) },
                { path: ':id/edit', component: TrainingPage, authorize: isSuperAdmin(client) },
                { path: ':id/clone', component: TrainingPage, authorize: isSuperAdmin(client) }
            ]
        },
        {
            path: '/organizations',
            component: OrganizationPage,
            authorize: isSuperAdmin(client),
            children: [
                { path: 'create', component: OrganizationPage, authorize: isSuperAdmin(client) },
            ]
        },
        {
            path: '/organizations/edit/:id', // Edit organization is separate page, So moving out from children routes
            component: EditOrganizationPage,
            authorize: isSuperAdmin(client),
            children: []
        },
        {
            path: '/groups',
            component: GroupPage,
            authorize: isAuthorized(GROUP, LIST),
            children: [
                { path: 'create', component: GroupPage, authorize: isAuthorized(GROUP, CREATE) },
                { path: ':id/edit', component: GroupPage, authorize: isAuthorized(GROUP, UPDATE) },
                { path: ':id/clone', component: GroupPage, authorize: isAuthorized(GROUP, CREATE) },
                { path: ':id/view', component: GroupPage, authorize: isAuthorized(GROUP, READ) }
            ]
        },
    ]
}