enum FormMode {
    CREATE = 'create',
    EDIT = 'edit',
    VIEW = 'view',
    CLONE = 'clone',
    DELETE = 'delete'
}

enum FieldType {
    TEXT = 'text',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    MULTISELECT = 'multiselect',
    DATE = 'date'
}

export {
    FormMode,
    FieldType
}
