import React from 'react'
import { Box, useTheme } from '@mui/material'
import { cursorPointer } from 'styles/GlobalSXProps'
import { UnfoldMore } from '@mui/icons-material'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './NTCGridLayout.css'

/**
 * This component defines the custom resize handle which is used in the grid layout
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */

// Forward the reference so that the react-grid-layout can control the handle
export const NTCResizeHandle = React.forwardRef((props, ref) => {
    let theme = useTheme()
    const { handleAxis, ...restProps } = props

    return (
        // Position Box on top of other elements with zIndex: 1, position: 'absolute'
        // Make box go to bottom right corner of module with bottom: -10, right: 0
        // Do not set as a percentage or position will change when box grows
        <Box sx={{ ...cursorPointer, zIndex: 1, position: 'absolute', bottom: -5, right: 0 }} ref={ref} {...restProps}>
            {/*Actual Icon being used*/}
            <UnfoldMore sx={{
                transform: 'rotate(-45deg)',
                color: theme.palette.custom.greyScaleInactive
            }}/>
        </Box>
    )
})
