import React from 'react'
import Profile from 'components/profile/Profile'
import { FlashNotification } from 'components/display/modals/FlashNotification'


function UserProfilePage(props) {

    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }
    return (
        <>
            <Profile actionHandler={(args) => { console.log(args) }} showResponseStatus={showResponseStatus} />
            {showNotification ?
                <FlashNotification open={showNotification}
                    setOpen={setNotification} message={notificationData.message}
                    severity={notificationData.severity} /> :
                <></>}
        </>
    )
}

export default UserProfilePage
