import * as React from 'react'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function NewsItem({ newsItem }) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [showFullText, setShowFullText] = useState(false)
    const toggleText = () => {
        setShowFullText(!showFullText)
    }
    return (
        <Grid sx={{ border: 1, margin: 1, borderColor: theme.palette.custom.knobRange }}>
            <Grid item sx={{ display: 'flex' }}>
                <Box>
                    <CardContent>
                        <Link target={'_blank'} href={newsItem.link}
                            sx={{ color: theme.palette.custom.nomadRed, cursor: 'pointer' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {newsItem.title}
                            </Typography>
                        </Link>
                        <Box display={'flex'} justifyContent={'flex-start'} columnGap={2}>
                            <Link target={'_blank'} href={newsItem.source_url}
                                sx={{ color: theme.palette.custom.defaultText, fontStyle: 'italic', textTransform: 'capitalize' }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {newsItem.source_id}
                                </Typography>
                            </Link>
                            <Typography variant="subtitle2" gutterBottom sx={{ color: theme.palette.custom.defaultText, fontStyle: 'italic' }}>
                                - {newsItem.published_date}
                            </Typography>
                        </Box>
                    </CardContent>
                </Box>
            </Grid>
        </Grid>
    )
}
