import { createTheme } from '@mui/material/styles'

// Mui global and custom theme provider
// All custom colors must be declared in light and dark
// https://mui.com/material-ui/customization/theming/

declare module '@mui/material/styles/createPalette' {
    interface ICustomBaseTheme { //Interface to make sure light and dark themes match
        appbarHeader?: string,
        breadcrumb?: string,
        buttonHoverColor?: string,
        contactUsBackground?: string,   
        contrast?: string,
        contrastButton?: string,
        defaultText?: string,
        divider?: string,
        elementBorder?: string,
        gaugeGradientStart: string,
        gaugeGradientStop: string,
        greyScaleActive?: string,
        greyScaleInactive?: string,
        knobRange?: string,
        knobValue?: string,
        menuBack?: string,
        menuIcon?: string,
        menuText?: string,
        moduleBorder?: string,
        moduleIcon?: string,
        moduleStatus?: string,
        moduleTitle?: string,
        nomadRed?: string,
        oddTableStripe?: string,
        editButton?: string,
        permissionTableBgEven: string,
        permissionTableBgOdd: string
        permissionTableText: string,
        sliderBlue?: string,
        sliderGreen?: string,
        sliderLightGreen?: string,
        sliderGray?: string,
        stopButton?: string,
        stopButtonBG?: string,
        svgFill?: string,
        tableSpanBackground?: string,
        tableSpanBorderBottom?: string,
        whiteText?: string,
        backdropColor?: string,
        editableLayoutEditBorder?: string,
        editableLayoutControlBackground?: string,
        editableLayoutControlBorder?: string,
    }

    // Extending the theme class to make custom themes
    interface ICustomOptions extends ICustomBaseTheme {}

    interface ICustomColor extends ICustomBaseTheme {}

    interface PaletteOptions {
        custom?: ICustomColor
    }

    interface Palette {
        custom: ICustomOptions
    }
}

const colorPalette = {
    reds: {
        nomadRed: '#CC2027',             // HR BRAND GUIDE COLOR
        nomadRedRGB: '204, 32, 39'
    },
    blues: {
        blue300: '#10A6FA',
        blue400: '#0C6ABD',
        blue600: '#2948FF'
    },
    greens: {
        green050: '#d2f8d2',
        green100: '#75E6DA',
        green400: '#69be28'
    },
    grays: {
        flatheadGray: '#E2E3E2',        // HR BRAND GUIDE COLOR
        gray100: '#cccccc',
        gray200: '#A7A7A7',
        gray300: '#838383',
        gray400: '#5d5c5c',
        gray500: '#333333',
        gray550: "#292929",
        gray600: '#2c2c2c',
        gray700: '#171717',
        gray800: '#0B0B0B'
    },
    neutrals: {
        beige: '#F6F6F6',
        fullWhite: '#FFFFFF',
        fullBlack: '#000000'           // HR BRAND GUIDE COLOR
    }
}

export const lightTheme = createTheme({
    typography: {
        'fontFamily': 'Montserrat'
    },
    palette: {
        mode: 'light',
        primary: {
            main: colorPalette.neutrals.fullWhite
        },
        custom: {
            appbarHeader: colorPalette.neutrals.beige,
            breadcrumb: colorPalette.grays.gray500,
            buttonHoverColor: colorPalette.grays.gray400,
            contactUsBackground: colorPalette.neutrals.beige,
            contrast: colorPalette.neutrals.fullBlack,
            contrastButton: colorPalette.grays.gray300,
            defaultText: colorPalette.grays.gray700,
            divider: colorPalette.grays.gray200,
            editButton: colorPalette.blues.blue400,
            elementBorder: colorPalette.neutrals.fullBlack,
            gaugeGradientStart: colorPalette.greens.green100,
            gaugeGradientStop: colorPalette.blues.blue600,
            greyScaleActive: colorPalette.neutrals.fullWhite,
            greyScaleInactive: colorPalette.grays.gray300,
            knobRange: colorPalette.grays.gray100,
            knobValue: colorPalette.reds.nomadRed,
            menuBack: colorPalette.neutrals.fullBlack,
            menuIcon: colorPalette.blues.blue300,
            menuText: colorPalette.neutrals.fullWhite,
            moduleBorder: '1px #c2c2c2 solid',
            moduleIcon: colorPalette.grays.gray300,
            moduleStatus: colorPalette.grays.gray500,
            moduleTitle: colorPalette.neutrals.fullBlack,
            nomadRed: colorPalette.reds.nomadRed,
            oddTableStripe: colorPalette.neutrals.beige,
            permissionTableBgEven: colorPalette.neutrals.fullWhite,
            permissionTableBgOdd: colorPalette.neutrals.beige,
            permissionTableText: colorPalette.neutrals.fullBlack,
            sliderBlue: colorPalette.blues.blue300,
            sliderGreen: colorPalette.greens.green400,
            sliderLightGreen: colorPalette.greens.green050,
            sliderGray: colorPalette.grays.gray400,
            stopButton: colorPalette.neutrals.fullWhite,
            stopButtonBG: colorPalette.neutrals.fullBlack,
            svgFill: colorPalette.grays.gray400,
            tableSpanBackground: colorPalette.grays.flatheadGray,
            tableSpanBorderBottom: '1px solid rgba(205,205,205,1)',
            whiteText: colorPalette.neutrals.fullWhite,
            backdropColor: 'rgba(0, 0, 0, 0.5)',
            editableLayoutEditBorder: `rgba(${colorPalette.reds.nomadRedRGB}, 0.25)`,
            editableLayoutControlBackground: colorPalette.neutrals.beige,
            editableLayoutControlBorder: colorPalette.grays.gray100,
        }
    }
})
export const darkTheme = createTheme({
    typography: {
        'fontFamily': 'Montserrat'
    },
    palette: {
        mode: 'dark',
        primary: {
            main: colorPalette.grays.gray700
        },
        custom: {
            appbarHeader: colorPalette.grays.gray800,
            breadcrumb: colorPalette.grays.flatheadGray,
            buttonHoverColor: colorPalette.grays.gray400,
            contactUsBackground: colorPalette.grays.gray400,
            contrast: colorPalette.neutrals.fullWhite,
            contrastButton: colorPalette.grays.gray400,
            defaultText: colorPalette.neutrals.fullWhite,
            divider: colorPalette.grays.gray200,
            editButton: colorPalette.blues.blue400,
            elementBorder: colorPalette.grays.gray100,
            gaugeGradientStart: colorPalette.greens.green100,
            gaugeGradientStop: colorPalette.blues.blue600,
            greyScaleActive: colorPalette.neutrals.fullWhite,
            greyScaleInactive: colorPalette.grays.gray100,
            knobRange: colorPalette.grays.gray400,
            knobValue: colorPalette.reds.nomadRed,
            menuBack: colorPalette.neutrals.fullBlack,
            menuIcon: colorPalette.blues.blue300,
            menuText: colorPalette.neutrals.fullWhite,
            moduleBorder: '1px #363636 solid',
            moduleIcon: colorPalette.grays.flatheadGray,
            moduleStatus: colorPalette.grays.flatheadGray,
            moduleTitle: colorPalette.grays.flatheadGray,
            nomadRed: colorPalette.reds.nomadRed,
            permissionTableBgEven: colorPalette.grays.gray700,
            permissionTableBgOdd: colorPalette.grays.gray600,
            permissionTableText: colorPalette.neutrals.fullWhite,
            oddTableStripe: colorPalette.grays.gray600,
            sliderBlue: colorPalette.blues.blue300,
            sliderGreen: colorPalette.greens.green400,
            sliderLightGreen: colorPalette.greens.green050,
            sliderGray: colorPalette.grays.gray300,
            stopButton: colorPalette.neutrals.fullWhite,
            stopButtonBG: colorPalette.reds.nomadRed,
            svgFill: colorPalette.neutrals.fullWhite,
            tableSpanBackground: colorPalette.grays.gray500,
            tableSpanBorderBottom: '1px solid rgba(111,111,111,1)',
            whiteText: colorPalette.neutrals.fullWhite,
            backdropColor: 'rgb(120 120 120 / 50%)',
            editableLayoutEditBorder: `rgba(${colorPalette.reds.nomadRedRGB}, 1)`,
            editableLayoutControlBackground: colorPalette.grays.gray550,
            editableLayoutControlBorder: colorPalette.grays.gray400,
        }
    }
})

