import { RoleRoutes } from 'utils/ServerRoutes'

import { HTTPMethod } from './HttpType'
import { requestEndpoint } from './HTTPUtils'
import useFetch from './HTTP/useFetch'
import { Role } from 'generated/auth/Role'
import { PermittedState } from 'generated/auth/PermittedState'

/**
 * HTTP request to get the list of roles, use this function where refresh required specially on List pages
 * @param controller
 */
function fetchRoles() {
    return useFetch<any, Role[]>(RoleRoutes.roles)
}

async function fetchAllRoles(data?, controller?: AbortController) {
    const endpoint = RoleRoutes.roles

    return await requestEndpoint<any, Role[]>(
        endpoint, data, HTTPMethod.GET, controller
    )
}

async function fetchRole({ id }, controller?: AbortController) {
    const endpoint = RoleRoutes.role(id)

    return await requestEndpoint<any, Role>(
        endpoint, id, HTTPMethod.GET, controller
    )
}

/**
 * Submit the role basic information to create the entry
 * @param roleEntity
 * @param controller
 */
async function createRole(role: any, controller?: AbortController) {
    const endpoint = RoleRoutes.roles
    const objectifiedRole = { role: role }

    return await requestEndpoint<any, Role>(
        endpoint, objectifiedRole, HTTPMethod.POST, controller
    )
}

/**
 * Submit the role basic information to update the entry
 * @param roleEntity
 * @param controller
 */
async function updateRole(id: string, data: any, controller?: AbortController) {
    const endpoint = RoleRoutes.role(id)
    const objectifiedRole = { role: data }

    return await requestEndpoint<any, Role>(
        endpoint, objectifiedRole, HTTPMethod.PUT, controller
    )
}

/**
 *
 * @param id
 * @param controller
 */
async function deleteRole(id: string, controller?: AbortController) {
    const endpoint = `${RoleRoutes.roles}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.DELETE, controller
    )
}

async function addRolePermissions(roleId: string, permissionPayload: {
    id: string,
    targetIds: string[],
    state: PermittedState
}, controller?: AbortController) {
    const endpoint = RoleRoutes.permissions(roleId)

    return await requestEndpoint<any, Role>(
        endpoint, permissionPayload, HTTPMethod.POST, controller
    )
}

async function deleteRolePermissions(roleId: string, permissionId: string, controller?: AbortController) {
    const endpoint = RoleRoutes.permissions(roleId)

    return await requestEndpoint<string, any>(
        endpoint, permissionId, HTTPMethod.DELETE, controller
    )
}

const RoleApiService = {
    fetchRoles,
    fetchRole,
    createRole,
    updateRole,
    deleteRole,
    fetchAllRoles,
    addRolePermissions,
    deleteRolePermissions
}
export default RoleApiService

