import { HTTPMethod } from '../HttpType'
import { requestEndpoint } from '../HTTPUtils'
import useFetch from './useFetch'

const trainingRoutes = '/trainings'


function fetchTrainings(param = {}) {
    return useFetch<any, any[]>(trainingRoutes, param)
}


async function getTrainingById(id: string, param?: any) {
    const endpoint = `${trainingRoutes}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.GET, param
    )
}

async function createTraining(data: any, param?: any) {
    const endpoint = trainingRoutes
    const objectifyData = { training: { ...data, id: undefined } }
    return await requestEndpoint<any, any>(
        endpoint, objectifyData, HTTPMethod.POST, param
    )
}


async function updateTraining(data: any, param?: any) {
    const endpoint = `${trainingRoutes}/${data.id}`
    const objectifyData = { training: { ...data, id: undefined } }

    return await requestEndpoint<any, any>(
        endpoint, objectifyData, HTTPMethod.PUT, param
    )
}


async function deleteTraining(id: string, param?: any) {
    const endpoint = `${trainingRoutes}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.DELETE, param
    )
}

const TrainingApiService = {
    fetchTrainings,
    getTrainingById,
    createTraining,
    updateTraining,
    deleteTraining
}

export default TrainingApiService