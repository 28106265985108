import { Translation } from '../Translation'
import { MAXIMUM_PASSWORD_LENGTH, NON_STIG_MINIMUM_PASSWORD_LENGTH, PASSWORD_HISTORY_SIZE, SPECIAL_CHARS, STIG_MINIMUM_PASSWORD_LENGTH } from 'constants/Password'

export const spanish: Translation = {
    // Put all translations in 'Title Case' and in Alphabetical order across every file
    translation: {
        errors: {
            general: 'Error',
            404: 'Recurso no encontrado',
            noData: 'No hay datos disponibles para este activo'
        },
        reauthenticate: {
            modalTitle: 'Volver a autenticarse para privilegios elevados',
            success: 'Reautenticado exitosamente',
            info: 'Tendrás privilegios elevados durante 30 minutos'
        },
        logoff: {
            message: 'El usuario ha cerrado sesión y la sesión ha finalizado'
        },
        buttons: {
        },
        common: {
            name: 'Nombre',
            system: 'Sistema',
            type: 'Tipo',
            subtype: 'Subtipo',
            room: 'Sala {{room}}',
            start: 'Arrancar',
            stop: 'Detener',
            starting: 'Arrancar',
            stopping: 'Detener',
            status: 'Estado',
            temperature: 'Temperatura',
            temp: 'Temp.',
            volts: 'Voltios',
            stopAll: 'Para todo',
            top: 'Arriba',
            bottom: 'Abajo',
            on: 'En',
            off: 'Apagado',
            cancel: 'Cancelar',
            clear: 'Limpiar',
            never: 'Nunca',
            actions: {
                label: 'Comportamiento',
                save: 'Ahorrar',
                create: 'Crear',
                delete: 'Borrar',
                cancel: 'Cancelar',
                assign: 'Asignar rol',
                add: 'Agregar',
                submit: 'Entregar',
                reset: 'Reiniciar',
                ok: 'De acuerdo',
                forcePassword: 'Actualizar contraseñaa',
                addAnother: 'Agrega otro',
                upload: 'Subir',
                uploadAnother: 'Subir foto/vídeo',
                login: 'Acceso',
                saveAndContinue: 'Guardar Continuar',
                saveAndExit: 'Guardar la salida',
                view: 'Leer',
                edit: 'Editar',
                clone: 'Duplicar',
                next: 'Próximo',
                back: 'Atrás',
                return: 'Devolver',
                lock: 'Bloquear cuenta',
                unlock: 'Desbloquear cuenta',
                changePassword: 'Cambiar la contraseña',
                generatePassword: 'Generar contraseña',
                pair: 'Par',
                keypair: 'Mostrar par de claves',
                forgotPassword: 'Has olvidado tu contraseña',
                resetPasswordLink: 'Enviar enlace de reinicio',
                confirm_account: 'Reenviar correo electrónico de confirmación',
                user_confirmed: 'Usuario confirmado',
                impersonate: 'Personificar',
                hierarchy: 'Jerarquía',
                duplicate: 'Duplicar',
                assignment: 'Assignment',
                transfer: 'Transferir',
            },
            notifications: {
                edit: '{{name}} actualizado exitosamente',
                delete: '{{name}} borrado exitosamente',
                create: '{{name}} creado exitosamente',
                clone: '{{name}} copiado exitosamente',
                transfer: '{{name}} transferido exitosamente'
            },
            message: {
                noRecord: 'Ningún record fue encontrado.'
            }
        },
        contact: {
            phone: 'Teléfono',
            email: 'Correo Electrónico',
            mailingAddress: 'Dirección de envio',
            headQuarters: 'Sede',
            linkText: 'Auxilio'
        },
        dropDowns: {
            moduleHeader: {
                close: 'Cerca',
                collapse: 'Caída',
                addToDashboard: 'Agregar al tablero',
                graphView: 'Grafico',
                expand: 'Expandir',
                lock: 'Cerrar',
                settings: 'Ajustes',
                sliderType: 'Deslizador'
            }
        },
        forms: {
            hidden: '(oculto)',
            header: {
                label: { list: 'Lista' }
            },
            permissions: {
                field: {
                    resource: { label: 'Recurso', validation: { required: '' } },
                    action: { label: 'Acción', validation: { required: '' } },
                    description: { label: 'Descripción', validation: { required: '' } }
                }
            },
            role: {
                title: 'Rol',
                modal: {
                    confirm: {
                        delete: {
                            message: 'Esto eliminará permanentemente este rol y afectará a todos los que tengan asignado este rol. ¿Desea continuar?',
                            title: 'Eliminar rol'
                        }
                    },
                    view: {
                        header: {
                            roles: 'Roles',
                            modules: 'Módulos y acciones asignados',
                            actions: 'Acciones del usuario',
                            users: 'Individuos actualmente asignados a roles'
                        }
                    }
                },
                field: {
                    name: {
                        label: 'Nombre de rol',
                        validation: { required: 'El nombre del rol es obligatorio' }
                    },
                    level: {
                        label: 'Nivel',
                        validation: {
                            required: '',
                            optional: '\'El nivel del rol debe ser un número entero positivo'
                        },
                        notes: 'Nota: Se espera un valor más pequeño para un rol \'Superior\' y solo se permiten números enteros positivos mayores que cero'
                    },
                    description: {
                        label: 'Role Description',
                        validation: { required: 'La descripción del rol es obligatoria' }
                    },
                    permissions: {
                        label: 'Permisos de rol',
                        validation: { required: 'Se requieren permisos de rol' },
                        selectedInfo: '{{count}} elemento(s) seleccionado(s)'
                    },
                    active: {
                        label: 'Activo',
                        validation: { required: '' }
                    },
                    organizationId: {
                        label: 'Organización',
                        validation: { required: '' }
                    },
                    userId: {
                        label: 'Usuario',
                        validation: { required: 'Se requiere usuario' },
                        dropdownLabel: 'Seleccionar usuario'
                    },
                    roles: {
                        label: 'Roles',
                        validation: { required: 'Se requiere Roles' }
                    }
                },
                name: {
                    mechanics: 'Mecánico',
                    drivers: 'Conductores',
                    operators: 'Operadores',
                    administrators: 'Administradores',
                    viewer: 'Espectador',
                    owner: 'Dueño'
                }
            },
            group: {
                title: 'Grupo',
                modal: {
                    confirm: {
                        delete: {
                            message: '¿Estás seguro de eliminar los elementos seleccionados?',
                            title: 'Eliminar Grupo'
                        }
                    }
                },
                search: 'Grupo de búsqueda',
                basicInfo: 'Información básica',
                field: {
                    name: {
                        label: 'Nombre del grupo',
                        validation: { required: 'El nombre del grupo es obligatorio.' }
                    },
                    roles: { label: 'Roles', validation: {} },
                    parentId: {
                        label: 'Grupo de padres',
                        dropdownLabel: 'Seleccionar grupo de padres',
                        validation: {}
                    },
                    users: { label: 'Usuarios', validation: {} },
                    ntc_assets: { label: 'Activos', validation: {} },
                    children: { label: 'Subgrupos', validation: {} }
                },
                name: {
                    roles: 'Grupo de Roles',
                    users: 'Grupo de usuarios'
                }
            },
            user: {
                title: 'Usuario',
                modal: {
                    confirm: {
                        delete: {
                            message: '¿Quieres eliminar permanentemente a este usuario: {{user.name}}? Todos los datos se perderán.',
                            title: 'Eliminar Usuario'
                        },
                        lock: {
                            message: '¿Desea bloquear temporalmente la cuenta de este usuario: {{user.name}}?',
                            title: 'Bloquear cuenta'
                        },
                        unlock: {
                            message: '¿Quieres desbloquear la cuenta de este usuario: {{user.name}}?',
                            title: 'Desbloquear cuenta'
                        },
                        confirm_account: {
                            message: '¿Quieres reenviar el correo electrónico de confirmación de la cuenta: {{user.name}}?',
                            title: 'Reenviar confirmación de cuenta'
                        },
                        impersonate: {
                            message: '¿Quieres hacerte pasar por el usuario: {{user.name}}?',
                            title: 'Suplantar usuario'
                        }
                    }
                },
                preferences: {
                    weather: 'Clima Preferencias',
                    news: 'Noticias Preferencias',
                },
                notifications: {
                    lock: '¡Usuario bloqueado exitosamente!',
                    unlock: '¡Usuario desbloqueado exitosamente!',
                    delete: '¡Usuario eliminado exitosamente!',
                    edit: 'Usuario actualizado exitosamente!',
                    create: 'Se ha enviado un correo electrónico de confirmación a {{email}}. La cuenta no estará activa hasta que el usuario confirme su cuenta a través del enlace en ese correo electrónico.',
                    roleUpdate: '¡Los roles de usuario se actualizaron exitosamente!',
                    confirm_account: '¡Correo electrónico de confirmación de cuenta enviado!',
                    impersonate: '¡Usuario suplantado con éxito!',
                    impersonating: 'Hacerse pasar por'
                },
                field: {
                    firstName: { label: 'Nombre de pila', validation: { required: 'Se requiere el primer nombre' } },
                    lastName: { label: 'Apellido', validation: { required: 'Se requiere apellido' } },
                    name: { label: 'Nombre', validation: { required: 'Se requiere el nombre' } },
                    email: {
                        label: 'Dirección de correo electrónico',
                        validation: {
                            required: 'correo electronico es requerido',
                            email: 'el correo electrónico es invalido'
                        },
                        accountConfirmed: 'Su correo electrónico ha sido confirmado exitosamente. Gracias por verificar su cuenta. Ahora puede proceder a iniciar sesión en su cuenta y acceder a todas las funciones y servicios disponibles para usted.'
                    },
                    username: {
                        label: 'Nombre de usuario',
                        validation: {
                            required: 'Se requiere nombre de usuario',
                            min: 'El nombre de usuario debe tener al menos 6 caracteres',
                            max: 'El nombre de usuario no debe exceder los 20 caracteres'
                        }
                    },
                    phone: { label: 'Teléfono', validation: { required: 'Se requiere número de teléfono' } },
                    existingPassword: { label: 'La Antigua', required: 'Se requiere contraseña antigua' },
                    password: {
                        sentResetLink: 'Enlace de correo electrónico para restablecer contraseña enviado',
                        sentResetEmailInfo: 'Se ha enviado un correo electrónico que contiene un enlace para restablecer su contraseña. Revise la bandeja de entrada de su correo electrónico, incluida su carpeta de correo no deseado o basura, para continuar con el restablecimiento de su contraseña.',
                        resourceNotFound: 'Recurso no encontrado',
                        updateMessage: 'Contraseña de usuario actualizada',
                        updateMessageInfo: 'Su contraseña se ha restablecido correctamente. Ahora puede iniciar sesión con su nueva contraseña. Si tiene más preguntas o necesita ayuda, no dude en contactarnos. ¡Gracias!',
                        failedMessage: 'No se ha podido actualizar la contraseña. Por favor, inténtelo de nuevo.',
                        security: 'Seguridad',
                        stig: 'Habilitar los requisitos de contraseña de STIG',
                        passwordManagement: 'Gestión de contraseñas',
                        label: 'Contraseña',
                        labelNew: 'Nueva Contraseña',
                        accountLocked: 'Su cuenta ha sido bloqueada',
                        tooManyLoginAttempts: 'Has intentado 3 inicios de sesión en 15 minutos',
                        forcePasswordTitle: 'Tu contraseña ha expirado. Por favor cree uno nuevo para poder utilizar la aplicación.',
                        validation: {
                            required: 'se requiere contraseña',
                            invalid: 'usuario o contraseña invalido',
                            min: `La contraseña debe tener al menos ${NON_STIG_MINIMUM_PASSWORD_LENGTH} caracteres`,
                            stigMin: `La contraseña debe tener al menos ${STIG_MINIMUM_PASSWORD_LENGTH} caracteres`,
                            max: `La contraseña no debe exceder los ${MAXIMUM_PASSWORD_LENGTH} caracteres`,
                            upperCase: `Al menos una letra mayúscula [A-Z]`,
                            lowerCase: `Al menos una letra minúscula [a-z]`,
                            specialChar: `Al menos un símbolo [ ${SPECIAL_CHARS.split('').join(' ')} ]`,
                            number: `Al menos un número [ 0-9 ]`,
                            stigPatternMessage: 'La contraseña debe contener al menos 15 caracteres y al menos uno de los siguientes: ' +
                                `una letra minúscula, una letra mayúscula, un número y un carácter especial de ${SPECIAL_CHARS.split('').join(' ')} `,
                            nonStigPatternMessage: 'La contraseña debe contener al menos 8 caracteres y al menos uno de los siguientes: ' +
                                `una letra minúscula, una letra mayúscula, un número y un carácter especial de ${SPECIAL_CHARS.split('').join(' ')} `,
                            passwordReused: `La contraseña no puede coincidir con ninguna de las últimas ${PASSWORD_HISTORY_SIZE} contraseñas utilizadas`,
                            password8CharsMustChange: 'Se deben cambiar al menos 8 caracteres de la contraseña existente',
                            passwordTooNew: 'Debes esperar al menos 24 horas antes de cambiar tu contraseña',
                            passwordConfirmationMissing: 'Faltaba la confirmación de la contraseña',
                            passwordConfirmationNoMatch: 'La confirmación de contraseña no coincide con la contraseña',
                            existingPasswordNoMatch: 'La contraseña existente no coincide con la contraseña almacenada'
                        }
                    },
                    confirmPassword: {
                        label: 'confirmar Contraseña',
                        validation: {
                            required: 'Se requiere confirmar contraseña',
                            match: 'Confirmar contraseña no coincide'
                        }
                    },
                    roles: { label: 'Roles del usuario', validation: { required: 'Los roles son obligatorios' } },
                    groups: { label: 'Grupos de Usuarios', validation: { required: 'Grupos de Usuarios' } },
                    active: { label: 'Activo', validation: { required: '' } },
                    company: { label: 'Compañía', validation: { required: 'El nombre de la empresa es obligatorio.' } },
                    weather_country: { label: 'País', validation: { required: '' }, helpText: 'Ingrese el nombre del país (por ejemplo, Estados Unidos de América, México, Brasil, etc.).' },
                    weather_state: { label: 'Estado', validation: { required: '' }, helpText: 'Ingrese el nombre del estado para el país correspondiente (por ejemplo, Montana, Texas, etc.).' },
                    weather_city: { label: 'Ciudad', validation: { required: '' }, helpText: 'Ingrese el nombre del estado para la ciudad correspondiente (por ejemplo, Missoula, Kalispell, etc.).' },
                    news_country: { label: 'País', validation: { required: '' }, helpText: 'Ingrese el nombre del país (por ejemplo, Estados Unidos de América, México, Brasil, etc.).', dropdownLabel: 'Elige un país' },
                    news_state: { label: 'Estado', validation: { required: '' }, helpText: 'Ingrese el nombre del estado para el país correspondiente (por ejemplo, Montana, Texas, etc.).' },
                    news_city: { label: 'Ciudad', validation: { required: '' }, helpText: 'Ingrese el nombre del estado para la ciudad correspondiente (por ejemplo, Missoula, Kalispell, etc.).' },
                    news_keywords: { label: 'Palabra(s) clave(s) de noticias', validation: { required: '' }, helpText: 'Ingrese palabras clave separadas por comas para filtrar tipos específicos de noticias, por ejemplo, deportes, elecciones, tráfico, aventuras.' }
                }
            },
            organization: {
                title: 'Organización',
                name: 'Organización',
                search: 'Buscar',
                modal: {
                    confirm: {
                        delete: {
                            message: '¿Estás seguro de que quieres eliminar este artículo?',
                            title: 'Eliminar organización'
                        }
                    },
                    association: {
                        user: {
                            title: 'Agregar o eliminar usuarios de la organización',
                            label: 'Seleccionar/Anular selección de usuarios'
                        },
                        asset: {
                            title: 'Agregar o eliminar activos de la organización',
                            label: 'Seleccionar/deseleccionar activos'
                        }
                    }
                },
                field: {
                    name: {
                        label: 'Nombre de la Organización',
                        validation: { required: 'El nombre de la organización es obligatorio.' }
                    },
                    typeName: { label: 'Tipo de organización', validation: {} },
                    users: { label: 'Usuarios', validation: {} },
                    assets: { label: 'Activos', validation: {} }
                },
                buttons: {
                    linkUnlinkUser: 'Vincular/Desvincular usuario'
                }
            },
            asset: {
                title: 'Activo',
                search: 'Buscar activos',
                warning: 'NomadGCS advierte a los clientes que el Portal está en desarrollo y los usuarios aceptan todos los riesgos asociados con el control remoto de sus Centros de Operaciones Móviles Conectados',
                modal: {
                    confirm: {
                        delete: {
                            message: '¿Estás seguro de que quieres eliminar este artículo?',
                            title: 'Eliminar activo'
                        }
                    }
                },
                field: {
                    name: {
                        label: 'Nombre del activo',
                        validation: { required: 'El nombre del activo es obligatorio.' }
                    },
                    description: {
                        label: 'Descripción',
                        validation: {}
                    },
                    connected: {
                        label: 'Actualmente en linea',
                        validation: {}
                    },
                    last_online: {
                        label: 'Visto por última vez en línea en',
                        validation: {}
                    },
                    status: {
                        label: 'Status',
                        validation: {}
                    },
                    remote_control: {
                        label: 'Control remoto',
                        validation: {}
                    },
                    allow_remote_control: {
                        label: 'Permitir el Control Remoto',
                        validation: {}
                    },
                    key: { label: 'Clave API' },
                    secret: { label: 'Secreto' },
                    hardware_key: { label: 'Equipo ID' },
                    project_id: { label: 'Projecto ID' },
                    ssh_tunnel_port: { label: 'Puerto del túnel SSH' },
                    show_starlink: { label: 'Mostrar atributos de Starlink' },
                    serial_number: { label: 'Número de serie' },
                    asset_id: { label: 'ID de activo' },
                    business_unit: { label: 'Unidad de negocio' },
                    unit_number: { label: 'Unidad #' },
                    customer_number: { label: 'Cliente #' },
                    transfer_org: { label: 'Organización objetivo', dropdownLabel: 'Seleccionar organización', validation: { required: 'Se requiere organización objetivo' } },
                }
            },
            training: {
                title: 'Capacitación',
                modal: {
                    confirm: {
                        delete: {
                            message: '¿Estás seguro de que quieres eliminar este artículo?',
                            title: 'Eliminar entrenamiento'
                        }
                    }
                },
                field: {
                    name: {
                        label: 'Nombre del entrenamiento',
                        validation: { required: 'El nombre del entrenamiento es obligatorio.' }
                    },
                    description: {
                        label: 'Descripción',
                        validation: {}
                    },
                    repeat_count: {
                        label: 'Repetir recuento', validation:
                        {
                            required: 'Se requiere repetir el conteo',
                            min: 'El recuento de repeticiones debe ser un valor mayor que 0',
                            max: 'El recuento de repeticiones no debe exceder de 100'
                        }
                    },
                    source_id: {
                        label: 'Fuente de formación',
                        validation: { required: 'Se requiere fuente' },
                        dropdownLabel: 'Seleccionar fuente'
                    }
                }
            }
        },
        lang: {
            en: { us: 'Inglés (US)' },
            es: { mx: 'Español (Mexico)' }
        },
        menu: {
            settings: 'Ajustes',
            users: 'Usuarias',
            roles: 'Roles',
            ntc_assets: 'Activos',
            groups: 'Grupos',
            overview: 'Descripción general',
            organizations: 'Organizaciones',
            profileSettings: 'Configuración de perfil',
            languageUpdated: 'Idioma actualizado',
            themeUpdated: 'Tema actualizado',
            darkMode: 'Modo oscuro',
            language: 'Idioma',
            login: 'Acceso',
            logout: 'Cerrar sesión',
            lastLogin: 'Último acceso',
            firstLoginMessage: 'Primer inicio de sesión',
            trainings: 'Entrenamientos'
        },
        modules: {
            dashboard: {
                name: 'Panel',
                roles: 'Roles',
                active: 'Módulos Activos',
                modal: { confirm: { delete: { message: '¿Estás seguro de que quieres eliminar este artículo?' } } },
                button: { saveLayout: 'Guardar diseño de página' },
                empty: 'Agregue módulos a su panel y aparecerán aquí.'
            },
            map: {
                filter: { startDate: 'Fecha de inicio', endDate: 'Fecha final', search: 'Buscar', allAsset: 'Todos los activos' },
                layer: {
                    location: 'Ubicaciones en vivo',
                    route: 'Ruta Ruta'
                },
                type: {
                    assetTracker: 'Mapa de seguimiento de activos'
                },
                marker: {
                    connected: 'En línea',
                    notConnected: 'Desconectado',
                    stationary: 'Estacionario desde',
                    lastOnline: 'Último en línea',
                    latitude: 'Latitud',
                    longitude: 'Longitud',
                }
            },
            news: {
                name: 'Noticiero',
                showLess: 'Muestra menos',
                showMore: 'Mostrar más'
            },
            weather: {
                name: 'Clima',
                search: 'Ingrese el nombre de la ciudad o el código postal',
                current: {
                    header: 'CLIMA ACTUAL',
                    today: 'Hoy'
                },
                aircondition: {
                    header: 'CONDICIONES DEL AIRE',
                    realfeel: 'SENSACIÓN REAL',
                    wind: 'VIENTO',
                    clouds: 'NUBES',
                    humidity: 'HUMEDAD'
                }
            }
        },
        time: {
            day: 'día{{plural}}',
            hour: 'hora{{plural}}',
            minute: 'minuto{{plural}}',
            second: 'segundo{{plural}}',
            week: 'semana{{plural}}',
            ago: 'atrás',
        }
    }
}
