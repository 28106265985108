import React from 'react'
import { Typography, useTheme } from '@mui/material'

export default function WeatherHeader({ title }) {
    const theme = useTheme()
    return (
        <Typography variant='h5'
            lineHeight={'2'} component="div"
            sx={{
                flexGrow: 1,
                fontWeight: '600',
                color: theme.palette.custom.moduleTitle,
                textTransform: 'upperCase',
                fontSize: '1rem',
                textAlign: 'center'
            }}>
            {title}
        </Typography>
    )
}