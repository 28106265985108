import React from 'react'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import UserManagement from 'components/user/UserManagement'
import { ListIcon } from 'static/svgs/icons'
import { PageHeaderLayout } from 'components/layout/page/PageHeaderLayout'

function UserPage(props) {
    const [showNotification, setNotification] = React.useState(false)
    
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }
    return (
        <>
            <PageHeaderLayout title={`List`} headerIcon={<ListIcon />}>
                <UserManagement showResponseStatus={showResponseStatus} />
            </PageHeaderLayout>
            {showNotification ?
                <FlashNotification open={showNotification}
                    setOpen={setNotification} message={notificationData.message}
                    severity={notificationData.severity} /> :
                <></>}
        </>
    )
}

export default UserPage
