import React, { useContext } from 'react'
import { Grid, useTheme } from '@mui/material'
import NTCButton from '../inputs/buttons/NTCButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { isAuthorized, TRAINING, UPDATE, DELETE, CREATE } from 'generated/auth/Permissions'
import { useTranslation } from 'react-i18next'
import { ClientContext } from '../../Context'
import { RenderActionItems } from 'components/renderActionItems/RenderActionItems'
import { Training } from 'interface/Training'

interface TrainingListProps {
    trainings: Training[]
    actionButtonHandler: any,
    page?: string
}

const TrainingList: React.FC<TrainingListProps> = ({
    trainings,
    actionButtonHandler,
    page = 'training'
}) => {

    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const { t } = useTranslation()
    const isOrg = page === 'organization'
    return <>
        <Grid container sx={{ pt: 2 }}>
            {trainings.map(training => (
                <RenderActionItems
                    key={training.id}
                    item={training}
                    itemType={'training'}
                    permissions={{
                        edit: { resource: TRAINING, action: UPDATE },
                        delete: { resource: TRAINING, action: DELETE },
                        clone: { resource: TRAINING, action: CREATE }
                    }}
                    actionButtonHandler={actionButtonHandler}
                />
            ))}
        </Grid>
        <Grid container spacing={2} sx={{ pt: 2 }}>
            {isAuthorized(TRAINING, CREATE) ?
                <Grid item xs={12} sm={6} md={2}>
                    <NTCButton
                        endIcon={<AddOutlinedIcon />}
                        text={`${t('common.actions.create')} ${t('forms.training.title')}`}
                        sx={{ px: 4, textTransform: 'none' }}
                        backgroundColor={theme.palette.custom.nomadRed}
                        onClick={() => actionButtonHandler('create')}
                    />
                </Grid> : <></>
            }
        </Grid>
    </>

}

export default TrainingList