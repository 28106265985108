import React, { useEffect, useState, useRef, useContext } from 'react'
import Grid from '@mui/material/Grid'
import { TableCell, TableHead, TableRow, useTheme, Collapse } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { NTCTable, NTCTableRow } from 'components/display/tables/NTCTable'
import TableBody from '@mui/material/TableBody'
import { useParams } from "react-router-dom"
import { AssetApiService } from "../../utils/HTTP/AssetUtils"
import { Asset } from '../../generated/asset/Asset'
import { HardwareSystems } from '../../constants/HardwareSystems';
import { formatRelativeTime } from 'Utils'
import { PageContext } from 'Context'
import { ASSET, REMOTE_VIEW, REMOTE_CONTROL, isAuthorized } from 'generated/auth/Permissions'

function AssetStatus() {
    const [ntcAsset, setNtcAsset] = useState<Asset>(null)
    const { id } = useParams()
    const { t } = useTranslation()
    const theme = useTheme()
    const [openRows, setOpenRows] = useState({});
    const lastType = useRef(null);
    const { setPageEntity } = useContext(PageContext)

    const handleRowClick = (index) => {
        setOpenRows({ ...openRows, [index]: !openRows[index] });
    };

    useEffect(() => {
        loadAssetData()
    }, [id])

    var loadAssetData = () => {
        if (id != undefined && id != 'undefined') {
            AssetApiService.getAssetById(id).then(assetData => {
                setNtcAsset(assetData)
                setPageEntity(assetData)
            });
        }
    }

    return (
        ntcAsset?.ntc_systems?.length && (isAuthorized(ASSET, REMOTE_VIEW) || isAuthorized(ASSET, REMOTE_CONTROL)) ?
            <Grid container>
                <Grid item sx={{ border: 1, padding: 3, margin: 1, borderRadius: 4, borderColor: theme.palette.custom.nomadRed }}>
                    {t('modules.map.marker.lastOnline') + ': '} {formatRelativeTime(t, ntcAsset.last_online)}
                    {ntcAsset.last_online ? ' on ' + new Date(ntcAsset.last_online).toLocaleString() : null}
                </Grid >
                <Grid item xs={12} sm={12}>
                    <NTCTable>
                        {HardwareSystems.CATEGORIES.map((category) => (
                            <TableBody key={`system_${category}`}>
                                <TableRow sx={{ backgroundColor: theme.palette.custom.nomadRed, cursor: 'pointer' }} key={category} onClick={() => handleRowClick(category)}>
                                    <TableCell colSpan={4} sx={{ borderRadius: 2 }}>
                                        <Typography>
                                            {category}
                                            <IconButton>
                                                {openRows[category] ?
                                                    <KeyboardArrowUpIcon /> :
                                                    <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Collapse in={openRows[category]} timeout="auto" unmountOnExit>
                                            <NTCTable>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" sx={{ backgroundColor: theme.palette.custom.sliderGray }}>
                                                            <Typography>
                                                                {t('common.system')}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ backgroundColor: theme.palette.custom.sliderGray }}>
                                                            <Typography>
                                                                {t('common.name')}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ backgroundColor: theme.palette.custom.sliderGray }}>
                                                            <Typography>
                                                                {t('common.status')}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ backgroundColor: theme.palette.custom.sliderGray }}>
                                                            <Typography>
                                                                State
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ntcAsset?.ntc_systems?.map((system) => {
                                                        return (
                                                            HardwareSystems.CATEGORIES_BY_SYSTEM[system.system_type.toLowerCase()] == category &&
                                                            <React.Fragment key={Math.random()}>
                                                                {AssetTableHeader(system)}
                                                                {AssetTableRow(system)}
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </TableBody>
                                            </NTCTable>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                    </NTCTable >
                </Grid >
            </Grid > :
            <>
                {ntcAsset ? t('errors.noData') : null}
            </>
    );

    function AssetTableHeader(ntcSystem) {
        if (lastType.current != ntcSystem.display_type) {
            lastType.current = ntcSystem.display_type

            return (
                <NTCTableRow key={`header_${ntcSystem.remote_id}`}>
                    <TableCell align="left" colSpan={4}>
                        <Typography>
                            {ntcSystem.display_type}
                        </Typography>
                    </TableCell>
                </NTCTableRow>
            )
        } else return null
    }

    function AssetTableRow(ntcSystem) {
        return (
            <NTCTableRow key={ntcSystem.remote_id}>
                <TableCell align="left">
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {ntcSystem.display_name}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {ntcSystem.status}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {JSON.stringify(ntcSystem.state_info, null, 2)?.replace(/"|\{|\}/g, "")}
                    </Typography>
                </TableCell>
            </NTCTableRow>
        )
    }
}

function alignInterlocksWithSystems(ntcSystem) {
    ntcAsset?.ntc_systems?.find((otherSystem) => {
        ntcSystem.display_name
    })
}

export default AssetStatus;