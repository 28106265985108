import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Grid, useTheme } from '@mui/material'
import { FormMultiSelectControl, prepareSelectedList } from '../inputs/forms/controls/FormMultiSelectControl'
import NTCButton from '../inputs/buttons/NTCButton'
import BaseModalDialog from '../display/modals/BaseModalDialog'
import { User } from 'generated/client/User'
import { useTranslation } from 'react-i18next'
import RoleApiService from 'utils/RoleHttpUtils'
import UserApiService from 'utils/UserHttpUtils'
import OrganizationApiService from 'utils/HTTP/OrganizationUtils'
import { PageContext } from 'Context'

export interface UserRoleAssignmentProps {
    userId: string,
    organizationId?: string,
    handleCloseModal?: any,
    showResponseStatus?: any
}

export const UserRoleAssignment = ({ userId, organizationId, handleCloseModal, showResponseStatus }: UserRoleAssignmentProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [user, setUser] = useState<User>()

    const [roleOptions, setRoleOptions] = useState<[]>([])
    const [selectedRoleIds, setSelectedRoleIds] = useState([])
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const { setLoadingPanel } = useContext(PageContext)
    const validationSchema = Yup.object().shape({
        roles: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string()
                })
            ).required(t('forms.role.field.roles.validation.required'))
    })

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: { roles: [] },
        resolver: yupResolver(validationSchema)
    })

    const getRoleIdAssignedToUser = (user) => user?.roles?.map(role => role.id) || []

    useEffect(() => {
        const promise = organizationId ?
            OrganizationApiService.fetchAllOrgRoles(organizationId) :
            RoleApiService.fetchAllRoles()
        
        promise.then(res => {
            setRoleOptions(prepareItemList(res.filter((role: { name: string }) => role.name !== 'super_admin')))
        })
    }, [])

    useEffect(() => {
        if (!roleOptions.length) return

        UserApiService.fetchUser(userId).then(res => {
            setUser(res)
            const roleIds = getRoleIdAssignedToUser(res)
            setSelectedRoleIds(roleIds)

            const selectedRoles = prepareSelectedList(roleOptions, roleIds)
            setValue('roles', selectedRoles)
        })
    }, [userId, roleOptions])

    const onSubmit = async (data) => {
        setLoadingPanel(true)
        const newRoleIds = data.roles.map(role => role.value)
        try {
            await UserApiService.updateUser({ role_ids: newRoleIds, id: userId })
            showResponseStatus({ success: true, entity: 'Role', message: t(`forms.user.notifications.roleUpdate`) })
        } catch (ex) {
            showResponseStatus({ success: false, entity: 'Role', message: ex.message })
        } finally {
            setOpenFormDialog(false)
            handleCloseModal()
            setLoadingPanel(false)
        }
    }

    const prepareItemList = (options) => {
        return options.map(({ id, friendly_name }) => ({ value: id, label: `${friendly_name}` }))
    }

    return (
        <BaseModalDialog
            modalTitle={t('forms.role.title') + ' ' + t(`common.actions.assignment`)}
            maxWidth="lg"
            open={openFormDialog}
            handleClose={() => {
                setOpenFormDialog(false)
                handleCloseModal()
            }}>
            <Box px={3} py={2}>
                <Grid container spacing={3} sx={{ minWidth: 600 }}>
                    <Grid item xs={12} sm={12}>
                        <FormMultiSelectControl
                            name={'roles'}
                            label={t('forms.role.field.roles.label')}
                            options={roleOptions || []}
                            {...{ control, errors }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                    <Grid container spacing={3} justifyContent={'right'}>
                        <Grid item xs={12} sm={2.5}>
                            <NTCButton text={t('common.actions.cancel')} onClick={() => {
                                setOpenFormDialog(false)
                                handleCloseModal()
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={2.5}>
                            <NTCButton onClick={handleSubmit(onSubmit)}
                                text={t('common.actions.submit')}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </BaseModalDialog>
    )
}
