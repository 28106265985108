import React, { useState } from 'react'

import { Theme } from '@mui/material'
import { LineLayer } from 'react-map-gl'
import { FeatureCollection } from 'geojson'

import { MapLinesPaths } from '../LinePathLayer'
import { extractCoordinate } from '../utils'


// Function to convert an array of location objects to latitudes and longitudes
export function pointStringToLatLongList(data = []): number[][] {
    return data.map(loc => extractCoordinate(loc.coordinates))
}

// generateLineLayerBody method create a array of points based on locations data
export function generateLineLayerBody(assetLocations: any[], theme: Theme): { layer: LineLayer, geoJson: FeatureCollection }[] {
    return assetLocations.map(al => {
        return {
            layer: {
                id: al.id,
                type: 'line',
                paint: {
                    'line-color': 'blue',
                    'line-width': 2,
                }
            },
            geoJson: {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'properties': {
                            title: al.name
                        },
                        'geometry': {
                            'coordinates': pointStringToLatLongList(al.locations),
                            'type': 'LineString'
                        }
                    }
                ]
            }
        }
    })
}

const RoutePathLayer = ({ mapLinePaths }) => {
    const [selectedLinePath, setSelectedLinePath] = useState(null)

    return <MapLinesPaths {...{ mapLinePaths, selectedLinePath, setSelectedLinePath }} />
}

export default RoutePathLayer