import React from 'react'
import WeatherLayout from '../layout/WeatherLayout'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import AirIcon from '@mui/icons-material/Air'
import FilterDramaIcon from '@mui/icons-material/FilterDrama'
import HumidityIcon from 'static/svgs/humidity'
import { useTranslation } from 'react-i18next'

export default function TodayAirConditions({ data }) {
  const { t } = useTranslation() 
  function sanitize(value) {
    return value ? Math.round(value) : 0
  }
  return (
    <WeatherLayout title={t('modules.weather.aircondition.header')}>
      <AirConditionItem
        title={t('modules.weather.aircondition.realfeel')}
        value={`${sanitize(data?.current?.feelslike_c)} °C`}
        icon={<ThermostatIcon />} />

      <AirConditionItem
        title={t('modules.weather.aircondition.wind')}
        value={`${sanitize(data?.current?.wind_mph)} m/s`}
        icon={<AirIcon />} />

      <AirConditionItem
        title={t('modules.weather.aircondition.clouds')}
        value={`${sanitize(data?.current?.cloud)} %`}
        icon={<FilterDramaIcon />} />

      <AirConditionItem
        title={t('modules.weather.aircondition.humidity')}
        value={`${sanitize(data?.current?.humidity)} %`}
        icon={<HumidityIcon />} />

    </WeatherLayout>
  )
}

function AirConditionItem({ title, value, icon }) {
  const theme = useTheme()
  return (
    <Grid item xs={3}
      display={'grid'} rowGap={3} alignItems={'center'} justifyContent={'center'}
      sx={{ '& svg': { color: theme.palette.custom.menuIcon } }}>

      <Box display={'flex'} alignItems={'center'} flexDirection={'row'} columnGap={.5}>
        {icon}
        <Typography variant='h6' sx={{ flexGrow: 1, textTransform: 'upperCase', fontSize: '1rem' }}>
          {title}
        </Typography>
      </Box>
      <Typography textAlign={'center'}>
        {value}
      </Typography>
    </Grid>
  )
}