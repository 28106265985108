import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Divider, Grid, useTheme } from '@mui/material'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import NTCButton from '../inputs/buttons/NTCButton'
import BaseModalDialog from '../display/modals/BaseModalDialog'
import { User } from 'generated/client/User'
import { FormMode } from 'components/inputs/forms/Forms'
import { FormMultiSelectControl, prepareSelectedList } from 'components/inputs/forms/controls/FormMultiSelectControl'
import { useTranslation } from 'react-i18next'
import { ClientContext, PageContext } from '../../Context'
import { NON_STIG_PASSWORD_REGEX, STIG_PASSWORD_REGEX } from 'constants/Password'
import RoleApiService from 'utils/RoleHttpUtils'
import { handleException } from 'utils/HTTPUtils'
import UserApiService from 'utils/UserHttpUtils'
import OrganizationApiService from 'utils/HTTP/OrganizationUtils'

export interface UserFormProps {
    showResponseStatus?: any
    handleCloseModal?: any
    userId?: any,
    mode: FormMode,
    actionCallback?: any,
    organizationId?: string
    page?: string
}

export function passwordSchema(t) {
    return {
        password: Yup.string()
            .required(t('forms.user.field.password.validation.required'))
            .matches(NON_STIG_PASSWORD_REGEX, t('forms.user.field.password.validation.nonStigPatternMessage')),
        password_confirmation: Yup.string()
            .required(t('forms.user.field.confirmPassword.validation.required'))
            .oneOf([Yup.ref('password'), null], t('forms.user.field.confirmPassword.validation.match'))
    }
};

export function UserValidationSchema(t) {
    return {
        id: Yup.string(),
        first_name: Yup.string().required(t('forms.user.field.firstName.validation.required')),
        last_name: Yup.string().required(t('forms.user.field.lastName.validation.required')),
        email: Yup.string()
            .required(t('forms.user.field.email.validation.required'))
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, t('forms.user.field.email.validation.email')),
        phone: Yup.string(),
    };
}

const UserForm = ({
    userId,
    mode,
    showResponseStatus,
    handleCloseModal,
    actionCallback = (v) => { },
    organizationId = '',
    page = 'user'
}: UserFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const [roleOptions, setRoleOptions] = useState<[]>([])

    const isOrg = page === 'organization'

    const [openFormDialog, setOpenFormDialog] = useState(true)
    const validationSchema =
        Yup.object().shape({
            ...UserValidationSchema(t),
            ...{
                roles: Yup.array()
                    .of(
                        Yup.object().shape({
                            label: Yup.string(),
                            value: Yup.string()
                        })
                    ).required(t('forms.role.field.roles.validation.required'))
            }
        })


    const defaultValues = {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        roles: []
    }

    const [user, setUser] = useState(defaultValues)

    const {
        register,
        control,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: user,
        mode: 'all'
    })

    useEffect(() => {
        if (!userId) {
            trigger()
            return
        }

        UserApiService.fetchUser(userId).then((res) => {
            const roleIds = res?.roles?.map(role => role.id) || []
            const selectedRoles = prepareSelectedList(roleOptions, roleIds)

            setValue('id', res.id)
            setValue('first_name', res.first_name)
            setValue('last_name', res.last_name)
            setValue('email', res.email)
            setValue('phone', res.phone)
            setValue('roles', selectedRoles)
            setUser(res)
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'User', message: ex.message })
        })
    }, [userId, roleOptions])

    const prepareItemList = (options) => options.map(({ id, friendly_name }) => ({ value: id, label: friendly_name }))

    useEffect(() => {
        const promise = organizationId ?
            OrganizationApiService.fetchAllOrgRoles(organizationId) :
            RoleApiService.fetchAllRoles()

        promise.then(res => {
            setRoleOptions(prepareItemList(res.filter((role: { name: string }) => role.name !== 'super_admin')))
        })
    }, [])

    const onSubmit = async (data: any) => {
        setLoadingPanel(true)
        if (mode == FormMode.EDIT) {
            delete data.password
            delete data.password_confirmation
        } else {
            delete data.id
        }

        data.org_id = organizationId || client.org?.id

        const role_ids = data?.roles?.map(role => role.value) || []
        data.role_ids = role_ids
        delete data.roles

        const apiFunction = mode === 'create' ? UserApiService.createUser : UserApiService.updateUser
        let res = null

        try {
            res = await apiFunction(data)
            setUser(res)
            showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.${mode}`, { email: res.email }) })
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'User', message: errorMessage })
        } finally {
            if (isOrg) {
                actionCallback({ id: userId || res?.id })
                handleCloseModal()
            } else {
                setOpenFormDialog(false)
                handleCloseModal()
                if (userId === client.id) {
                    refreshClient(userId)
                }
            }
            setLoadingPanel(false)
        }
    }

    // Fetch the latest info from database including all basic info with role and permission
    const refreshClient = (userId) => {
        UserApiService.fetchUser(userId).then((user: User) => {
            setClient(user)
        })
    }

    const getFormHeader = () => {
        return t(`common.actions.${mode}`) + ' ' + t(`forms.user.title`)
    }

    return (
        <BaseModalDialog
            modalTitle={getFormHeader()}
            maxWidth="lg"
            open={openFormDialog}
            handleClose={() => {
                setOpenFormDialog(false)
                handleCloseModal()
            }}
        >
            <Box px={3} py={2}>
                <Grid container spacing={3}>
                    <input type="hidden" id="id" name="id" value={user.id} {...register('id')} />
                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            id={'first_name'}
                            name={'first_name'}
                            defaultValue={user.first_name}
                            label={t('forms.user.field.firstName.label')}
                            required={true}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            id={'last_name'}
                            name={'last_name'}
                            defaultValue={user.last_name}
                            label={t('forms.user.field.lastName.label')}
                            required={true}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            autoComplete='new-email'
                            id={'email'}
                            name={'email'}
                            defaultValue={user.email}
                            label={t('forms.user.field.email.label')}
                            required={true}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            id={'phone'}
                            name={'phone'}
                            defaultValue={user.phone}
                            label={t('forms.user.field.phone.label')}
                            required={false}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormMultiSelectControl
                            name={'roles'}
                            label={t('forms.role.field.roles.label')}
                            options={roleOptions || []}
                            {...{ control, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}></Grid>

                    <Grid container spacing={3} justifyContent="right" sx={{ pt: 3 }}>
                        <Divider />
                        <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <NTCButton
                                text={t('common.actions.cancel')}
                                onClick={() => {
                                    setOpenFormDialog(false)
                                    handleCloseModal()
                                }} />
                            <NTCButton
                                text={t('common.actions.save')}
                                onClick={handleSubmit(onSubmit)}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </BaseModalDialog>)
}

export default UserForm