// noinspection ES6PreferShortImport

/*
 * This file has been generated by the TypeScript generator.
 *
 * Any changes will be overwritten during the next generation phase.
 */

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}