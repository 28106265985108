import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import AppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material'
import { useState, useEffect } from 'react'
import { getEnvironment } from 'utils/HTTPUtils'
import '../../../App.css'

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export interface AppHeaderProps {
    marginLeft: number
    openSidebar?: boolean,
    logoIcon?,
    onMenuIconClick?: React.MouseEventHandler,
    children: any;
}

const AppBarExt = styled(MuiAppBar, {
    shouldForwardProp: (prop) => !['open', 'marginLeft'].includes(prop.toString())
})<AppBarProps & { open?: boolean, marginLeft: number }>(({ theme, open, marginLeft }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${marginLeft}px)`,
        marginLeft: `${marginLeft}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

export const AppHeaderMobile = (props: AppHeaderProps) => {
    const theme = useTheme()
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="sticky"
                sx={{
                    backgroundColor: theme.palette.custom.menuBack,
                    height: '83px',
                    borderRadius: '0px 0px 12px 12px'
                }}>
                <Toolbar sx={{ width: '100%', justifyContent: 'space-between', height: '83px' }}>
                    <Box display={'flex'}>
                        <IconButton aria-label="open drawer" onClick={props.onMenuIconClick} edge="start"
                            sx={{ mr: 0 }}>
                            <MenuIcon sx={{ fill: theme.palette.custom.breadcrumb }} />
                        </IconButton>
                        <Box className="main-logo-sm" sx={{ '& svg': { margin: '5px 0px 0px 0px' } }}>
                            {props.logoIcon}
                        </Box>
                    </Box>
                    {props.children}
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export const AppHeader = (props: AppHeaderProps) => {
    let theme = useTheme()
    const [env, setEnv] = useState('')
    useEffect(() => {
        loadEnv()
    }, [])

    var loadEnv = () => {
        getEnvironment().then(env => {
            setEnv(env)
        });
    }

    // @ts-ignore
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBarExt position="sticky"
                open={props.openSidebar}
                marginLeft={props.marginLeft}
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: 'none',
                    backgroundImage: 'none'
                }}>
                <Toolbar>
                    <span className={`dev-env-${env.name}`}>Environment: {env.name}</span>
                    <Box>
                        <IconButton aria-label="open drawer" onClick={props.onMenuIconClick} edge="start"
                            sx={{
                                mr: 0, ...(props.openSidebar && { display: 'none' }),
                                color: theme.palette.custom.breadcrumb
                            }}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    {props.children}
                </Toolbar>
            </AppBarExt>
        </Box >
    )
}
