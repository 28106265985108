import React from 'react'
import { Card, CardContent, CardHeader, useTheme } from '@mui/material'

export function PageHeaderLayout({ children, headerIcon, title }) {
    const theme = useTheme()
    return (
        <Card sx={{ borderShadow: 'none' }}>
            <CardHeader avatar={headerIcon} title={title} sx={{
                borderBottom: theme.palette.custom.moduleBorder,
                '& svg': {
                    maxWidth: '20px',
                    fill: `${theme.palette.custom.menuIcon}`,
                    color: `${theme.palette.custom.menuIcon}`,
                    'path': {
                        fill: 'primary.breadCrumbColor'
                    }
                }
            }}/>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}