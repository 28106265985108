.list-item-icon {
    margin-left: 12rem;
    margin-right: 0;
}

.drawer-root {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #141617;
    border-radius: 0px 11.25px 11.25px 0px;
    font-family: 'Montserrat';
}

.drawer-root .Mui-selected path {
    stroke: #fff;
    fill: #fff !important;
}

.drawer-root .Mui-selected .dashboard-icon path {
    fill: #fff;
}

.drawer-root .nav-menu-items a.Mui-selected {
    background: #CE1B22;
    border-radius: 5.625px;
}

.nav-menu-items .MuiTypography-root {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Montserrat';
}
