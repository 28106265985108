.form-container * {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.form-container .background {
    width: 100vw;
    height: 100vh;
    background: url('static/images/LoginBackgroundAssets.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-form {
    /* height: 40rem !important; */
    width: 40rem !important;
    top: 43% !important;
}

.form-container form {
    /* height: 25rem; */
    width: 25rem;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 30%;
    left: 50%;
    border-radius: 0.625rem;
    backdrop-filter: blur(0.625rem);
    border: 0.125rem solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 2.5rem rgba(8, 7, 16, 0.6);
    padding: 3.125rem 2.188rem;
}

.form-container form * {
    outline: none;
    border: none;
}

.form-container form h1 {
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 2.625rem;
    text-align: center;
}

.form-container div.image::before {
    content: url("static/images/nomad_logo_black.png");
}

@media all and (max-width: 750px) {
    .form-container form {
        margin-top: 5rem;
        /* height: 25rem; */
        width: 17rem;
    }

    .reset-form {
        margin-top: 10% !important;
        width: 22rem !important;
        /* height: 40rem !important; */
    }
}