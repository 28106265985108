import { setUserLoggedOutTrigger } from './components/layout/page/LogoutConfirmation'
import { noRedirectApi } from './api/ApiConfig'
import Papa from 'papaparse'
import { AuthRoutes } from './utils/ServerRoutes'

export const RETURN_KEY = 'returnUrl'
export const USER_JSON_KEY = 'user'
export const SESSION_KEY = 'user'
export const AUTH_TOKEN_KEY = 'token'
export const AUTH_TOKEN_EXPIRES_IN = 'expires_in'
export const REFRESH_TOKEN_KEY = 'refresh_token'
export const AUTH_TOKEN_EXPIRES_AT = 'created_at'
export const IMPERSONATING_KEY = 'impersonating'

/**
 * Logout the current client by clearing their session
 * and calling the exit route.
 */
export async function logout(redirect?: string, serverExit: boolean = true) {
    if (serverExit) {
        return noRedirectApi.delete(AuthRoutes.exit)
            .then(() => endSession(redirect))
    } else {
        endSession(redirect)
    }
}

export function redirectToReturnUrl() {
    const searchParams = new URLSearchParams(document.location.search)
    const returnUrl = searchParams.get(RETURN_KEY)
    window.location.replace(returnUrl ? window.location.origin + returnUrl : origin)
}

function endSession(redirect?: string) {
    sessionStorage.clear()
    // STIG: Set flag to show explicit logout notification for some time
    setUserLoggedOutTrigger()
    sessionStorage.clear()
    window.location.replace(redirect ? `${origin}?${RETURN_KEY}=${redirect}` : origin)
}

export function getTimeStamp() {
    return new Date().toISOString().replace(/[-:]/g, '_').split('.')[0]
}

/**
 * function to download the csv file in local system
 * @param data
 * @param filename
 */
export function downloadFile(data: any, filename: string) {
    const csv = Papa.unparse(data)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

export function saveJwt(tokenJson) {
    sessionStorage.setItem(AUTH_TOKEN_KEY, tokenJson[AUTH_TOKEN_KEY])
    sessionStorage.setItem(REFRESH_TOKEN_KEY, tokenJson[REFRESH_TOKEN_KEY])
    sessionStorage.setItem(AUTH_TOKEN_EXPIRES_IN, tokenJson[AUTH_TOKEN_EXPIRES_IN])
    sessionStorage.setItem(AUTH_TOKEN_EXPIRES_AT, dateTimeOffset(new Date(), tokenJson[AUTH_TOKEN_EXPIRES_IN]))
}

export function dateTimeOffset(startDate, offsetSeconds) {
    return startDate.setSeconds(startDate.getSeconds() + offsetSeconds);
}

// Function to format a date relative to the current time based on time difference
export function formatRelativeTime(t, date) {
    if (date == null) { return t('common.never') }

    const now = new Date();
    const targetDate = new Date(date);
    const difference = Math.floor((now - targetDate) / 1000);

    const formatTimeUnit = (value: number, unit: string) =>
        `${value} ${t(unit, { plural: value === 1 ? '' : 's' })} ${t('time.ago')}`;

    if (difference < 60) return formatTimeUnit(difference, 'time.second');
    if (difference < 60 * 60) return formatTimeUnit(Math.floor(difference / 60), 'time.minute');
    if (difference < 60 * 60 * 24) return formatTimeUnit(Math.floor(difference / (60 * 60)), 'time.hour');
    if (difference < 60 * 60 * 24 * 7) return formatTimeUnit(Math.floor(difference / (60 * 60 * 24)), 'time.day');
    return formatTimeUnit(Math.floor(difference / (60 * 60 * 24 * 7)), 'time.week');
}