import React, { useContext } from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ClientContext, ReauthenticationContext } from '../../Context'
import { isAuthorized } from 'generated/auth/Permissions'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CheckIcon from '@mui/icons-material/Check'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'

export const RenderActionItems = ({ item, itemType, permissions, actionButtonHandler }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { client } = useContext(ClientContext)
    const actions = [], idKey = 'id', name = itemType === 'user' ? `${item.first_name} ${item.last_name}` : item.friendly_name || item.name
    const isClientSameAsItem = client.id === item.id
    const isClientOrgSameAsItem = client?.org?.id === item.id
    const checkAdmin = (user) => {
        return user.roles.find(role => role.name === 'super_admin')
    }
    const isClientAdmin = checkAdmin(client)
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)

    // populate action list with all incoming keys
    Object.keys(permissions).map(key => {
        if (isAuthorized(permissions[key].resource, permissions[key].action)) actions.push(key)
    })

    return (
        <Grid container item xs={12} key={item['id']} rowSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{
                borderBottom: theme.palette.custom.moduleBorder, padding: 1,
                ...(isMobile && { display: 'grid' })
            }}>
            <Grid item xs={8}>
                <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
                {actions.map((action) => (
                    <Tooltip key={`${item[idKey]}-${action}`}
                        title={
                            (action === 'lock' && item?.locked_at) ? t(`common.actions.un${action}`) :
                                (action === 'confirm_account' && item?.confirmed_at) ? t(`common.actions.user_confirmed`) :
                                    t(`common.actions.${action}`)}>
                        <IconButton
                            aria-label={action}
                            onClick={() => {
                                if ((action === 'confirm_account' && item?.confirmed_at)) {
                                    return
                                } else {
                                    actionButtonHandler(action === 'lock' && item?.locked_at ? `un${action}` : action, item)
                                }
                            }
                            }>
                            {action === 'edit' && <EditIcon />}
                            {action === 'lock' && !isClientSameAsItem && (item?.locked_at ?
                                <LockOpenOutlinedIcon /> : <LockOutlinedIcon />)}
                            {action === 'delete' && !isClientSameAsItem && !isClientOrgSameAsItem && <DeleteIcon />}
                            {action === 'clone' && <ContentCopyIcon />}
                            {action === 'impersonate' && !isClientSameAsItem && isClientAdmin &&
                                <FontAwesomeIcon icon={faPeopleArrows} />}
                            {action === 'pair' && <SyncAltIcon />}
                            {action === 'assign' && !isClientSameAsItem && <AdminPanelSettingsIcon />}
                            {action === 'confirm_account' && !isClientSameAsItem && (item?.confirmed_at ?
                                <CheckIcon /> : <MarkEmailUnreadIcon />)}
                            {/*TODO, add more checks for other possible buttons*/}
                        </IconButton>
                    </Tooltip>
                ))}
            </Grid>
        </Grid>
    )
}