import { TranslationKey } from 'generated/util/TranslationKey'
import { UiModuleConfig } from './UiModuleConfig'

export enum UiModuleType {
    ASSETTRACKER = 'assetTracker',
    NEWS = 'news',
    WEATHER = 'weather'
}

export interface UiModuleProps {
    id: string;
    name: TranslationKey
    type: UiModuleType
    config: UiModuleConfig
}