import * as React from 'react'

import Link from '@mui/material/Link'
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteIcon from '@mui/icons-material/Delete'

import { FormMode } from 'components/inputs/forms/Forms'
import { CREATE, DELETE, GROUP, READ, UPDATE, isAuthorized } from 'generated/auth/Permissions'


/**
 * Renders a link component that opens a dialog to preview a list of associated items,
 * such as assets, users, or groups, based on the provided count.
 */
function ItemPreviewLink({ params, handleClick, children }) {
    const theme = useTheme()

    return (
        <Link sx={{ color: theme.palette.custom.defaultText, cursor: 'pointer', padding: 2 }} underline='always'
            onClick={() => handleClick(FormMode.VIEW, params)}>
            {children}
        </Link>
    )
}

/**
 * Generates action buttons list based on authorization and predefined actions.
 */

function gridActionsCellItems(t, params, handleClick) {
    const actions = [
        {
            type: UPDATE,
            label: t('forms.button.edit'),
            mode: FormMode.EDIT,
            icon: <EditIcon />
        },
        {
            type: CREATE,
            label: t('forms.button.copy'),
            mode: FormMode.CLONE,
            icon: <ContentCopyOutlinedIcon />
        },
        {
            type: DELETE,
            label: t('forms.button.delete'),
            mode: FormMode.DELETE,
            icon: <DeleteIcon />
        }
    ]

    const actionButtons = actions
        .filter(({ type }) => isAuthorized(GROUP, type))
        .map(({ type, icon, label, mode }) => (
            <GridActionsCellItem key={type} icon={icon} label={label} onClick={() => handleClick(mode, params)} />
        ))

    return actionButtons
}

/**
 * Generates and returns an array of column definitions for displaying groups in a data grid.
 * Each column defines its field, header name, width, alignment, and optional action buttons.
 * Action buttons are dynamically generated based on user permissions and include edit, copy, delete, and preview actions.
 */
export function getGroupColumns({ t, actionButtonHandler }): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            renderCell: (params) => {
                return <ItemPreviewLink params={params} handleClick={actionButtonHandler}>{params.value}</ItemPreviewLink>
            }
        },
        {
            field: 'children',
            headerName: 'Groups',
            width: 100,
            align: 'center',
            renderCell: (params) => {
                return <ItemPreviewLink params={params} handleClick={actionButtonHandler}>{params.value?.length}</ItemPreviewLink>
            }
        },
        {
            field: 'ntc_assets',
            headerName: 'Assets',
            width: 100,
            align: 'center',
            renderCell: (params) => {
                return <ItemPreviewLink params={params} handleClick={actionButtonHandler}>{params.value?.length}</ItemPreviewLink>
            }
        },
        {
            field: 'users',
            headerName: 'Users',
            width: 100,
            align: 'center',
            renderCell: (params) => {
                return <ItemPreviewLink params={params} handleClick={actionButtonHandler}>{params.value?.length}</ItemPreviewLink>
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 200,
            getActions: (params) => {
                return gridActionsCellItems(t, params, actionButtonHandler)
            }
        }
    ]
}
