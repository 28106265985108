// Function to extract coordinates from a location object
export function extractCoordinate(coordinate: string): number[] {
    const coordinateString = coordinate.match(/\(([^)]+)\)/)[1]
    const [longitude, latitude] = coordinateString.split(' ')
    return [parseFloat(longitude), parseFloat(latitude)]
}

// Returns the date part (YYYY-MM-DD) of a Date object returns input unchanged if not a Date.
export function getDatePart(date) {
    if (!(date instanceof Date)) return date

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}
