import { useEffect, useState } from 'react'
import { HTTPMethod } from '../HttpType'
import { requestEndpoint } from '../HTTPUtils'

interface RequestOptions<TRequest> extends RequestInit {
    token?: string;
    data?: TRequest,
    controller?: AbortController
}

/**
 * Method to handle error and returns loading status, result or error message
 * @param url
 * @param options
 */
function useFetch<TRequest, TResponse>(url: string, options: RequestOptions<TRequest> = {}) {
    const [result, setResult] = useState<TResponse | null>(null)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState('')
    let controller = options.controller || new AbortController()

    const fetchData = async () => {
        try {
            const response = await requestEndpoint<TRequest, TResponse>(
                url, null, HTTPMethod.GET, controller
            )
            setResult(response)
            setError('')
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()

        return () => {
            // Cleanup function to abort the ongoing fetch when the component unmounts
            controller.abort()
        }
    }, [url, options.method, options.token, options.data])

    const refetch = () => {
        fetchData()
    }

    return { result, isLoading, error, refetch }
}

export default useFetch
