import React from 'react'
import { Container, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { faBell, faDashboard, faGears, faMap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

const Circle = styled(Paper)(({ theme }) => ({
    position: 'relative',
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.7'
}))

const Icon = styled('div')(({ theme }) => ({
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
}))

const CenterIcon = styled(Icon)({
    top: '80px',
    left: '80px'
})

const NorthIcon = styled(Icon)({
    top: '10px',
    left: '80px'
})

const WestIcon = styled(Icon)({
    top: '80px',
    left: '10px'
})

const SouthIcon = styled(Icon)({
    top: '150px',
    left: '80px'
})

const EastIcon = styled(Icon)({
    top: '80px',
    left: '150px'
})

const IconDesign = (props) => {
    const navigate = useNavigate()

    function handleIconClick(feature) {
        alert("UNIMPLEMENTED - see PopupNavigationsIcons.tsx")
    }

    return (
        <Container>
            <Circle elevation={3}>
                <CenterIcon>
                    <Typography fontWeight={'bolder'}>{props.feature?.properties?.title}</Typography>
                </CenterIcon>
                <NorthIcon>
                    <FontAwesomeIcon icon={faBell} size={'2x'} onClick={() => handleIconClick(props.feature)}
                        cursor={'pointer'} />
                </NorthIcon>
                <WestIcon>
                    <FontAwesomeIcon icon={faGears} size={'2x'} onClick={() => handleIconClick(props.feature)}
                        cursor={'pointer'} />
                </WestIcon>
                <SouthIcon>
                    <FontAwesomeIcon icon={faMap} size={'2x'} onClick={() => handleIconClick(props.feature)}
                        cursor={'pointer'} />
                </SouthIcon>
                <EastIcon>
                    <FontAwesomeIcon icon={faDashboard} size={'2x'} onClick={() => handleIconClick(props.feature)}
                        cursor={'pointer'} />
                </EastIcon>
            </Circle>
        </Container>
    )
}

export default IconDesign

