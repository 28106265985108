import * as React from 'react'
import { styled } from '@mui/material/styles'
import LogoutConfirmation from './LogoutConfirmation'
import { LoadingPanel } from '../loading-panel/LoadingPanel';
import { PageContextProvider } from 'Context';

const Main = styled('main', {
    shouldForwardProp: (prop) => !['open', 'marginLeft'].includes(prop.toString())
})<{
    open?: boolean;
    marginLeft?: number,
}>(({ theme, open, marginLeft }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: marginLeft
    })
})
)
const PageContainer = ({ open, marginLeft, children }) => {
    return (
        <Main open={open} marginLeft={marginLeft}>
            <LogoutConfirmation />
            <div> {children}</div>
            <LoadingPanel />
        </Main>
    )
}

export default PageContainer
