import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'

import ModuleWrapper from '../ModuleWrapper'
import { UiModuleProps } from '../UiModuleProps'
import { getDatePart } from './utils'
import { AssetMapControl } from './MapControl'


const StatusElementItem = ({ text }) => {
    const theme = useTheme()
    const color = theme.palette.custom.moduleStatus

    return <Typography variant='caption' sx={{ color }}>{text}</Typography>
}

const StatusElement = ({ dateRange }) => {
    const { t } = useTranslation()

    return (
        <Box display='flex' flexDirection='row' gap={3}>
            <StatusElementItem text={`${t('modules.map.filter.startDate')}: ${dateRange?.startDate}`} />
            <StatusElementItem text={`${t('modules.map.filter.endDate')}: ${dateRange?.endDate}`} />
            <StatusElementItem text={t('modules.map.filter.allAsset')} />
        </Box>
    )
}

const AssetTrackerModule = ({ id, name, type, config }: UiModuleProps) => {
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [dateRange, setDateRange] = useState({
        startDate: getDatePart(thirtyDaysAgo),
        endDate: getDatePart(new Date())
    })

    return (
        <ModuleWrapper
            id={id}
            type={type}
            name={name}
            statusElement={<StatusElement dateRange={dateRange} />}
        >
            <AssetMapControl {...{ id, name, type, config, dateRange, setDateRange }} />
        </ModuleWrapper>
    )
}

export default AssetTrackerModule