import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { IMPERSONATING_KEY, SESSION_KEY } from './Utils'
import { Client } from 'generated/client/Client'

interface ClientContextProps {
    client: Client;
    setClient: Dispatch<SetStateAction<Client>>;
}

interface ClientContextProviderProps {
    value: Client
    children: ReactNode
}

export const ClientContext = createContext<ClientContextProps>({
    client: null,
    setClient: () => { }
})

export const ClientContextProvider = ({ value, children }: ClientContextProviderProps) => {
    const [client, setClient] = useState<Client>(value)
    return (
        <ClientContext.Provider value={{ client, setClient }}>
            {children}
        </ClientContext.Provider>
    )
}

interface PageContextProps {
    loading: boolean;
    setLoadingPanel: Dispatch<SetStateAction<boolean>>;
    pageEntity?: any;
    setPageEntity?: Dispatch<SetStateAction<any>>;
}

interface PageContextProviderProps {
    children: ReactNode
}

export const PageContext = createContext<PageContextProps>({
    loading: false,
    setLoadingPanel: () => { },
    pageEntity: {},
    setPageEntity: () => { },
})

export const PageContextProvider = ({ children }: PageContextProviderProps) => {
    const [loading, setLoadingPanel] = useState<boolean>(false)
    const [pageEntity, setPageEntity] = useState({})
    return (
        <PageContext.Provider value={{ loading, setLoadingPanel, pageEntity, setPageEntity }}>
            {children}
        </PageContext.Provider>
    )
}

export const setClientSession = (id: string) => {
    sessionStorage.setItem(SESSION_KEY, id)
}

export const setImpersonating = (val = true) => {
    sessionStorage.setItem(IMPERSONATING_KEY, val.toString())
}

export const ReauthenticationContext = createContext({ open: false, setOpen: null })
