import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { resizeAllLayouts } from '../display/grid/NTCGridLayout'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { calculateGridHeight } from '../../utils/MathUtils'
import { fetchPageLayouts, savePageLayouts } from 'utils/HTTPUtils'
import { ClientContext } from '../../Context'
import { SYSTEM_STRUCTURES } from './ModuleGridLayout'

function createDefaultLayout(modules) {
    return modules.map((module) => {
        const layout = { ...SYSTEM_STRUCTURES[module.type].layout }
        layout.i = module.id
        return layout
    })
}

function calculateHeights() {
    const items = document.querySelectorAll('.module_body_container')
    const keyValue = {}
    items.forEach((item) => {
        // @ts-ignore
        keyValue[item.dataset?.id] = calculateGridHeight(item.clientHeight) + 1
    })
    return keyValue
}

function adjustDynamicLayouts(layouts, setLayouts) {
    const keyValue = calculateHeights()
    resizeAllLayouts(layouts, keyValue, setLayouts)
}

export function useModuleAdjuster(modules, pageId, enableAutoSave): [layouts: Array<any>, saveLayouts: any, setStartAutoSave: any, saveLayoutRequest: any] {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const { client } = useContext(ClientContext)

    const [isDefaultLayout, setIsDefaultLayout] = useState(false)
    const [layouts, setLayouts] = useState([])
    const [persistedLayout, setPersistedLayouts] = useState([])
    const [startAutoSave, setStartAutoSave] = useState(false)

    function saveLayoutRequest(_layouts = layouts) {
        savePageLayouts({ pageId, layouts: _layouts.map(stringifyObjectValues), userId: client.id })
            .catch((saveError) => console.log('Layout Save Error', saveError.message))
    }

    const stringifyObjectValues = obj =>
        Object.fromEntries(
            Object.entries(obj)
                .filter(([_, value]) => value != null)
                .map(([key, value]) => [key, String(value)])
        )

    const numerifyObjectValues = obj =>
        Object.fromEntries(
            Object.entries(obj)
                .filter(([_, value]) => value != null)
                .map(([key, value]) =>
                    [key, isNaN(Number(value)) ? value === 'false' ? false : value === 'true' ? true : value : Number(value)])
        )

    function saveLayouts(layouts) {
        if (!layouts.length) return

        setLayouts(layouts)
        if (enableAutoSave && startAutoSave) {
            console.debug('Auto saving layouts in database...')
            //saveLayoutRequest(layouts)
        }
    }

    useEffect(() => {
        /**
         * TODO,
         * Enable this code when backend is ready to store the layouts in layouts collections
         */
       
        /**
        fetchPageLayouts(pageId)
            .then((res) => {
                const responseLayout = res?.layouts?.map(numerifyObjectValues) || []
                setPersistedLayouts(responseLayout)
            })
            .catch((fetchError) => {
                setPersistedLayouts([])
                setIsDefaultLayout(true)
            })
        */
    }, [pageId])

    function getAddedModuleLayout(modules, persistedLayout) {
        const addedModulesCount = modules.length - persistedLayout.length
        let pLayout = [...persistedLayout]

        if (addedModulesCount > 0) {
            const addedModules = modules.filter(module => !pLayout.map(l => l.i).includes(module.id))

            addedModules.forEach(m => {
                const layoutToAdd = { ...SYSTEM_STRUCTURES[m.type].layout, i: m.id, y: Infinity }
                pLayout.push(layoutToAdd)
            })
        }
        return pLayout
    }

    useLayoutEffect(() => {
        const maxAttempts = 10
        const retryDelay = 100
        const savedLayoutDelay = 150

        let timeoutId
        let attempts = 0

        const defaultLayout = createDefaultLayout(modules)

        const tryAdjustment = () => {
            if (persistedLayout.length) {
                // Stop auto adjustment early if saved layout found
                timeoutId && clearTimeout(timeoutId)

                const savedLayout = getAddedModuleLayout(modules, persistedLayout)
                setTimeout(() => setLayouts(savedLayout), savedLayoutDelay)
            } else if (attempts < maxAttempts) {
                // Continue attempting adjustment
                adjustDynamicLayouts(defaultLayout, setLayouts)
                attempts += 1
                timeoutId = setTimeout(tryAdjustment, retryDelay)
            }
        }

        tryAdjustment()

        return () => {
            clearTimeout(timeoutId)
        }
    }, [modules, persistedLayout])

    return [layouts, saveLayouts, setStartAutoSave, saveLayoutRequest]
}