import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { ClientContext, PageContext } from '../../Context'
import ConfirmModalDialog from '../display/modals/ConfirmModalDialog'
import { FormMode } from '../inputs/forms/Forms'
import { Grid, useTheme } from '@mui/material'
import { CREATE, ROLE, isAuthorized } from '../../generated/auth/Permissions'
import NTCButton from '../inputs/buttons/NTCButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import TrainingApiService from 'utils/HTTP/TrainingUtils'
import TrainingList from './TrainingList'
import TrainingForm from './TrainingForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export interface TrainingFormDialogProps {
    showResponseStatus?: any
    actionHandler?: any
    handleCloseModal?: any
}

const TrainingManagement = ({ showResponseStatus }: TrainingFormDialogProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [action, setAction] = useState<FormMode | null>(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { client } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const trainingResponses = TrainingApiService.fetchTrainings()

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            setSelectedId(id)
            if (pathname.includes('edit')) {
                setAction(FormMode.EDIT)
            }
            else if (pathname.includes('clone')) {
                setAction(FormMode.CLONE)
            }
        } else if (pathname.includes('create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/trainings')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])

    const actionButtonHandler = (command, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (command === 'delete') {
            setOpenConfirmDialog(true)
        } else if (command === 'edit') {
            navigate(`${item.id}/edit`)
        } else if (command === 'create') {
            navigate(`create`)
        } else if (command === 'clone') {
            navigate(`${item.id}/clone`)
        }
    }

    const handleCloseModal = () => {
        navigate('/trainings')
        trainingResponses.refetch()
        setAction(null)
    }

    const onActionClick = (action: string) => {
        setLoadingPanel(true)
        action === FormMode.DELETE &&
            TrainingApiService.deleteTraining(selectedId)
                .then(() => {
                    showResponseStatus({
                        success: true,
                        message: t(`common.notifications.${action}`, { name: t('forms.training.title')}) 
                    });
                    trainingResponses.refetch();
                })
                .catch(error => {
                    let errorMessage = '';
                    if (error.code === 400) {
                        errorMessage = t('forms.training.trainingDeletion');
                    }
                    showResponseStatus({
                        success: false,
                        message: errorMessage
                    });
                })
                .finally(() => {
                    setOpenConfirmDialog(false)
                    handleCloseModal()
                    setLoadingPanel(false)
                });

    }

    return (
        <>
            <TrainingList trainings={trainingResponses.result || []}
                actionButtonHandler={actionButtonHandler} />

            {(() => {
                if (action === FormMode.DELETE) {
                    return (
                        <ConfirmModalDialog
                            open={openConfirmDialog} handleClose={handleCloseModal}
                            modalTitle={t(`common.actions.delete`) + ' ' + t('forms.training.title')}
                            handleConfirm={() => { onActionClick(action) }}
                            handleCancel={() => setAction(null)}
                            confirmMessage={t('forms.training.modal.confirm.delete.message')} />
                    )
                }

                if ([FormMode.CREATE, FormMode.EDIT, FormMode.CLONE].includes(action)) {
                    return (
                        <TrainingForm
                            trainingId={selectedId}
                            mode={action}
                            showResponseStatus={showResponseStatus}
                            actionCallback={() => { }}
                            handleCloseModal={handleCloseModal}
                        />
                    )
                }
                return null
            })()}
        </>
    )
}

export default TrainingManagement
