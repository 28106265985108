import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'

import GroupToolbar from './GroupToolbar'
import { getGroupColumns } from './GroupListColumns'
import GroupTreeView from './GroupTreeView'

export default function GroupList({ rows, actionButtonHandler }) {
    const { t } = useTranslation()
    const [filteredRows, setFilteredRows] = useState(rows)
    const [keyword, setKeyword] = useState('')
    const [viewMode, setViewMode] = useState('list')

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    useEffect(() => {
        if (!keyword.trim().length) {
            setFilteredRows(rows)
            return
        }

        const filteredValue = rows.filter(item => {
            return Object.values(item).some(value => {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(keyword.toLowerCase())
                }
                return false
            })
        })

        setFilteredRows(filteredValue)
    }, [keyword, rows])

    function handleViewChange(value) {
        setViewMode(value)
    }

    return (
        <>
            <GroupToolbar handleClick={actionButtonHandler} {...{ keyword, setKeyword, handleViewChange, viewMode }} />
            <Box sx={{ width: '100%' }}>
                {viewMode === 'list' ? (
                    <DataGrid
                        rows={filteredRows}
                        columns={getGroupColumns({ t, actionButtonHandler })}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20
                                }
                            }
                        }}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        disableRowSelectionOnClick
                    />
                ) : 
                <GroupTreeView data={rows} />
                }
            </Box>
        </>
    )
}