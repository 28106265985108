import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import { FormLabel, Typography, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { useForm } from 'react-hook-form'
import { ClientContext, PageContext } from '../../Context'
import { User } from 'generated/client/User'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import { useTranslation } from 'react-i18next'
import NTCButton from '../inputs/buttons/NTCButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import UserApiService from 'utils/UserHttpUtils'
import { handleException } from 'utils/HTTPUtils'
import { FormSelectControl } from 'components/inputs/forms/controls/FormSelectControl'

const NewsPreferences = ({ showResponseStatus }) => {

    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const [user, setUser] = useState<User>()
    const [expanded, setExpanded] = React.useState(true)
    const [selectedCountry, setSelectedCountry] = useState(null)

    const validationSchema = Yup.object().shape({
        news_country: Yup.string().optional(),
        news_state: Yup.string().optional(),
        news_city: Yup.string().optional(),
        news_keywords: Yup.string().optional()
    })

    const CountryList = [
        { label: 'United States of America', value: 'us' },
        { label: 'Canada', value: 'ca' },
        { label: 'Mexico', value: 'mx' },
        { label: 'Argentina', value: 'ar' },
    ]

    const {
        register,
        control,
        reset,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {
        if (!client.id) return
        getUserInfo()

    }, [client.id])

    function getUserInfo() {
        UserApiService.fetchUserProfile().then((res: User) => {
            setValue('news_keywords', res.news_keywords || '')
            setValue('news_country', res.news_country)
            setValue('news_state', res.news_state)
            setValue('news_city', res.news_city)

            setUser(res)
        }).catch(ex => console.log(ex))
    }

    const onSubmit = async (data) => {
        setLoadingPanel(true)
        try {
            const res = await UserApiService.updateProfile({
                user:
                {
                    news_country: data.news_country,
                    news_state: data.news_state,
                    news_city: data.news_city,
                    news_keywords: data.news_keywords
                }
            })
            setUser(res)
            setClient({ ...client, ...res })
            showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.edit`) })
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'User', message: errorMessage })
        } finally {
            setLoadingPanel(false)
        }
    }

    const handleUserChange = (val) => {
        const fountItem = CountryList.find((cl) => cl.value === val)
        if (fountItem) setSelectedCountry(fountItem)
        
        setValue('news_country', fountItem?.value || '')
    }

    return (
        <Accordion expanded={expanded} onChange={(ev, expanded) => { setExpanded(expanded) }}>
            <AccordionSummary
                sx={{ backgroundColor: theme.palette.custom.appbarHeader }} expandIcon={<ExpandMoreIcon />}>
                <FormControl>
                    <FormLabel sx={{ fontWeight: 'bold', color: theme.palette.custom.defaultText }}>{t('forms.user.preferences.news')}</FormLabel>
                </FormControl>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} pl={2} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'news_keywords'}
                                name={'news_keywords'}
                                defaultValue={user?.news_keywords || ''}
                                label={t('forms.user.field.news_keywords.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.news_keywords.helpText')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'news_city'}
                                name={'news_city'}
                                defaultValue={user?.news_city || ''}
                                label={t('forms.user.field.news_city.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.news_city.helpText')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'news_state'}
                                name={'news_state'}
                                defaultValue={user?.news_state || ''}
                                label={t('forms.user.field.news_state.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.news_state.helpText')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormSelectControl id={'news_country'}
                                name={'news_country'}
                                options={CountryList}
                                label={t('forms.user.field.news_country.label')}
                                defaultOptionLabel={t('forms.user.field.news_country.dropdownLabel')}
                                handleChange={(val) => handleUserChange(val)}
                                {...{ control, errors }} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.news_country.helpText')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} justifyContent="end" sx={{ marginTop: '7px', padding: '0px 16px' }}>
                        <Grid item>
                            <NTCButton onClick={() => {
                                reset(user)
                                setExpanded(false)
                            }} text={t('common.actions.cancel')} />
                        </Grid>
                        <Grid item>
                            <NTCButton
                                onClick={handleSubmit(onSubmit)} text={t('common.actions.save')}
                                backgroundColor={theme.palette.custom.nomadRed}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default NewsPreferences
