import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import NTCButton from '../inputs/buttons/NTCButton'
import FormPasswordControl from '../inputs/forms/controls/FormPasswordControl'
import { useTranslation } from 'react-i18next'
import { PasswordChecklist } from './PasswordChecklist'
import { passwordSchema } from './UserForm'
import LoginIcon from '@mui/icons-material/Login'
import { AuthRoutes } from 'utils/ServerRoutes'

export const ResetPassword = ({ showResponseStatus, reset_token, user }) => {

    const { t } = useTranslation()
    const theme = useTheme()
    const validationSchema = Yup.object().shape({ ...passwordSchema(t) })
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)
    const {
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema)
    })

    const onSubmit = async (data) => {
        const payload =
        {
            user: {
                reset_password_token: reset_token,
                password: data.password,
                password_confirmation: data.password_confirmation
            }
        }
        try {
            const response = await fetch(`${origin}${AuthRoutes.resetPassword}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;'
                },
                body: JSON.stringify(payload)
            })

            if (response.ok) {
                showResponseStatus({ success: true, entity: 'Password', message: t('forms.user.field.password.updateMessage') })
                setIsPasswordUpdated(true)
            } else {
                response.json().then(data => {
                    const errorMessages = Object.values(data['errors']).map((key: any | string | string[]) => key).join('\n')
                    showResponseStatus({ success: false, entity: 'User', message: errorMessages })
                })
            }
        } catch (ex) { }
    }

    const watchPassword = watch('password', '')
    const watchPasswordConfirmation = watch('password_confirmation', '')


    return (
        <Box px={3} py={2}>
            {!isPasswordUpdated ?
                <>
                    <Grid container spacing={3} display={'grid'}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: theme.palette.custom.menuBack }}>
                                {user.name} [{user.email}]
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPasswordControl
                                autoFocus={true}
                                id={'password'}
                                name={'password'}
                                defaultValue={''}
                                type={'password'}
                                showPassword={passwordVisible}
                                handleClickShowPassword={() => setPasswordVisible(!passwordVisible)}
                                label={t('forms.user.field.password.label')}
                                hasCustomError={true}
                                {...{ register, errors, watch }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPasswordControl
                                autoFocus={false}
                                id={'password_confirmation'}
                                name={'password_confirmation'}
                                defaultValue={''}
                                type={'password'}
                                showPassword={confirmPasswordVisible}
                                handleClickShowPassword={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                label={t('forms.user.field.confirmPassword.label')}
                                {...{ register, errors, watch }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PasswordChecklist password={watchPassword} stigEnable={false} />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                        <NTCButton onClick={handleSubmit(onSubmit)} text={t('common.actions.submit')}
                            backgroundColor={theme.palette.custom.nomadRed}
                            endIcon={<LoginIcon />}
                            sx={{ minWidth: 150 }} />
                    </Box>
                </>
                :
                <Box mt={5}>
                    <Typography sx={{ color: theme.palette.custom.menuBack }}>{t('forms.user.field.password.updateMessageInfo')}</Typography>
                </Box>
            }
            <Box display={'flex'} justifyContent={'flex-end'} sx={{ mt: 5, cursor: 'pointer' }} >
                <Link
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    variant="body2"
                    onClick={() => {
                        window.location.replace(`${origin}/login`)
                    }}>
                    <Typography sx={{ color: theme.palette.custom.menuBack }}>
                        {t(`common.actions.login`)}
                    </Typography>
                </Link>
            </Box>

        </Box>
    )
}

