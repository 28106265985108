import { useLocation } from 'react-router-dom'
import AppHeaderLayout from './AppHeaderLayout'
import SidebarNavLayout from './SidebarLayout'
import { useDispatch, useSelector } from 'react-redux'
import { Box, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { toggleTheme } from 'redux/themeSlice'
import { ClientContext, PageContextProvider } from 'Context'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import { useTranslation } from 'react-i18next'
import PageContainer from '../layout/page/PageLayout'
import BreadcrumbWithIcon from '../layout/header/BreadcrumbWithIcon'
import Reauthenticator from '../display/modals/Reauthenticator'

const MasterLayout = ({ children, menuData, drawerWidth }) => {
    const { i18n } = useTranslation()
    const { client } = useContext(ClientContext)
    const dispatch = useDispatch()
    const drawerTracker = useSelector((state) => state.drawerTracker.value)
    const themeTracker = useSelector((state) => state.themeTracker.value)
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)
    const location = useLocation()
    const currentPath = location.pathname

    function setUserPreferences(client, themeTracker, dispatch) {
        i18n.changeLanguage(client.locale)
        if (themeTracker !== `${client.theme}Theme`) {
            dispatch(toggleTheme())
        }
    }

    useEffect(() => {
        setUserPreferences(client, themeTracker, dispatch)
    }, [])

    return <>
        <PageContextProvider>
            <AppHeaderLayout marginLeft={drawerWidth} />
            <PageContainer open={drawerTracker && !isMobile} marginLeft={drawerWidth}>
                {isMobile && (
                    <Box sx={{ paddingBottom: '35px' }}>
                        <BreadcrumbWithIcon
                            separator={'/'}
                            path={currentPath} handleClick={() => { }} />
                    </Box>
                )}
                {children}
                <Reauthenticator />
            </PageContainer>
            <SidebarNavLayout menuData={menuData} drawerWidth={drawerWidth} />
        </PageContextProvider>
    </>

}
export default MasterLayout
