import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar'

interface NotificationBarProps {
    message: string,
    severity?: string
    open: boolean,
    setOpen: any,
    anchorOrigin?: SnackbarOrigin,
    duration?: number
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

/**
 * NotificationBar component to show the flash notification
 * @param message
 * @param severity
 * @param setOpen
 * @param open
 * @param anchorOrigin
 * @constructor
 */
export const FlashNotification = ({
    message,
    severity = 'success',
    setOpen,
    open,
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
    duration = 6000
}: NotificationBarProps) => {
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}
                  anchorOrigin={anchorOrigin}>
            <Alert onClose={handleClose} severity={severity as AlertColor}
                   sx={{ width: '100%', whiteSpace: 'pre-line' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}