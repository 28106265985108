import React from 'react'
import { useTranslation } from 'react-i18next';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { getIconById } from '../../static/svgs/icons';


export default function ContactUs() {
    const { t } = useTranslation()
    const theme = useTheme()

    const ICON = 'externalLink'
    const SUPPORT_PHONE = '4068636386'
    const SUPPORT_EMAIL = 'servicedesk@nomadgcs.com'

    // on a small screen, switch to a 1 column grid.
    const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: onlySmallScreen ? '1fr' : '1fr 1fr',
            gap: '12px'
        }}>
            {/*  PHONE  */}
            <a href={`tel:${SUPPORT_PHONE}`} style={{ textDecoration: 'none', color: 'unset' }}>
                <Card sx={{ ':hover': { boxShadow: '0px 1px 4px #444' } }}>
                    <CardContent>
                        <Typography sx={{ fontWeight: 'bold', '& svg': { transform: 'scale(0.75)' } }}>
                            {t('contact.phone')}  <span style={{ marginLeft: '0.25em', opacity: 0.4 }}>{getIconById(ICON)}</span>
                        </Typography>
                        <Typography>
                            <a href={`tel:${SUPPORT_PHONE}`} style={{ textDecoration: 'none', color: 'unset' }}> (406) 863-6386</a>
                        </Typography>
                    </CardContent>
                </Card>
            </a>

            {/*  EMAIL  */}
            <a href={`mailto:${SUPPORT_EMAIL}`} style={{ textDecoration: 'none', color: 'unset' }}>
                <Card sx={{ ':hover': { boxShadow: '0px 1px 4px #444' } }}>
                    <CardContent>
                        <Typography sx={{ fontWeight: 'bold', '& svg': { transform: 'scale(0.75)' } }}>
                            {t('contact.email')}  <span style={{ marginLeft: '0.25em', opacity: 0.4 }}>{getIconById(ICON)}</span>
                        </Typography>
                        <Typography>
                            <a href={`mailto:${SUPPORT_EMAIL}`} style={{ textDecoration: 'none', color: 'unset' }}>{SUPPORT_EMAIL}</a>
                        </Typography>
                    </CardContent>
                </Card>
            </a>

            {/*  MAILING ADDRESS  */}
            <Card sx={{ gridColumn: '1 / -1' }}>
                <CardContent>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t('contact.mailingAddress')}
                    </Typography>
                    <Typography>
                        Nomad GCS // PO Box 865 // Kalispell, MT 59903
                    </Typography>
                </CardContent>
            </Card>

            {/*  HEADQUARTERS LOCATION  */}
            <Card sx={{ gridColumn: '1 / -1' }}>
                <CardContent>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {t('contact.headQuarters')}
                    </Typography>
                    <Typography>
                        5414 US Hwy 2 // Columbia Falls, MT 59912
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
}