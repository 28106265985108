import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { ClientContext, PageContext } from '../../Context'
import RoleLists from './RoleLists'
import ConfirmModalDialog from '../display/modals/ConfirmModalDialog'
import { FormMode } from '../inputs/forms/Forms'
import { RoleForm } from './RoleForm'
import { Grid, useTheme } from '@mui/material'
import { RoleSortableList } from './RoleSortableList'
import { CREATE, ROLE, isAuthorized } from '../../generated/auth/Permissions'
import NTCButton from '../inputs/buttons/NTCButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useTranslation } from 'react-i18next'
import RoleApiService from 'utils/RoleHttpUtils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export interface RoleFormDialogProps {
    showResponseStatus?: any
    actionHandler?: any
    handleCloseModal?: any
}

const RoleManagement = ({ showResponseStatus }: RoleFormDialogProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [action, setAction] = useState<FormMode | null>(null)
    const [selectedId, setSelectedId] = useState('')
    const [rows, setRows] = useState([])
    const [tmpRows, setTmpRows] = useState([])
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { client } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const roleResponses = RoleApiService.fetchRoles()

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            setSelectedId(id)
            if (pathname.includes('edit')) {
                setAction(FormMode.EDIT)
            }
            else if (pathname.includes('clone')) {
                setAction(FormMode.CLONE)
            }
        } else if (pathname.includes('create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/roles')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])

    const actionButtonHandler = (command, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (command === FormMode.DELETE) {
            setOpenConfirmDialog(true)
        } else if (command === FormMode.EDIT) {
            navigate(`${item.id}/edit`)
        } else if (command === FormMode.CREATE) {
            navigate(`create`)
        } else if(command === FormMode.CLONE) {
            navigate(`${item.id}/clone`)
        }
    }

    const handleCloseModal = () => {
        navigate('/roles')
        roleResponses.refetch()
        setAction(null)
    }

    const onActionClick = (action: string) => {
        setLoadingPanel(true)
        action === FormMode.DELETE &&
            RoleApiService.deleteRole(selectedId)
                .then(() => {
                    showResponseStatus({
                        success: true,
                        message: t(`common.notifications.${action}`, { name: t('forms.role.title') })
                    })
                    roleResponses.refetch()
                })
                .catch(error => {
                    let errorMessage = ''
                    if (error.code === 400) {
                        errorMessage = t('forms.role.roleDeletion')
                    }
                    showResponseStatus({
                        success: false,
                        message: errorMessage
                    })
                })
                .finally(() => {
                    setOpenConfirmDialog(false)
                    handleCloseModal()
                    setLoadingPanel(false)
                })

    }
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        setRows((prevItems) => {
            const newItems = [...prevItems]
            newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0])
            return newItems
        })
    }

    return (
        <>
            <RoleSortableList items={roleResponses.result || []} handleSortEnd={handleSortEnd}
                actionButtonHandler={actionButtonHandler} />
            {isAuthorized(ROLE, CREATE) ?
                <Grid container spacing={2} sx={{ pt: 2, gap: 2 }}>
                    <NTCButton
                        endIcon={<AddOutlinedIcon />}
                        text={`${t('common.actions.add')} ${t('forms.role.title')}`}
                        sx={{ px: 4, ml: 2 }}
                        backgroundColor={theme.palette.custom.nomadRed}
                        onClick={() => actionButtonHandler(FormMode.CREATE, '')}
                    />
                </Grid> : <></>
            }
            {(() => {
                if (action === FormMode.DELETE) {
                    return (
                        <ConfirmModalDialog
                            open={openConfirmDialog} handleClose={handleCloseModal}
                            modalTitle={t(`common.actions.delete`) + ' ' + t('forms.role.title')}
                            handleConfirm={() => { onActionClick(action) }}
                            handleCancel={() => setAction(null)}
                            confirmMessage={t('forms.role.modal.confirm.delete.message')} />
                    )
                }

                if ([FormMode.CREATE, FormMode.EDIT, FormMode.CLONE].includes(action)) {
                    return (
                        <RoleForm
                            roleId={selectedId}
                            mode={action}
                            showResponseStatus={showResponseStatus}
                            onSuccess={() => { }}
                            handleCloseModal={handleCloseModal}
                        />
                    )
                }
                return null
            })()}
        </>
    )
}

export default RoleManagement
