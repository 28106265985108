import React, { Fragment, useContext } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { getIconById } from 'static/svgs/icons'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageContext } from 'Context'

// Function to render the Typography component
const BreadCrumbTextPart = ({ color, crumb, name }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <Typography color={color} sx={{
            display: 'flex', alignItems: 'center', fontFamily: 'Montserrat', fontWeight: 700,
            fontSize: 15, lineHeight: '1.5', textTransform: 'capitalize',
            color: theme.palette.custom.breadcrumb
        }}>
            {crumb.length === 36 ? (name || crumb) : t(`menu.${crumb}`, crumb)}
        </Typography>)
}

const BreadcrumbWithIcon = ({ path = '', getBreadcrumbIcon = getIconById, separator, handleClick }) => {
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`);
    const theme = useTheme();
    const { t } = useTranslation();
    const breadcrumbs = path.split('/').filter(crumb => crumb !== '');
    const lastIndex = breadcrumbs.length - 1;

    const { pageEntity } = useContext(PageContext)
    const pageEntityName = pageEntity.name

    return (
        <div role="presentation" onClick={handleClick} className="breadcrumb-container">
            <Breadcrumbs sx={{ marginLeft: `${isMobile ? '0' : '1.5rem'}` }} separator={separator} aria-label="breadcrumb">
                {breadcrumbs.map((crumb, index) => (
                    index === 0 ? (
                        <Link aria-label={t(`menu.${crumb}`, crumb)} key={index}
                            color={index === lastIndex ? 'text.primary' : 'inherit'}
                            href={`/${crumb}`}
                            sx={{
                                mr: 0, ml: '5px', display: 'flex', alignItems: 'center', textDecoration: 'none',
                                '&.MuiLink-root svg': {
                                    height: '1.1rem',
                                    width: '1.2rem',
                                    fill: `${theme.palette.custom.menuIcon}`,
                                    color: `${theme.palette.custom.menuIcon}`,
                                    mr: 0.8,
                                    'path': {
                                        fill: 'primary.breadCrumbColor',
                                        stroke: crumb === 'dashboard' ? '' : 'primary.breadCrumbColor'
                                    }
                                }
                            }}
                        >
                            {getBreadcrumbIcon(crumb)}
                            <BreadCrumbTextPart color='primary.breadCrumbColor' crumb={crumb} name={pageEntityName} />
                        </Link>
                    ) :
                        <BreadCrumbTextPart key={index} color={index === lastIndex ? 'text.primary' : 'inherit'} crumb={crumb} name={pageEntityName} />
                ))}
            </Breadcrumbs>
        </div>
    );
}

export default BreadcrumbWithIcon;
