import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@mui/material'

export default function SubMenuDrawer({ drawerWidth, openSidebar, toggleSidebar, marginTop, children }) {
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline/>
            <Drawer className="drawer-root"
                    keepMounted={false}
                    sx={{
                        width: `${drawerWidth}px` || '200px',
                        flexShrink: 0,
                        '&.MuiDrawer-root': {
                            zIndex: 1000,
                            width: 0
                        },
                        '& .MuiDrawer-paper': {
                            left: `${drawerWidth + 8}px` || '200px',
                            width: `${drawerWidth}px` || '200px',
                            height: 'auto',
                            marginTop: `${marginTop || 300}px`,
                            boxSizing: 'border-box',
                            backgroundColor: theme.palette.custom.menuBack,
                            borderRadius: '0px 11.25px 11.25px 0px'
                        }
                    }}
                    variant={'temporary'}
                    anchor={'left'}
                    open={openSidebar}
                    onClose={toggleSidebar}>

                {children}
            </Drawer>
        </Box>
    )
};
