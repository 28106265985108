import { keyframes } from '@mui/material'

// These styles can be called in any sx={} prop in any module and reflect commonly used sx={} patterns
// Use like so: <Button sx={{ ...flexJustifyAlignCenter, ...flexContentCenter, width: '3rem' }} />

export const flexJustifyAlignCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}

export const flexJustifySpaceAround = {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center'
}

export const flexJustifySpaceBetween = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

export const flexJustifyStart = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'start'
}

export const absolute5050TopLeftCenter = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

export const flexContentCenter = {
    display: 'flex',
    alignContent: 'center'
}

export const gridFlexEnd = {
    display: 'grid',
    direction: 'column',
    alignItems: 'flex-end'
}
export const cursorPointer = {
    cursor: 'pointer'
}

export const mapPanel = {
    display: 'flex',
    border: 1,
    position: 'absolute',
    top: 0,
    left: 60,
    padding: 1,
    minHeight: 200
}

// Spinning keyframes animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`
// Use like so:  sx={{ animation: spinAnimation, animationDuration: '.5s' }}/>
export const spinAnimation = `${spin} linear infinite`

const flash = keyframes`
  0%, 100% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
`
export const flashAnimation = `${flash} 1s infinite`

export const dropdownBorder = (theme) => {
    return {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: theme.palette.custom.moduleBorder }
    }
}

export const textFieldBorder = (color) => {
    return { '& .MuiFormLabel-root': { color: color }, '& fieldset': { borderColor: color } }
}

export const textBoxFocusedLabel = (theme) => {
    return { '& label.Mui-focused': { color: `${theme.palette.custom.defaultText}` } }
}

export const textBoxFocusedControl = (theme, invalid) => {
    const textColor = invalid ? theme.palette.custom.nomadRed : theme.palette.custom.moduleTitle
    return {
        '& .MuiFormLabel-root': { color: `${textColor} !important` },
        '& fieldset': { borderColor: `${textColor} !important` },
        '& .MuiInput-underline::after': { borderBottomColor: theme.palette.custom.moduleTitle }
    }
}
