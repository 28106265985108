import { HTTPMethod } from './HttpType'
import { requestEndpoint } from './HTTPUtils'
import useFetch from './HTTP/useFetch'
import { User } from 'generated/client/User'
import { AuthRoutes } from '../utils/ServerRoutes'

const userRoutePath = `/users`

/**
 * HTTP request to get the list of users
 * @param controller
 */
function fetchUsers() {
    return useFetch<any, User[]>(userRoutePath)
}

async function fetchAllUsers(data?: any, controller?: AbortController) {
    const endpoint = userRoutePath

    return await requestEndpoint<any, User[]>(
        endpoint, data, HTTPMethod.GET, controller
    )
}

function fetchUserStatus() {
    return useFetch<any, { [key: string]: { id: string, locked: boolean } }>(`${userRoutePath}/lockStatus`)
}

async function fetchUser(id: string, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${id}`;

    return await requestEndpoint<any, User>(
        endpoint, id, HTTPMethod.GET, controller
    )
}

async function fetchUserProfile(controller?: AbortController) {
    const endpoint = `${userRoutePath}/profile`;

    return await requestEndpoint<any, User>(
        endpoint, null, HTTPMethod.GET, controller
    )
}

/**
 * Submit the user basic information to create the entry
 * @param userEntity
 * @param controller
 */
async function createUser(userEntity: User, controller?: AbortController) {
    const endpoint = userRoutePath
    const objectifyUser = { user: userEntity }

    return await requestEndpoint<any, User>(
        endpoint, objectifyUser, HTTPMethod.POST, controller
    )
}

/**
 * Submit the user basic information to update the entry
 * @param data
 * @param controller
 */
async function updateUser(data: any, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${data.id}`
    const objectifyUser = { user: { ...data, id: undefined } }

    return await requestEndpoint<any, User>(
        endpoint, objectifyUser, HTTPMethod.PUT, controller
    )
}

/**
 *
 * @param id
 * @param controller
 */
async function deleteUser(id: string, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.DELETE, controller
    )
}

/**
 * updateUserLockStatus method to lock and unlock the user
 * @param id
 * @param locked
 * @returns 
 */
async function updateUserLockStatus(id: string, locked: boolean, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${id}/${locked ? 'lock' : 'unlock'}`

    return await requestEndpoint<any, User>(
        endpoint, locked, HTTPMethod.POST, controller
    )
}

async function addUserRoles(data: { id: string; roleIds: string[] }, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${data.id}/roles`

    return await requestEndpoint<any, string[]>(
        endpoint, data.roleIds, HTTPMethod.POST, controller
    )
}

async function removeUserRoles(data: { id: string; roleIds: string[] }, controller?: AbortController) {
    const endpoint = `${userRoutePath}/${data.id}/roles`

    return await requestEndpoint<any, string[]>(
        endpoint, data.roleIds, HTTPMethod.DELETE, controller
    )
}

async function updateProfile(data: any, controller?: AbortController) {
    const endpoint = `${userRoutePath}/profile`

    return await requestEndpoint<User, User>(
        endpoint, data, HTTPMethod.PATCH, controller
    )
}

async function changePassword(data: any, controller?: AbortController) {
    const endpoint = `${userRoutePath}/update_password`

    return await requestEndpoint<any, any>(
        endpoint, data, HTTPMethod.PATCH, controller
    )
}

async function logout(controller?: AbortController) {
    const endpoint = AuthRoutes.exit  // Logout route

    return await requestEndpoint<any, any>(
        endpoint, {}, HTTPMethod.DELETE, controller
    )
}

async function resendAccountConfirmEmail(data: any, controller?: AbortController) {
    const endpoint = `${userRoutePath}/confirmation`

    return await requestEndpoint<any, any>(
        endpoint, data, HTTPMethod.POST, controller
    )
}

async function impersonate(user: any, controller?: AbortController) {
    const endpoint = `${userRoutePath}/impersonate`
    const payload = { id: user.id }
    return await requestEndpoint<any, any>(
        endpoint, payload, HTTPMethod.POST, controller
    )
}

const UserApiService = {
    fetchUsers,
    fetchUserStatus,
    fetchAllUsers,
    fetchUser,
    fetchUserProfile,
    createUser,
    updateUser,
    deleteUser,
    updateUserLockStatus,
    removeUserRoles,
    addUserRoles,
    changePassword,
    updateProfile,
    logout,
    resendAccountConfirmEmail,
    impersonate,
}
export default UserApiService