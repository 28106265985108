import React, { useContext, useEffect, useState } from 'react'
import GroupList from './GroupList'
import { useTranslation } from 'react-i18next'
import GroupForm from './GroupForm'
import ConfirmModalDialog from 'components/display/modals/ConfirmModalDialog'
import ViewGroupDetails from './GroupDetailView'
import GroupApiService from 'utils/HTTP/GroupUtils'
import { FormMode } from 'components/inputs/forms/Forms'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PageContext } from 'Context'

function GroupManagement({ showResponseStatus }) {
    const { t } = useTranslation()
    const responses = GroupApiService.fetchGroups()

    const [action, setAction] = useState(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const [openDetailDialog, setOpenDetailDialog] = useState(false)

    const { setLoadingPanel } = useContext(PageContext)

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            setSelectedId(id)
            if (pathname.includes('edit')) {
                setAction(FormMode.EDIT)
            } else if (pathname.includes('clone')) {
                setAction(FormMode.CLONE)
            } else if (pathname.includes('view')) {
                setAction(FormMode.VIEW)
            }
        } else if (pathname.includes('create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/groups')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])

    const actionButtonHandler = (command, item) => {
        setAction(command)
        setSelectedId(item?.id)

        if (command == FormMode.DELETE) {
            setOpenConfirmDialog(true)
        } else if (command == FormMode.EDIT) {
            navigate(`${item.id}/edit`)
        } else if (command == FormMode.CREATE) {
            navigate(`create`)
        } else if (command == FormMode.CLONE) {
            navigate(`${item.id}/clone`)
        } else if (command == FormMode.VIEW) {
            navigate(`${item.id}/view`)
        }
    }

    const handleCloseModal = () => {
        responses.refetch()
        navigate('/groups')
        setAction(null)
    }

    const onActionClick = (action: string) => {
        setLoadingPanel(true)

        GroupApiService.deleteGroup(selectedId).then((res) => {
            showResponseStatus({ success: true, entity: 'Group', message: t(`common.notifications.${action}`, { name: t('forms.group.title') }) })
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Group', message: ex.messaage })
        }).finally(() => {
            setAction(null)
            setOpenConfirmDialog(false)
            handleCloseModal()
            setLoadingPanel(false)
        })
    }

    return (
        <>
            <GroupList rows={responses.result || []} actionButtonHandler={actionButtonHandler} />

            {(() => {
                if (action === FormMode.CREATE || action === FormMode.EDIT || action === FormMode.CLONE) {
                    return (
                        <GroupForm
                            groupId={selectedId}
                            mode={action}
                            showResponseStatus={showResponseStatus}
                            handleCloseModal={handleCloseModal} />
                    )
                }

                if (action === FormMode.DELETE) {
                    return <ConfirmModalDialog
                        modalTitle={t(`common.actions.delete`) + ' ' + t(`forms.group.title`)}
                        open={openConfirmDialog}
                        maxWidth={'md'}
                        handleClose={() => {
                            setOpenConfirmDialog(false)
                            handleCloseModal()
                        }}
                        handleCancel={() => {
                            setOpenConfirmDialog(false)
                            handleCloseModal()
                        }}
                        handleConfirm={() => {
                            onActionClick(action)
                        }}
                        confirmMessage={t(`forms.group.modal.confirm.delete.message`)}
                    />
                }
                if (action === FormMode.VIEW) {
                    return (
                        <ViewGroupDetails
                            groupId={selectedId} mode={action}
                            showResponseStatus={showResponseStatus}
                            handleCloseModal={handleCloseModal} />
                    )
                }
                return null
            })()}
        </>)
}

export default GroupManagement