import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import NTCButton from '../inputs/buttons/NTCButton'
import FormPasswordControl from '../inputs/forms/controls/FormPasswordControl'
import { useTranslation } from 'react-i18next'
import { ClientContext } from '../../Context'
import { PasswordChecklist } from './PasswordChecklist'
import { passwordSchema } from './UserForm'
import LoginIcon from '@mui/icons-material/Login'
import { AuthRoutes } from 'utils/ServerRoutes'
import { parseErrorMessages } from 'utils/HTTPUtils'
import { User } from 'generated/client/User'

export const ConfirmAccount = ({ showResponseStatus, confirmation_token }) => {

    const { t } = useTranslation()
    const theme = useTheme()
    const validationSchema = Yup.object().shape({ ...passwordSchema(t) })
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [checkingAcount, setCheckingAccount] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [isAlreadyConfirmed, setIsAreadyConfirmed] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const [user, setUser] = useState({})

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema)
    })

    const watchPassword = watch('password', '')
    const watchPasswordConfirmation = watch('password_confirmation', '')

    const onSubmit = async (data) => {
        const payload = {
            user: {
                confirmation_token: confirmation_token,
                password: data.password,
                password_confirmation: data.password_confirmation
            }
        }
        const response = await fetch(`${origin}${AuthRoutes.confirmAccount}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;'
            },
            body: JSON.stringify(payload)
        })

        if (response.ok) {
            showResponseStatus({ success: true, entity: 'Password', message: t('forms.user.field.password.updateMessage') })
            setConfirmed(true)
        } else {
            response.json().then(data => {
                const errorMessages = parseErrorMessages(data).join('\n')
                showResponseStatus({ success: false, entity: 'User', message: errorMessages })
            })
        }
    }

    async function checkAccountConfirmed() {
        const payload = {
            confirmation_token,
        }
        const response = await fetch(`${origin}${AuthRoutes.checkAccountConfirmed}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;'
            },
            body: JSON.stringify(payload)
        })

        if (response.ok) {
            response.json().then(data => {
                setUser(data)
                setIsAreadyConfirmed(false)
                setCheckingAccount(false)
            })
        } else {
            response.json().then(data => {
                const errorMessage = parseErrorMessages(data).join('\n')
                showResponseStatus({ success: false, entity: 'User', message: errorMessage })
                setErrorMessage(errorMessage)
                setCheckingAccount(false)
            })
        }
    }

    useEffect(() => {
        checkAccountConfirmed()
    }, [])


    return (
        <Box px={3} py={2}>
            {checkingAcount && (
                <></> // Empty fragment for loading state or other handling
            )}
            {errorMessage && (
                <Box mt={5}>
                    <Typography sx={{ color: theme.palette.custom.menuBack, fontSize: 20 }}>{errorMessage}</Typography>
                </Box>
            )}
            {!checkingAcount && !errorMessage && !confirmed && (
                <>
                    <Grid container spacing={3} display={'grid'}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: theme.palette.custom.menuBack }}>
                                {user.name} [{user.email}]
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPasswordControl
                                autoFocus={true}
                                id={'password'}
                                name={'password'}
                                defaultValue={''}
                                type={'password'}
                                showPassword={passwordVisible}
                                handleClickShowPassword={() => setPasswordVisible(!passwordVisible)}
                                label={t('forms.user.field.password.label')}
                                hasCustomError={true}
                                {...{ register, errors, watch }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPasswordControl
                                autoFocus={false}
                                id={'password_confirmation'}
                                name={'password_confirmation'}
                                defaultValue={''}
                                type={'password'}
                                showPassword={confirmPasswordVisible}
                                handleClickShowPassword={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                label={t('forms.user.field.confirmPassword.label')}
                                {...{ register, errors, watch }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PasswordChecklist password={watchPassword} stigEnable={false} />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                        <NTCButton onClick={handleSubmit(onSubmit)} text={t('common.actions.submit')}
                            backgroundColor={theme.palette.custom.nomadRed}
                            endIcon={<LoginIcon />}
                            sx={{ minWidth: 150 }} />
                    </Box>
                </>
            )}
            {!errorMessage && confirmed && (
                <Box mt={5}>
                    <Typography sx={{ color: theme.palette.custom.menuBack }}>{t('forms.user.field.email.accountConfirmed')}</Typography>
                </Box>
            )}
            <Box display={'flex'} justifyContent={'flex-end'} sx={{ mt: 5, cursor: 'pointer' }} >
                <Link
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    variant="body2"
                    onClick={() => {
                        window.location.replace(`${origin}/login`)
                    }}>
                    <Typography sx={{ color: theme.palette.custom.menuBack }}>
                        {t(`common.actions.login`)}
                    </Typography>
                </Link>
            </Box>
        </Box>
    )
}