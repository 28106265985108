import React, { useEffect } from 'react'
import { FlashNotification } from '../../display/modals/FlashNotification'
import { useTranslation } from 'react-i18next'

const LOGGED_OUT_STORAGE_KEY = 'user-logged-out'

/**
 * Returns whether the user has just logged out or not.
 */
function checkIfUserLoggedOut() {
    return sessionStorage.getItem(LOGGED_OUT_STORAGE_KEY) === 'true'
}

/**
 * Clears the flag indicating user has just logged out, so system doesn't display logout notification.
 */
function clearUserLoggedOutTrigger() {
    sessionStorage.removeItem(LOGGED_OUT_STORAGE_KEY)
}

/**
 * Sets the flag indicating user has just logged out, so system displays logout notification.
 */
export function setUserLoggedOutTrigger() {
    sessionStorage.setItem(LOGGED_OUT_STORAGE_KEY, 'true')
}

export default function LogoutConfirmation() {

    const { t } = useTranslation()
    const [showLogoutMessage, setShowLogoutMessage] = React.useState(false)
    useEffect(() => {
        if (checkIfUserLoggedOut()) {
            clearUserLoggedOutTrigger()
            // The timeout here is to prevent flickering as the component gets re-rendered multiple times
            // before the page is finally loaded.  Basically we want to hide the message until we think the
            // page is loaded.
            setTimeout(() => {
                setShowLogoutMessage(true)
            }, 250)
        }
    }, [])

    return showLogoutMessage ?
        <FlashNotification open={showLogoutMessage}
                           setOpen={setShowLogoutMessage}
                           message={t('logoff.message')}
                           severity={'success'}
                           anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                           duration={30000}/> :
        <></>
}
