import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import { FormLabel, Typography, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { useForm } from 'react-hook-form'
import { ClientContext, PageContext } from '../../Context'
import { User } from 'generated/client/User'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import { useTranslation } from 'react-i18next'
import NTCButton from '../inputs/buttons/NTCButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import UserApiService from 'utils/UserHttpUtils'
import { handleException } from 'utils/HTTPUtils'

const WeatherPreferences = ({ showResponseStatus }) => {

    const { t } = useTranslation()
    const theme = useTheme()
    const { client, setClient } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const [user, setUser] = useState<User>()
    const [expanded, setExpanded] = React.useState(true)

    const validationSchema = Yup.object().shape({
        weather_country: Yup.string().optional(),
        weather_state: Yup.string().optional(),
        weather_city: Yup.string().optional()
    })

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {
        if (!client.id) return
        getUserInfo()

    }, [client.id])

    function getUserInfo() {
        UserApiService.fetchUserProfile().then((res: User) => {
            setValue('weather_country', res.weather_country)
            setValue('weather_state', res.weather_state)
            setValue('weather_city', res.weather_city)
            setUser(res)
        }).catch(ex => console.log(ex))
    }

    const onSubmit = async (data) => {
        setLoadingPanel(true)
        try {
            const res = await UserApiService.updateProfile({
                user:
                {
                    weather_country: data.weather_country,
                    weather_state: data.weather_state,
                    weather_city: data.weather_city
                }
            })
            setUser(res)
            setClient({ ...client, ...res })
            showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.edit`) })
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'User', message: errorMessage })
        } finally {
            setLoadingPanel(false)
        }
    }

    return (
        <Accordion expanded={expanded} onChange={(ev, expanded) => { setExpanded(expanded) }}>
            <AccordionSummary
                sx={{ backgroundColor: theme.palette.custom.appbarHeader }} expandIcon={<ExpandMoreIcon />}>
                <FormControl>
                    <FormLabel sx={{ fontWeight: 'bold', color: theme.palette.custom.defaultText }}>{t('forms.user.preferences.weather')}</FormLabel>
                </FormControl>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} pl={2} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'weather_city'}
                                name={'weather_city'}
                                defaultValue={user?.weather_city || ''}
                                label={t('forms.user.field.weather_city.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.weather_city.helpText')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'weather_state'}
                                name={'weather_state'}
                                defaultValue={user?.weather_state || ''}
                                label={t('forms.user.field.weather_state.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.weather_state.helpText')}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormTextControl
                                id={'weather_country'}
                                name={'weather_country'}
                                defaultValue={user?.weather_country || ''}
                                label={t('forms.user.field.weather_country.label')}
                                required={false}
                                {...{ register, errors }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography>
                                {t('forms.user.field.weather_country.helpText')}
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1} justifyContent="end" sx={{ marginTop: '7px', padding: '0px 16px' }}>
                        <Grid item>
                            <NTCButton onClick={() => {
                                reset(user)
                                setExpanded(false)
                            }} text={t('common.actions.cancel')} />
                        </Grid>
                        <Grid item>
                            <NTCButton
                                onClick={handleSubmit(onSubmit)} text={t('common.actions.save')}
                                backgroundColor={theme.palette.custom.nomadRed}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default WeatherPreferences