import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { Box, Grid, useTheme } from '@mui/material'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import NTCButton from '../inputs/buttons/NTCButton'
import BaseModalDialog from '../display/modals/BaseModalDialog'
import { Role } from 'generated/auth/Role'
import { FormMode } from 'components/inputs/forms/Forms'
import { useTranslation } from 'react-i18next'
import RoleApiService from 'utils/RoleHttpUtils'
import { handleException } from 'utils/HTTPUtils'
import { FormMultiSelectGroupControl } from 'components/inputs/forms/controls/FormMultiSelectGroupControl'
import { Permissions } from 'generated/auth/Permission'
import { prepareSelectedList } from 'components/inputs/forms/controls/FormMultiSelectControl'
import { PageContext } from 'Context'

export interface RoleFormProps {
    roleId?: string
    mode: FormMode
    showResponseStatus?: any
    handleCloseModal?: any
    onSuccess?: () => void
}

/**
 * Role form to handle add, edit and clone functionality for a role
 * @param roleId
 * @param mode
 * @param handleCloseModal
 * @param showResponseStatus
 * @constructor
 */
const RoleForm = ({ roleId, mode, handleCloseModal, showResponseStatus, onSuccess }: RoleFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { setLoadingPanel } = useContext(PageContext)
    const defaultValues: Role = { id: roleId, name: '', description: '', permissions: [] }
    const [roleEntity, setRoleEntity] = useState<Role>(defaultValues)
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const [permissionOptions, setPermissionOptions] = useState(Permissions.map(p => {
        return { value: `${p.resource}.${p.action}`, label: `${p.action.toUpperCase()}`, groupBy: p.resource }
    }))

    const validationSchema = Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('forms.role.field.name.validation.required')),
        description: Yup.string().required(t('forms.role.field.description.validation.required')),
        permissions: Yup.array()
    })

    const { control, register, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    })

    useEffect(() => {
        if (roleId) {
            getRole(roleId)
        }
    }, [mode, roleId])

    const getRole = (roleId: string) => {
        RoleApiService.fetchRole({ id: roleId }).then((res: Role) => {
            if (mode === FormMode.CLONE) {
                res.name = `${res.name} (Copy)`
            }
            const permissionPaths = res.permissions?.map(p => `${p.resource}.${p.action}`)
            const selectedPermissions = prepareSelectedList(permissionOptions, permissionPaths)

            setValue('name', res.name)
            setValue('description', res.description)
            setValue('permissions', selectedPermissions)
            setRoleEntity(res)

        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Role', message: ex.message })
            console.error(ex)
        })
    }

    const onSubmit = async ({ name, description, permissions }) => {
        setLoadingPanel(true)
        try {
            const _permissions = permissions.map(p => {
                const [resource, action] = p.value.split('.')
                return { resource, action }
            })
            const response =
                [FormMode.CREATE, FormMode.CLONE].includes(mode) ?
                    await RoleApiService.createRole({ name, description, permissions: _permissions }) :
                    mode === 'edit' ? await RoleApiService.updateRole(roleId, { name, description, permissions: _permissions }) : null

            if (response) {
                showResponseStatus({ success: true, entity: 'Role', message: t(`common.notifications.${mode}`, { name: t('forms.role.title')}) })
                onSuccess()
            }
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'Role', message: errorMessage })   
        } finally {
            setOpenFormDialog(false)
            handleCloseModal()
            setLoadingPanel(false)
        }
    }


    return (
        <BaseModalDialog
            modalTitle={t(`common.actions.${mode}`) + ' ' + t('forms.role.title')}
            maxWidth="md"
            open={openFormDialog}
            handleClose={() => {
                setOpenFormDialog(false)
                handleCloseModal()
            }}>
            <Box px={3} py={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormTextControl id={'name'} name={'name'} defaultValue={roleEntity.name}
                            label={t('forms.role.field.name.label')} {...{ register, errors }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl id={'description'} name={'description'}
                            defaultValue={roleEntity.description}
                            label={t('forms.role.field.description.label')} {...{ register, errors }} />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormMultiSelectGroupControl
                            name={'permissions'}
                            label={t('forms.role.field.permissions.label')}
                            options={permissionOptions || []}
                            {...{ control, errors }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                    <Grid container spacing={3} justifyContent={'right'}>
                        <Grid item xs={12} sm={3}>
                            <NTCButton text={t('common.actions.cancel')} onClick={() => handleCloseModal()} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NTCButton onClick={handleSubmit(onSubmit)}
                                text={t('common.actions.submit')}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </BaseModalDialog>
    )
}

export {
    RoleForm
}