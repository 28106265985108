"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironment = exports.addOrgIdToUrl = exports.handleException = exports.parseErrorMessages = exports.clearSessionStorage = exports.savePageLayouts = exports.fetchPageLayouts = exports.requestEndpoint = void 0;
const Utils_1 = require("Utils");
const HttpType_1 = require("./HttpType");
const ServerRoutes_1 = require("utils/ServerRoutes");
async function requestEndpoint(endpoint, request, method, controller, retrying = false) {
    const options = {
        method,
        signal: controller === null || controller === void 0 ? void 0 : controller.signal
    };
    const headers = new Headers();
    // Add the JWT token to the headers
    const token = sessionStorage.getItem(Utils_1.AUTH_TOKEN_KEY);
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }
    // The following line tells the server we want JSON returned
    headers.append('Accept', HttpType_1.HTTPContentType.APPLICATION_JSON);
    if (method !== HttpType_1.HTTPMethod.GET) {
        headers.append('Content-Type', `${HttpType_1.HTTPContentType.APPLICATION_JSON}; charset=UTF-8`);
        options.body = JSON.stringify(request);
    }
    options.headers = headers;
    const response = await fetch(endpoint, options);
    if (response.ok) {
        if (response.redirected) {
            // Redirect on password change running infinite loop, to get rid of that clear the session data
            // Other work around is on self password change, get the new session token which should set into cache
            clearSessionStorage();
            document.location = response.url;
        }
        // Check if response is JSON or text
        const contentType = response.headers.get('Content-Type') || '';
        if (response.status !== 204 && contentType.includes(HttpType_1.HTTPContentType.APPLICATION_JSON)) {
            return await response.json();
        }
        else {
            return await response.text();
        }
    }
    else {
        if (response.status === 401) { // If our token expired and we haven't yet tried to refresh, then attempt a single token refresh
            if (!retrying) {
                try {
                    const refreshToken = sessionStorage.getItem(Utils_1.REFRESH_TOKEN_KEY);
                    const headers = new Headers();
                    headers.append('Authorization', `Bearer ${refreshToken}`);
                    headers.append('Accept', HttpType_1.HTTPContentType.APPLICATION_JSON);
                    headers.append('Content-Type', `${HttpType_1.HTTPContentType.APPLICATION_JSON}; charset=UTF-8`);
                    // Add the JWT token to the headers
                    const options = {
                        method: 'POST',
                        headers: headers
                    };
                    const response = await fetch(ServerRoutes_1.AuthRoutes.refresh, options);
                    const tokenJson = await response.json();
                    if (tokenJson) {
                        (0, Utils_1.saveJwt)(tokenJson);
                        // Retry the API call one time
                        return requestEndpoint(endpoint, request, method, controller, true);
                    }
                    else {
                        forceLogout();
                    }
                }
                catch (error) {
                    // Handle refresh token error or redirect to login
                }
            }
            else {
                forceLogout();
            }
        }
        else {
            response['message'] = response.statusText;
            response['code'] = response.status;
            throw response;
        }
    }
}
exports.requestEndpoint = requestEndpoint;
function forceLogout() {
    clearSessionStorage();
    window.location.reload();
}
/**
 * Get the list of module layouts based on category
 * @param category
 * @param controller
 */
async function fetchPageLayouts(pageId = '', controller) {
    const endpoint = `/v1/layouts/${pageId}`;
    return await requestEndpoint(endpoint, null, HttpType_1.HTTPMethod.GET, controller);
}
exports.fetchPageLayouts = fetchPageLayouts;
/**
 * Get the list of module layouts based on category
 * @param category
 * @param controller
 */
async function savePageLayouts(data, controller) {
    const endpoint = `/v1/layouts/${data.pageId}`;
    return await requestEndpoint(endpoint, data, HttpType_1.HTTPMethod.POST, controller);
}
exports.savePageLayouts = savePageLayouts;
function clearSessionStorage() {
    sessionStorage.clear();
}
exports.clearSessionStorage = clearSessionStorage;
/**
 * Parse errors json return from api
 * @param jsonErrors e.g. { "errors": { "email": ["has already been taken"], "password": ["can't be blank"] }}
 * @returns
 */
function parseErrorMessages(response, keyPrefix = true) {
    // TODO, implement translation key here once api support key based error message
    const errorMessages = [];
    if (response) {
        const errors = response.errors || response || {};
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const prefix = key.charAt(0).toUpperCase() + key.slice(1);
                const messages = errors[key].map(message => keyPrefix ? `${prefix} ${message}` : message);
                errorMessages.push(...messages);
            }
        }
    }
    return errorMessages;
}
exports.parseErrorMessages = parseErrorMessages;
async function handleException(ex) {
    let errorMessage = ex.message;
    if (ex.json) {
        try {
            const errorData = await ex.json();
            const errorMessages = parseErrorMessages(errorData).join('\n');
            errorMessage = errorMessages || errorMessage;
        }
        catch (jsonEx) {
            console.log(jsonEx.message);
        }
    }
    return errorMessage;
}
exports.handleException = handleException;
async function handleException(ex) {
    let errorMessage = ex.message;
    if (ex.json) {
        try {
            const errorData = await ex.json();
            const errorMessages = parseErrorMessages(errorData).join('\n');
            errorMessage = errorMessages || errorMessage;
        }
        catch (jsonEx) {
            console.log(jsonEx.message);
        }
    }
    return errorMessage;
}
exports.handleException = handleException;
function addOrgIdToUrl(url, orgId) {
    // Check if the URL already contains a query string
    const separator = url.includes('?') ? '&' : '?';
    // If orgId is not empty and not already in the URL, add it
    if (orgId && !url.includes('org_id=')) {
        // Append assetId to the URL
        return `${url}${separator}org_id=${orgId}`;
    }
    // If assetId is empty or already in the URL, return the original URL
    return url;
}
exports.addOrgIdToUrl = addOrgIdToUrl;
async function getEnvironment() {
    const endpoint = `/env`;
    return await requestEndpoint(endpoint, null, HttpType_1.HTTPMethod.GET);
}
exports.getEnvironment = getEnvironment;
