import { styled, Theme } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import React from 'react'

interface Props extends SwitchProps {
    checked: boolean
}

interface StyleProps {
    theme?: Theme
}

const IOSSwitch = styled((props: Props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple checked={props.checked} {...props} />
))<StyleProps>(({ theme }) => ({
    width: 29,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '2.5px 3px 3px 3px',
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.custom.nomadRed,
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[600]
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 11,
        height: 11
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#bdbdbd',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        marginTop: '2.5px',
        marginLeft: '0px'
    }
}))

export default IOSSwitch
