import React, { useEffect, useRef } from 'react'
import Sortable from 'sortablejs'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import DehazeIcon from '@mui/icons-material/Dehaze'
import { RenderActionItems } from '../renderActionItems/RenderActionItems'
import { READ, ROLE, DELETE, CREATE, UPDATE } from 'generated/auth/Permissions'

export const RoleSortableList = ({ items, handleSortEnd, actionButtonHandler }: any) => {
    const sortableRef = useRef()

    return (
        <List dense ref={sortableRef}>
            {items.filter(item => !['super_admin', 'org_admin'].includes(item.name)).map((item, index) => (
                <ListItem key={item.id + index} className="sort-handler">
                    {/* <ListItemIcon><DehazeIcon/></ListItemIcon> */}
                    <RenderActionItems
                        item={item}
                        itemType={'role'}
                        permissions={{
                            view:  { resource: ROLE, action: READ },
                            edit: { resource: ROLE, action: UPDATE },
                            create: { resource: ROLE, action: CREATE },
                            clone: { resource: ROLE, action: CREATE },
                            delete: { resource: ROLE, action: DELETE }
                        }}
                        actionButtonHandler={actionButtonHandler}
                    />

                </ListItem>
            ))}
        </List>
    )
}

