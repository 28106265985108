import * as React from 'react'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { AppBar, Box, ClickAwayListener, Grid, Menu, MenuItem, MenuList, TextField, useTheme } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import './ModuleWrapper.css'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { useTranslation } from 'react-i18next'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import { LayoutContext } from '../display/grid/NTCGridLayout'
import { calculateGridHeight } from 'utils/MathUtils'
import MenuIcon from '@mui/icons-material/Menu'
import { alpha, styled } from '@mui/material/styles'
import { MenuProps } from '@mui/material/Menu'
import { TranslationKey } from 'generated/util/TranslationKey'
import { translateKey } from 'i18n/translations/Translation'
import { handleConfirmKeyPress } from 'utils/StateUtil'
import { UiModuleType } from './UiModuleProps'
import { useTranslation } from 'react-i18next'

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}))

interface ModuleWrapperProps {
    style?: any,
    isCollapsed?: boolean,
    name: TranslationKey,
    children: any
    statusElement: React.ReactNode
    minWidth?: number,
    stateChangeCallback?,
    minHeight?: number,
    settingsFunction?: () => void,
    sliderSwitchFunction?: () => void,
    moduleInDash?: boolean,
    moduleHasLock?: boolean,
    moduleHasSettings?: boolean,
    moduleHasGraph?: boolean
    moduleHasSliderOptions?: boolean
    id: string,
    type: UiModuleType
}

/**
 * A wrapper to display modules consistently across the interface
 *
 * @param isCollapsed collapses the module body and only displays the header if true
 * @param moduleName the name that will be displayed as the title of the module
 * @param children the elements displayed inside the module body
 * @param statusElement JSX element passed into the module body to be displayed as a status
 * @param minHeight the minimum height of the module
 * @param moduleHasSliderOptions boolean to determine if slider type option should be displayed on dropdown default of false
 * @param sliderSwitchFunction function to be executed on slider option click
 * @param moduleHasSettings boolean to determine if settings option should be displayed, default of false
 * @param settingsFunction function executed on settings option click
 * @param moduleHasGraph boolean to determine if graph view option should be displayed, default of false
 * @param moduleHasLock boolean to determine if lock option should be displayed, default of true
 * @param moduleInDash boolean to determine if move to dash option should be displayed, default of true
 * @param id module ID used in the react-grid-layout
 */
export default function ModuleWrapper({
    style,
    isCollapsed,
    name,
    children,
    statusElement,
    minHeight,
    settingsFunction = () => console.log('Settings clicked'),
    sliderSwitchFunction = () => console.log('Slider Change clicked'),
    moduleInDash = true,
    moduleHasLock = true,
    moduleHasSettings = false,
    moduleHasGraph = false,
    moduleHasSliderOptions = false,
    id,
    type
}: ModuleWrapperProps) {
    const layoutContext = useContext(LayoutContext)

    let theme = useTheme()
    const { t } = useTranslation()
    const ITEM_HEIGHT = 48

    const [editMode, setEditMode] = useState(false)
    const [collapsed, setCollapse] = useState(isCollapsed || false)
    const [headerName, setHeaderName] = useState(name)
    //const [gridHeight, setGridHeight] = useState(0)
    const [previousHeight, setPreviousHeight] = useState(0)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [anchorElDash, setAnchorElDash] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const openDash = Boolean(anchorElDash)
    const heightRef = useRef(null)
    const [dashboards, setDashboards] = useState([])

    //  This is a creative hack for touch events due to 'react-grid-layout'.  When 'react-grid-layout' is in non-drag
    //  mode, for some reason, when a menu is clicked, the close event is immediately fire (as if user clicked the
    //  background).  So we're adding a short timer to let the UI know that the menu should stay open.
    //
    const [doNotCloseMenu, setDoNotCloseMenu] = useState(false);
    const [touchDelay, setTouchDelay] = useState(false);
    const touchDelayTimer = React.useRef(null);
    useEffect(() => {
        if (doNotCloseMenu) {
            touchDelayTimer.current = setInterval(() => {
                setDoNotCloseMenu(false);
            }, 500)
        }
        return () => clearInterval(touchDelayTimer.current);
    }, [doNotCloseMenu]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
        setAnchorElDash(null)
    }

    const handleClickDash = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElDash(event.currentTarget)
    }
    const handleCloseDash = () => {
        setAnchorElDash(null)
    }

    useEffect(() => {
        // Current height of the module in pixels
        const height = heightRef.current.clientHeight

        // Convert pixels to grid units
        let gridHeight = calculateGridHeight(height)

        // Save grid height so it can be restored when module is expanded using layoutContext below
        setPreviousHeight(gridHeight)
    }, [collapsed])

    const options = [
        {
            display: true, // Will always display in every module
            id: '913a698b-f8e3-4939-ad85-eb94bef9716f',
            name: 'expandCollapseOption',
            component: <Box sx={{ width: '100%' }} onClick={() => {
                // Set the height of the grid using layout context to the previous height of the module see NTCGridLayout.tsx
                layoutContext(id, collapsed ? previousHeight : .5, collapsed ? 100 : 2)
                // Change how much is displayed in the module
                setCollapse(!collapsed)
                // Switch icon based on state of the module
            }}> {collapsed ?
                <IconButton>
                    <KeyboardArrowDownIcon/>
                </IconButton> :
                <IconButton>
                    <KeyboardArrowUpIcon/>
                </IconButton>}
                {/*Switch text based on state of collapsed variable*/}
                {collapsed ? t('dropDowns.moduleHeader.expand') : t('dropDowns.moduleHeader.collapse')}
            </Box>
        },
        {
            display: moduleHasSettings, // does not display by default
            id: 'db473d99-acf1-45e4-af37-4cd0f1fe19ca',
            name: 'settingsOption',
            component:
                <Box sx={{ width: '100%' }}
                     onClick={settingsFunction}> {/*Callback to settings function passed in by parent module*/}
                    <IconButton>
                        <SettingsRoundedIcon/>
                    </IconButton>
                    {t('dropDowns.moduleHeader.settings')}
                </Box>
        }
    ]

    return (
        // See https://mui.com/material-ui/react-card/ for more info on the <card/> tag
        <Card ref={heightRef}
              sx={{
                  // Must be set to 100% to take up full space of parent component, usually the react-grid-layout
                  height: '100%',
                  // todo remove border on card, see NeverDown.tsx to see how border is bothersome
                  border: theme.palette.custom.moduleBorder,
                  boxShadow: 'none',
                  br: 6,
                  '&.MuiPaper-root': {
                      overflow: 'auto'
                  }
              }}>
            {/*Start of the Module Header*/}
            {/*See https://mui.com/material-ui/react-app-bar/ for appBar info*/}
            <AppBar position="sticky"
                    sx={{
                        height: '4.5rem',
                        boxShadow: 0,
                        backgroundColor: theme.palette.custom.appbarHeader
                    }}>
                <Toolbar sx={{ paddingTop: '.3rem' }} className={'drag-handle'}>
                    <Grid container justifyContent={'space-between'}>
                        {/*Module Title Will take up 11 columns when screen is small and 6 otherwise, always first*/}
                        <Grid item order={{
                            xs: 1,
                            sm: 1
                        }}> {/*For more on order see https://stackoverflow.com/questions/54726871/changing-the-order-of-grid-item-stacking-in-material-ui*/}
                            {editMode ?
                                // Input for when module title is in edit mode
                                <ClickAwayListener
                                    onClickAway={() => setEditMode(false)}>{/* Handles click away, keep in mind touchscreens may not have keyboard*/}
                                    <TextField
                                        sx={{ marginBottom: '.1rem' }}
                                        onKeyDown={e => handleConfirmKeyPress(e, setEditMode, !editMode)}
                                        id="standard-basic"
                                        variant="standard"
                                        defaultValue={translateKey(t, headerName)}
                                        onChange={(e) =>
                                            setHeaderName({ value: e.currentTarget.value })
                                        }/>
                                </ClickAwayListener> :
                                // Regular title display
                                <Typography className={'module-title'}
                                            onClick={() => setEditMode(!editMode)} lineHeight={'2'}
                                            variant="h6" component="div"
                                            sx={{
                                                flexGrow: 1,
                                                fontWeight: '600',
                                                color: theme.palette.custom.moduleTitle,
                                                textTransform: 'upperCase',
                                                fontSize: '1rem'
                                            }}>
                                    {translateKey(t, headerName)}
                                </Typography>}
                        </Grid>
                        {/* Status JSX Will take up 12 columns when screen is small and 5 otherwise*/}
                        <Grid style={style} item
                              order={{ xs: 3, sm: 2 }} // Order will change based on screen size
                              sx={{
                                  fontFamily: 'Montserrat',
                                  position: 'relative',
                                  color: theme.palette.custom.moduleStatus,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',             // JB
                                  overflowWrap: 'break-word'
                              }}>
                            {statusElement}
                        </Grid>
                        {/* Triple dot icon will only ever take up one column*/}
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item xs={1}
                              sm={1} order={{ xs: 2, sm: 3 }}>
                            <Box onMouseDown={(event) => event.stopPropagation()}>
                                {/*Must have Icon Button element in order to handle events on the MUI Icon*/}
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    onPointerDown={(e) => {
                                        handleClick(e)}} // needed for phones, button not picking up on click on mobile
                                    sx={{
                                        padding: '0',
                                        color: theme.palette.custom.greyScaleInactive,
                                        position: 'relative'
                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                {/*Start of the dropdown menu*/}
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button'
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={ (event, reason) => {
                                        handleClose(); }
                                    }
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '25ch',
                                            minHeight: minHeight
                                        }
                                    }}>
                                    <MenuList>
                                        {/*Loop through each drop down option and render*/}
                                        {options.map((option) =>
                                            // Check if the display option is set to true
                                            option.display ? // This is where those default displays get checked
                                                <MenuItem sx={{ padding: '0' }} key={option.id}
                                                          onClick={option.name !== 'dashboardOption' ? handleClose : undefined}> {/*On click close the drop-down*/}
                                                    {option.component}
                                                </MenuItem> :
                                                <div key={option.id}></div>
                                        )}
                                    </MenuList>
                                </Menu>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {collapsed ? // If collapsed is true do not display inner module just display the header and options
                <></> :
                <>
                    <Divider/>
                    <CardContent className={`module_body_container`} data-id={id}>
                        {/*Renders inner children components*/}
                        {children}
                    </CardContent>
                </>}
        </Card>
    )
}