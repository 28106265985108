import { requestEndpoint } from 'utils/HTTPUtils'
import { HTTPMethod } from 'utils/HttpType'


export async function fetchCurrentWeathe(keyword: string, controller?: AbortController) {
    // Use actual api endpoint, once PR merged
    // const endpoint = `http://api.weatherapi.com/v1/current.json?key=58877789352a4ed4b91120701241005&q=${keyword}&aqi=no`
    const endpoint = `/weather/current?q=${keyword}`
    return await requestEndpoint<any, any>(
        endpoint, null, HTTPMethod.GET, controller
    )
}

export const weatherResposne = {
    "location": {
        "name": "London",
        "region": "City of London, Greater London",
        "country": "United Kingdom",
        "lat": 51.52,
        "lon": -0.11,
        "tz_id": "Europe/London",
        "localtime_epoch": 1715344650,
        "localtime": "2024-05-10 13:37"
    },
    "current": {
        "last_updated_epoch": 1715344200,
        "last_updated": "2024-05-10 13:30",
        "temp_c": 23.0,
        "temp_f": 73.4,
        "is_day": 1,
        "condition": {
            "text": "Sunny",
            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
            "code": 1000
        },
        "wind_mph": 5.6,
        "wind_kph": 9.0,
        "wind_degree": 60,
        "wind_dir": "ENE",
        "pressure_mb": 1023.0,
        "pressure_in": 30.21,
        "precip_mm": 0.0,
        "precip_in": 0.0,
        "humidity": 44,
        "cloud": 0,
        "feelslike_c": 24.7,
        "feelslike_f": 76.5,
        "vis_km": 10.0,
        "vis_miles": 6.0,
        "uv": 6.0,
        "gust_mph": 8.7,
        "gust_kph": 14.1
    }
}