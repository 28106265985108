import React, { useContext } from 'react'

import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Chip, useMediaQuery } from '@mui/material'
import { toggle } from 'redux/booleanSlice'
import { toggleTheme } from 'redux/themeSlice'
import { IMPERSONATING_KEY, logout, SESSION_KEY } from 'Utils'
import { NomadIcon, NomadMiniIcon } from 'static/svgs/icons'
import { ProfileMenu, ProfileMenuDefaultList, ProfileMenuItems } from 'components/layout/navbar/SideNavProfile'
import UserApiService from 'utils/UserHttpUtils'
import { MediaQueryMaxWidthPoint } from 'constants/Layout'
import { ClientContext } from 'Context'
import BreadcrumbWithIcon from 'components/layout/header/BreadcrumbWithIcon'
import { AppHeader, AppHeaderMobile } from 'components/layout/header/AppHeader'
import { FlashNotification } from 'components/display/modals/FlashNotification'
import { useTranslation } from 'react-i18next'

const AppHeaderLayout = ({ marginLeft }) => {
    const { t } = useTranslation()
    const { client } = useContext(ClientContext)
    const dispatch = useDispatch()
    const location = useLocation()

    const [showNotification, setNotification] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState({ message: '', severity: 'success' })
    const showResponseStatus = (param) => {
        setNotification(true)
        setNotificationData({ message: param.message, severity: param.success ? 'success' : 'error' })
    }

    const drawerTracker = useSelector((state) => state.drawerTracker.value)
    const themeTracker = useSelector((state) => state.themeTracker.value)
    const isMobile = useMediaQuery(`(max-width: ${MediaQueryMaxWidthPoint}px)`)
    const isImpersonating = sessionStorage.getItem(IMPERSONATING_KEY)
    const currentPath = location.pathname
    const onBreadcrumbClick = ev => {
        // TO DO
        // Go to Route
    }
    const handleProfileMenuItemClick = async (event, action) => {
        if (action === 'logout') {
            await logout()
            sessionStorage.clear()
        } else if (action === 'login') {
            sessionStorage.clear()
            window.location.href = '/login'
        } else if (action === 'profileSettings') {
            window.location.href = '/profile'
        }
    }
    const renderProfileMenu = (showUserInfo) => {
        return (
            <>
                <ProfileMenu
                    profileName={client.name}
                    profileTitle={client.org.name}
                    profileImage={''}
                    showUserInfo={showUserInfo}
                    isMobile={isMobile} elementId={'profile-menu'}
                    lastLogin={client.current_sign_in_at}
                >
                    <ProfileMenuItems
                        items={ProfileMenuDefaultList({
                            isDarkMode: themeTracker === 'darkTheme',
                            toggleDarkMode: () => dispatch(toggleTheme()),
                            isCommandNode: false,
                            callback: ()=> {},
                            showResponseStatus,
                        })}
                        callback={handleProfileMenuItemClick} />
                </ProfileMenu>
                {showNotification ?
                    <FlashNotification open={showNotification}
                        setOpen={setNotification} message={notificationData.message}
                        severity={notificationData.severity} /> :
                    <></>}
            </>
        )
    }
    return (
        <>
            {isMobile ? (
                <AppHeaderMobile openSidebar={drawerTracker} onMenuIconClick={() => dispatch(toggle())}
                    logoIcon={<NomadMiniIcon />} marginLeft={marginLeft}>
                    {isImpersonating && <Chip label={t('forms.user.notifications.impersonating')} color="warning" />}
                    {renderProfileMenu(false)}
                </AppHeaderMobile>
            ) : (
                <AppHeader openSidebar={drawerTracker} marginLeft={marginLeft}
                    onMenuIconClick={() => dispatch(toggle())}
                    logoIcon={<NomadIcon />}>
                    <Box mt={'.75rem'} display={'flex'} justifyContent={'space-between'} flex={1}>
                        <BreadcrumbWithIcon separator={'/'} path={currentPath} handleClick={onBreadcrumbClick} />
                        {isImpersonating && <Chip label={t('forms.user.notifications.impersonating')} color="warning" />}
                        {renderProfileMenu(true)}
                    </Box>
                </AppHeader>
            )}
        </>
    )
}
export default AppHeaderLayout
