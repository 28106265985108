import * as React from 'react'
import { useContext, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Collapse from '@mui/material/Collapse'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getIconById } from 'static/svgs/icons'
import { useTheme } from '@mui/material'
import MenuItemsProps from './menu/menuItemsProps'
import SubMenuDrawer from './SubMenuDrawer'
import { ClientContext } from 'Context'
import { useTranslation } from 'react-i18next'
import { flashAnimation } from '../../../styles/GlobalSXProps'
import { Warning } from '@mui/icons-material'
import { ASSET, CREATE, GROUP, LIST, REMOTE_CONTROL, REMOTE_VIEW, ROLE, UPDATE, USER, isAuthorized, isSuperAdmin } from 'generated/auth/Permissions'

interface NavMenuItemsProps {
    menu: MenuItemsProps[],
    elementType?: any
    currentPath: string
    drawerWidth: number,
}

interface NavMenuItemProps {
    item: MenuItemsProps,
    elementType?: any
    currentPath: string,
    hasWarning?: boolean
    drawerWidth: number
    setDrawerId?: any
    openDrawerId?: any
}

/**
 *
 * @param menu List of menu items
 * @param currentPath path of route e.g /dashboard, /user/1234
 * @param elementType type of component e.g. RouterLink
 * @constructor
 */
const NavMenuItems = ({ menu, currentPath, elementType, drawerWidth }: NavMenuItemsProps) => {
    const theme = useTheme()
    const [warnings, setWarnings] = useState<{ [key: string]: boolean } | {}>({})
    const [menuWithWarning, setMenuWithWarning] = useState(menu)
    const [openDrawerId, setDrawerId] = useState(null)

    return (
        <List className="nav-menu-items"
            sx={{ marginTop: '47px', padding: '8px', '& svg': { fill: theme.palette.custom.menuText } }}>
            {menuWithWarning?.map((item, key) =>
                <NavMenuItem key={key} item={item} currentPath={currentPath} elementType={elementType}
                    hasWarning={item.warning} drawerWidth={drawerWidth} setDrawerId={setDrawerId}
                    openDrawerId={openDrawerId} />)}
        </List>
    )
}

/**
 *
 * @param item Single menu item
 */
const hasChildren = (item) => {
    const children = item.children
    return Array.isArray(children) && (children.length > 0)
}
const isSelected = (item, currentPath) => item.path === (currentPath === '/' ? '/overview' : currentPath);
const parentExpanded = (item, currentPath) => currentPath.startsWith(item.path) && (item.path !== currentPath)
/**
 *
 * @param menu Single menu item
 * @param currentPath path of route e.g /dashboard, /user/1234
 * @param elementType type of component e.g. RouterLink
 * @constructor
 */
const NavMenuItem = ({
    item,
    elementType,
    currentPath = '',
    hasWarning,
    drawerWidth,
    setDrawerId,
    openDrawerId
}: NavMenuItemProps) => {
    const { client } = useContext(ClientContext)
    const { t } = useTranslation()
    const theme = useTheme()
    const isMultiLevel = hasChildren(item)
    const isSelectedItem = isSelected(item, currentPath)
    const isPopupMenu = isMultiLevel && item.childrenMenuDisplayType === 'popup'
    const [open, setOpen] = useState(false)
    const [submenuTopMargin, setSubmenuTopMargin] = useState(0)

    const handleClick = (ev) => {
        if (setDrawerId) {
            if (openDrawerId === item.id) {
                setDrawerId(null)
            } else {
                setDrawerId(item.id)
            }
        }

        if (isPopupMenu) {
            setSubmenuTopMargin(ev.clientY)
        }
        setOpen((prev) => !prev)
    }

    let allowed = true
    switch (item.id) {
        case 'users':
            allowed = isAuthorized(USER, LIST) || isAuthorized(USER, CREATE) || isAuthorized(USER, UPDATE)
            break
        case 'roles':
            allowed = isAuthorized(ROLE, LIST) || isAuthorized(ROLE, CREATE) || isAuthorized(ROLE, UPDATE)
            break
        case 'ntc_assets':
            allowed = isAuthorized(ASSET, LIST) || isAuthorized(ASSET, REMOTE_CONTROL) || isAuthorized(ASSET, REMOTE_VIEW)
            break
        case 'groups':
            allowed = isAuthorized(GROUP, LIST) || isAuthorized(GROUP, CREATE) || isAuthorized(GROUP, UPDATE)
            break
        case 'organizations':
            allowed = isSuperAdmin(client)
            break
        case 'trainings':
            allowed = isSuperAdmin(client)
            break
        default:
            allowed = true
            break
    }

    const isSelectedDrawer = (id) => openDrawerId === id

    if (allowed) {
        return (
            <>
                <ListItem
                    aria-label={item.id}
                    key={item.path}
                    sx={{
                        padding: '0px 8px',
                        '& svg': {
                            fill: `${theme.palette.custom.menuIcon}cc`,
                            color: `${theme.palette.custom.menuIcon}cc`
                        }
                    }}>
                    <ListItemButton
                        to={isMultiLevel ? '#' : item.path}
                        component={elementType}
                        selected={isSelectedItem}
                        onClick={(ev) => {
                            isMultiLevel ? handleClick(ev) : undefined
                        }}
                        sx={{
                            marginBottom: '10px',
                            padding: '3px 8px',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}>
                        <ListItemIcon sx={{ minWidth: '25px', margin: '0px', color: theme.palette.custom.menuIcon }}
                            className={item.class}>
                            {getIconById(item.iconId)}
                        </ListItemIcon>
                        {item.format ?
                            <ListItemText primary={t(`menu.${item.id}`, { val: item.format.val })}
                                sx={{ color: 'white' }} /> :
                            <ListItemText primary={t(`menu.${item.id}`)}
                                sx={{ color: 'white' }} />}
                        {isMultiLevel && (
                            isPopupMenu ? (
                                isSelectedDrawer(item.id) ? <ExpandLessIcon sx={{ transform: 'rotate(90deg)' }} /> :
                                    <ExpandMoreIcon />
                            ) : (
                                open ? <ExpandLessIcon sx={{ transform: 'rotate(90deg)' }} /> : <ExpandMoreIcon />
                            )
                        )}

                    </ListItemButton>
                    {hasWarning &&
                        <Warning
                            sx={{
                                marginBottom: '1rem',
                                marginLeft: '7px',
                                '& path': { fill: '#ce2127' },
                                animation: flashAnimation
                            }} />
                    }
                </ListItem>
                {isMultiLevel && (isPopupMenu ?
                    (<SubMenuDrawer openSidebar={isSelectedDrawer(item.id)} toggleSidebar={handleClick}
                        drawerWidth={drawerWidth}
                        marginTop={submenuTopMargin}>
                        <List className={`nav-menu-items multilevel popup`} onClick={handleClick}>
                            {item?.children?.map((child, key) => (
                                <NavMenuItem key={key} item={child} currentPath={currentPath}
                                    elementType={elementType} drawerWidth={drawerWidth} />
                            ))}
                        </List>
                    </SubMenuDrawer>)
                    : (
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List className={`nav-menu-items multilevel`}>
                                {item?.children?.map((child, key) => (
                                    <NavMenuItem key={key} item={child} currentPath={currentPath}
                                        elementType={elementType} drawerWidth={drawerWidth}
                                        setDrawerId={setDrawerId}
                                        openDrawerId={openDrawerId} />
                                ))}
                            </List>
                        </Collapse>
                    ))
                }
            </>
        )
    } else {
        return <></>
    }
}

export {
    NavMenuItem,
    NavMenuItems
}
