import React from 'react'

const PulsingCircle = ({ onClick = () => {} }) => {
    return (
        <svg width="100" height="100" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <circle cx="20" cy="20" fill="none" r="3" stroke="red" strokeWidth="2">
                <animate attributeName="r" from="4" to="5" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
            </circle>
            <circle cx="20" cy="20" fill="red" r="2"/>
        </svg>
    )
}

export default PulsingCircle