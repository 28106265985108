import * as React from 'react'
import { useState } from 'react'
import { useMap } from 'react-map-gl'
import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LayersIcon from '@mui/icons-material/Layers'
import TuneIcon from '@mui/icons-material/Tune'
import FullscreenIcon from '@mui/icons-material/Fullscreen'

import { FullscreenExit } from '@mui/icons-material'
import MapDateFilter from './MapDateFilter'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { mapPanel } from 'styles/GlobalSXProps'
import { useTranslation } from 'react-i18next'

const MapControlPanel = ({ handleLayerChange, handleFilterChange, settings, filterDate }) => {
    const { current: map } = useMap()
    const [view, setView] = React.useState('')
    const [fullscreenMode, setFullScreenMode] = useState(false)

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setView(nextView)
        if (event.currentTarget.ariaLabel === 'fullscreen') {
            if (fullscreenMode) {
                setFullScreenMode(false)
                document.exitFullscreen()
            } else {
                map.getContainer().requestFullscreen()
                setFullScreenMode(true)
            }
        }
    }

    /**
     * Middleware method before trigger search to hide filter panel
     * @param filterDate
     */
    const filterCHangeHandler = (filterDate) => {
        setView('')
        handleFilterChange(filterDate)
    }

    return (
        <>
            <ToggleButtonGroup
                orientation={'vertical'}
                value={view}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton value={'layer'} aria-label={'layer'}>
                    <LayersIcon />
                </ToggleButton>
                <ToggleButton value={'filter'} aria-label={'filer'}>
                    <TuneIcon />
                </ToggleButton>
                <ToggleButton value={'fullscreen'} aria-label={'fullscreen'}>
                    {fullscreenMode ? <FullscreenExit /> : <FullscreenIcon />}
                </ToggleButton>
            </ToggleButtonGroup>

            {view === 'layer' && <LayerPanel handleLayerChange={handleLayerChange} settings={settings} />}
            {view === 'filter' && <FilterPanel handleFilterChange={filterCHangeHandler} defaultFilterDate={filterDate} />}
            {/*TO DO*/}
            {/*Add more events*/}
            {/*Add More Panel, e.g Filter Panel etc*/}
        </>

    )
}

const LayerPanel = ({ handleLayerChange, settings }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const handleChange = (event: { target: { name: any; checked: any } }) => {
        const layer = event.target.name
        const value = event.target.checked

        handleLayerChange(layer, value)
    }

    return (
        <Box sx={{ ...mapPanel, background: theme.palette.primary.main }}>
            <FormControl sx={{ m: 0, border: 0 }} component={'fieldset'} variant={'standard'}>
                <FormGroup>
                    <FormControlLabel sx={{ mt: 0 }}
                        control={<Checkbox checked={settings.showLiveLocation} onChange={handleChange} name={'showLiveLocation'} />}
                        label={t('modules.map.layer.location')}
                    />
                    <FormControlLabel sx={{ mt: 0 }}
                        control={<Checkbox checked={settings.showRoutePath} onChange={handleChange} name={'showRoutePath'} />}
                        label={t('modules.map.layer.route')}
                    />
                </FormGroup>
            </FormControl>
        </Box>
    )
}

const FilterPanel = ({ handleFilterChange, defaultFilterDate }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const defaultDate = new Date()
    defaultDate.setHours(0, 0, 1, 0)

    const [filterData, setFilterDate] = useState({
        startDate: defaultFilterDate?.startDate || defaultDate,
        endDate: defaultFilterDate?.endDate || defaultDate
    })

    const setDateValue = (val, field) => {
        setFilterDate({ ...filterData, [field]: val })
    }

    return (
        <Box sx={{ ...mapPanel, background: theme.palette.primary.main }}>
            <Box sx={{
                display: 'grid',
                rowGap: 5,
                '& .MuiInputBase-input': { padding: '.5rem 0 0 0' },
                '& .MuiFormControl-root': { height: '2rem' },
                '& .MuiInputBase-root': { padding: '.5rem', margin: 0 }
            }}>
                <MapDateFilter handleDateChange={(val, ev) => setDateValue(val, 'startDate')}
                    label={t('modules.map.filter.startDate')}
                    defaultValue={filterData.startDate} />
                <MapDateFilter handleDateChange={(val, ev) => setDateValue(val, 'endDate')}
                    label={t('modules.map.filter.endDate')}
                    defaultValue={filterData.endDate} />
                <Button variant={'contained'} onClick={() => { handleFilterChange(filterData) }}
                    sx={{
                        mt: 5,
                        border: 1,
                        borderColor: theme.palette.custom.defaultText
                    }}>{t('modules.map.filter.search')}</Button>
            </Box>
        </Box>
    )
}

export default MapControlPanel
