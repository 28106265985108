import { HTTPMethod } from '../HttpType'
import { requestEndpoint } from '../HTTPUtils'
import useFetch from './useFetch'

const GROUP_ENDPOINT = '/groups'

export function fetchGroups() {
    return useFetch<any, any[]>(GROUP_ENDPOINT)
}

export async function fetchAllGroups(data?: any, controller?: AbortController) {
    const endpoint = GROUP_ENDPOINT
    return await requestEndpoint<any, any[]>(endpoint, data, HTTPMethod.GET, controller)
}

export async function getGroupById(id: any, controller?: AbortController) {
    const endpoint = `${GROUP_ENDPOINT}/${id}`
    return await requestEndpoint<any, any>(endpoint, id, HTTPMethod.GET, controller)
}

export async function createGroup(data: any, controller?: AbortController) {
    const endpoint = GROUP_ENDPOINT
    const objectifiedData = { group: { ...data, id: undefined } }

    return await requestEndpoint<any, any>(endpoint, objectifiedData, HTTPMethod.POST, controller)
}

export async function updateGroup(data: any, controller?: AbortController) {
    const endpoint = `${GROUP_ENDPOINT}/${data.id}`
    const objectifiedData = { group: { ...data, id: undefined } }

    return await requestEndpoint<any, any>(endpoint, objectifiedData, HTTPMethod.PUT, controller)
}

export async function deleteGroup(id: string, controller?: AbortController) {
    const endpoint = `${GROUP_ENDPOINT}/${id}`
    return await requestEndpoint<any, any>(endpoint, id, HTTPMethod.DELETE, controller)
}

const GroupApiService = {
    fetchGroups,
    getGroupById,
    fetchAllGroups,
    createGroup,
    updateGroup,
    deleteGroup,
}

export default GroupApiService
