#ntc-asset-iframe {
    width: 100%;
    height: 1300px;
    border: 40px solid red;
    border-radius: 10px;
}

.remote-control-asset-name {
    padding: 10px;
    border-radius: 10px;
    background-color: red;
}