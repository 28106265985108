import * as React from 'react'
import { useContext, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { FormLabel, IconButton, InputAdornment, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { useForm } from 'react-hook-form'
import './Profile.css'
import { useTranslation } from 'react-i18next'
import { textBoxFocusedControl } from '../../styles/GlobalSXProps'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { PageContext } from '../../Context'
import NTCButton from '../inputs/buttons/NTCButton'
import { NON_STIG_PASSWORD_REGEX, STIG_PASSWORD_REGEX } from 'constants/Password'
import { PasswordChecklist } from '../user/PasswordChecklist'
import UserApiService from 'utils/UserHttpUtils'
import { handleException } from 'utils/HTTPUtils'

type FieldName = 'existingPassword' | 'password' | 'passwordConfirmation';

interface Field {
    name: FieldName;
    label: string;
    required: string;
    validation?: Record<string, any>;
    showCustomError?: boolean
}

const Security = ({ showResponseStatus }) => {

    const { t } = useTranslation()

    const fields: Field[] = [
        {
            name: 'existingPassword',
            label: t(`forms.user.field.existingPassword.label`),
            required: t(`forms.user.field.existingPassword.required`),
            validation: {}
        },
        {
            name: 'password',
            label: t(`forms.user.field.password.labelNew`),
            required: t(`forms.user.field.password.validation.required`),
            validation: {
                passwordRule: (fieldValue) => {
                    const regex = new RegExp(NON_STIG_PASSWORD_REGEX)
                    return regex.test(fieldValue) || t('forms.user.field.password.validation.stigPatternMessage')
                }
            },
            showCustomError: true
        },
        {
            name: 'passwordConfirmation',
            label: t(`forms.user.field.confirmPassword.label`),
            required: t(`forms.user.field.confirmPassword.validation.required`),
            validation: {
                notMatch: (value) => {
                    return value === getValues('password') || t(`forms.user.field.confirmPassword.validation.match`)
                }
            }
        }
    ]
    const {
        handleSubmit, getValues, reset, watch, trigger,
        register, formState: { errors }
    } = useForm({
        mode: 'all',
        defaultValues: {
            existingPassword: '',
            password: '',
            passwordConfirmation: ''
        }
    })

    React.useEffect(() => {
        trigger()
    }, [])

    const theme = useTheme()
    const { setLoadingPanel } = useContext(PageContext)
    const [showPasswords, setShowPasswords] = useState<Record<FieldName, boolean>>({
        existingPassword: false,
        password: false,
        passwordConfirmation: false
    })

    const handleClickShowPassword = (field: FieldName) => {
        setShowPasswords(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }))
    }

    const handleMouseDownPassword = (event: React.MouseEvent) => {
        event.preventDefault()
    }

    const passwordChange = async (data) => {
        setLoadingPanel(true)
        const payload = {
            user: {
                current_password: data.existingPassword,
                password: data.password,
                password_confirmation: data.passwordConfirmation
            }
        }

        try {
            await UserApiService.changePassword(payload)
            showResponseStatus({
                success: true,
                entity: 'User',
                message: t(`forms.user.field.password.updateMessage`)
            })
            reset()

        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'User', message: errorMessage })   
        } finally {
            setLoadingPanel(false)
        }
    }
    const watchPassword = watch('password', '')
    const watchConfirmPassword = watch('passwordConfirmation', '')

    return (
        <>
            <Accordion>
                <AccordionSummary sx={{ backgroundColor: theme.palette.custom.appbarHeader }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content" id="panel2bh-header">
                    <FormControl>
                        <FormLabel sx={{
                            fontWeight: 'bold',
                            color: theme.palette.custom.defaultText
                        }}>{t('forms.user.field.password.security')}</FormLabel>
                    </FormControl>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                    </Grid>
                    <FormControl>
                        <FormLabel sx={{
                            fontFamily: 'Montserrat', color: theme.palette.custom.defaultText, marginLeft: '0.3rem',
                            marginTop: '1rem'
                        }}>{t('forms.user.field.password.passwordManagement')}</FormLabel>
                    </FormControl>
                    <Grid container sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} spacing={2}>
                        {fields.map((field, index) => (
                            <Grid item xs={12} sm={4} key={field.name + index}>
                                <TextField
                                    type={showPasswords[field.name] ? 'text' : 'password'}
                                    {...register(field.name, {
                                        required: field.required,
                                        validate: field.validation
                                    })}
                                    error={!!errors[field.name as 'existingPassword' | 'password' | 'passwordConfirmation']}
                                    helperText={field.showCustomError ? '' : errors[field.name as 'existingPassword' | 'password' | 'passwordConfirmation']?.message as string}
                                    sx={{
                                        ...textBoxFocusedControl(theme, !!errors[field.name]),
                                        width: '100%',
                                        color: theme.palette.custom.defaultText
                                    }}
                                    size="small"
                                    id={field.name}
                                    label={field.label}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleClickShowPassword(field.name)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswords[field.name] ? <VisibilityOff /> :
                                                        <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Grid item marginTop={'10px'}>
                            <PasswordChecklist password={watchPassword} stigEnable={false} />
                        </Grid>
                        <Grid marginTop={'10px'}>
                            <NTCButton onClick={handleSubmit(passwordChange)} text={t('common.actions.changePassword')}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default Security
