import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Divider, Grid, useTheme } from '@mui/material'
import FormTextControl from '../inputs/forms/controls/FormTextControl'
import NTCButton from '../inputs/buttons/NTCButton'
import BaseModalDialog from '../display/modals/BaseModalDialog'
import { FormMode } from 'components/inputs/forms/Forms'
import { useTranslation } from 'react-i18next'
import { ClientContext, PageContext } from '../../Context'
import { handleException } from 'utils/HTTPUtils'
import TrainingApiService from 'utils/HTTP/TrainingUtils'
import { Training } from 'interface/Training'
import { FormSelectControl } from 'components/inputs/forms/controls/FormSelectControl'

export interface TrainingFormProps {
    showResponseStatus?: any
    handleCloseModal?: any
    trainingId?: any,
    mode: FormMode,
    actionCallback?: any,
    organizationId?: string
    page?: string
}

const TrainingForm = ({
    trainingId,
    mode,
    showResponseStatus,
    handleCloseModal,
    actionCallback = (v) => { },
    organizationId = '',
    page = 'training'
}: TrainingFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const { setLoadingPanel } = useContext(PageContext)
    const [sources, setSources] = useState([])

    const isOrg = page === 'organization'
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const defaultValues = { id: '', name: '', description: '', source_id: '', repeat_count: 1, org_id: organizationId || client['org'].id }

    const [training, setTraining] = useState<Training>(defaultValues)

    const validation = Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('forms.training.field.name.validation.required')),
        description: Yup.string().optional(),
        repeat_count: Yup.number()
            .typeError(t('forms.training.field.repeat_count.validation.required'))
            .required(t('forms.training.field.repeat_count.validation.required'))
            .min(1, t('forms.training.field.repeat_count.validation.min'))
            .max(100, t('forms.training.field.repeat_count.validation.max')),
        source_id: Yup.string().required(t('forms.training.field.source_id.validation.required')),
    })

    const {
        register,
        control,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validation),
        defaultValues: training,
        mode: 'all'
    })

    useEffect(() => {
        // TODO, Get source list once api is ready e.g wizard list
        // Mock the list
        const data = [
            { id: '15b8a138-b097-42ef-84fc-e2ff41544d15', name: 'Demo Wizard 1' },
            { id: '57427e98-6457-4ef8-b4c5-933c00541bc5', name: 'Demo Wizard 2' }
        ]
        setSources(data)
    }, [])

    useEffect(() => {
        if (!trainingId) {
            trigger()
            return
        }

        TrainingApiService.getTrainingById(trainingId).then((res) => {
            setValue('id', res.id)
            setValue('description', res.description)
            setValue('name', res.name)
            setValue('source_id', res.source_id)
            setValue('repeat_count', res.repeat_count)
            setTraining(res)
        }).catch(ex => {
            showResponseStatus({ success: false, entity: 'Training', message: ex.message })
        })
    }, [trainingId])

    const prepareItemList = (options) => {
        return options.map(({ id, name }) => ({ value: id, label: `${name}` }))
    }

    const onSubmit = async (data: any) => {
        setLoadingPanel(true)

        data.org_id = organizationId || client['org']?.id
        if (mode !== FormMode.EDIT) {
            delete data.id
        }

        const apiFunction = ([FormMode.CREATE, FormMode.CLONE].includes(mode)) ?
            TrainingApiService.createTraining : TrainingApiService  .updateTraining

        let res = null

        try {
            res = await apiFunction(data)
            setTraining(res)
            showResponseStatus({ success: true, entity: 'Training', message: t(`common.notifications.${mode}`, { name: t('forms.training.title') }) })
        } catch (ex) {
            const errorMessage = await handleException(ex)
            showResponseStatus({ success: false, entity: 'Training', message: errorMessage })   
        } finally {
            if (isOrg) {
                actionCallback({ id: trainingId || res?.id })
                handleCloseModal()
            } else {
                setOpenFormDialog(false)
                handleCloseModal()
            }
            setLoadingPanel(false)
        }
    }


    const getFormHeader = () => {
        return t(`common.actions.${mode}`) + ' ' + t(`forms.training.title`)
    }

    return (
        <BaseModalDialog
            modalTitle={getFormHeader()}
            maxWidth="lg"
            open={openFormDialog}
            handleClose={() => {
                setOpenFormDialog(false)
                handleCloseModal()
            }}
        >
            <Box px={3} py={2}>
                <Grid container spacing={3}>
                    <input type="hidden" id="id" name="id" value={training.id} {...register('id')} />
                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            id={'name'}
                            name={'name'}
                            defaultValue={training.name}
                            label={t('forms.training.field.name.label')}
                            required={true}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            id={'description'}
                            name={'description'}
                            defaultValue={training.description}
                            label={t('forms.training.field.description.label')}
                            required={false}
                            {...{ register, errors }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormSelectControl id={'source_id'}
                            name={'source_id'}
                            options={prepareItemList(sources)}
                            defaultValue={training.source_id}
                            label={t('forms.training.field.source_id.label')}
                            defaultOptionLabel={t('forms.training.field.source_id.dropdownLabel')}
                            handleChange={(val) => { setValue('source_id', val) }}
                            {...{ control, errors }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextControl
                            type={'number'}
                            id={'repeat_count'}
                            name={'repeat_count'}
                            label={t('forms.training.field.repeat_count.label')}
                            required={true}
                            {...{ register, errors }}
                        />
                    </Grid>


                    <Grid container spacing={3} justifyContent="right" sx={{ pt: 3 }}>
                        <Divider />
                        <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <NTCButton
                                text={t('common.actions.cancel')}
                                onClick={() => {
                                    setOpenFormDialog(false)
                                    handleCloseModal()
                                }} />
                            <NTCButton
                                text={t('common.actions.save')}
                                onClick={handleSubmit(onSubmit)}
                                backgroundColor={theme.palette.custom.nomadRed} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </BaseModalDialog>)
}

export default TrainingForm