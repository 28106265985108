import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BaseModalDialog from 'components/display/modals/BaseModalDialog'
import FormTextControl from 'components/inputs/forms/controls/FormTextControl'
import FormCheckBoxControl from 'components/inputs/forms/controls/FormCheckboxControl'
import NTCButton from 'components/inputs/buttons/NTCButton'
import { FormMode } from 'components/inputs/forms/Forms'
import OrganizationApiService from 'utils/HTTP/OrganizationUtils'
import { PageContext } from 'Context'

export function groupValidationSchema(t) {
    return {
        name: Yup.string().required(t('forms.organization.field.name.validation.required')),
    }
}

export const OrganizationForm = ({ organizationId, mode, showResponseStatus, handleCancel }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const defaultValues = { name: '', remote_control: false }
    const [organization, setOrganization] = useState(defaultValues)
    const { setLoadingPanel } = useContext(PageContext)
    const { setPageEntity } = useContext(PageContext)

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('forms.organization.field.name.validation.required')),
    })
    const {
        register,
        control,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors, isValid, isDirty }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues,
        mode: 'all'
    })

    useEffect(() => {
        if (!organizationId) {
            trigger()
            return
        } else {
            getOrganization(organizationId)
        }
    }, [organizationId, mode])

    const getOrganization = (id: any) => {
        setLoadingPanel(true)
        OrganizationApiService.getOrganizationById(id).then(res => {
            setValue('name', res.name)
            setValue('remote_control', res.remote_control)
            setOrganization(res)
            setPageEntity(res)
        }).catch(ex => {
            showResponseStatus({ success: true, entity: 'Organization', message: ex.message })
        }).finally(() => {
            setLoadingPanel(false)
        })
    }

    const onSubmit = async (data) => {
        try {
            mode === FormMode.EDIT ?
                await OrganizationApiService.updateOrganization({ name: data.name, remote_control: data.remote_control, id: organizationId }) :
                await OrganizationApiService.createOrganization({ name: data.name })
            showResponseStatus({ success: true, entity: 'Organization', message: t(`common.notifications.${mode}`, { name: t('forms.organization.title') }) })
        } catch (ex) {
            showResponseStatus({ success: false, entity: 'Organization', message: ex.message })
        } finally {
            handleCancel()
        }
    }

    return (
        <Box px={3} py={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormTextControl id={'name'} name={'name'} defaultValue={organization.name}
                        label={t('forms.organization.field.name.label')}{...{ register, errors }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} key={'remote_control'}>
                    <FormCheckBoxControl
                        id={'remote_control'}
                        name={'remote_control'}
                        defaultValue={!!organization.remote_control}
                        label={t(`forms.asset.field.allow_remote_control.label`)}
                        {...{ control, errors }}
                    />
                </Grid>
                <Grid item xs={12} gap={2} sx={{ mt: mode === FormMode.EDIT ? 0 : 2 }} justifyContent={'end'} display={'flex'}>
                    <NTCButton text={t('common.actions.cancel')} onClick={handleCancel} />
                    <NTCButton disabled={(!isValid || !isDirty)}
                        backgroundColor={isDirty ? theme.palette.custom.nomadRed : theme.palette.custom.contrastButton}
                        text={t('common.actions.save')}
                        onClick={handleSubmit(onSubmit)} />
                </Grid>
            </Grid>
        </Box>
    )
}

export const OrganizationFormDialog = ({
    organizationId,
    mode,
    organizations,
    showResponseStatus,
    handleCloseModal
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const getFormHeader = () => {
        return t(`common.actions.${mode}`) + ' ' + t(`forms.organization.title`)
    }
    const handleCancel = () => {
        setOpenFormDialog(false)
        handleCloseModal()
    }

    return (
        <BaseModalDialog
            modalTitle={getFormHeader()} maxWidth="lg" open={openFormDialog}
            handleClose={handleCancel}>
            <OrganizationForm {...{ organizationId, mode, organizations, showResponseStatus, handleCancel }} />
        </BaseModalDialog>
    )
}