// Define the TypeScript module
export namespace HardwareSystems {
    export const ELECTRICAL: string = 'Electrical';
    export const ENVIRONMENTAL: string = 'Environmental';
    export const MECHANICAL: string = 'Mechanical';
    export const SENSOR: string = 'Sensor';
    export const INTERLOCK: string = 'Interlock';

    export const CATEGORIES = [
        ELECTRICAL,
        ENVIRONMENTAL,
        MECHANICAL,
        SENSOR,
        INTERLOCK
    ]

    export const SYSTEMS_BY_CATEGORY: { [category: string]: string[] | Set<string> } = {
        [ELECTRICAL]: [
            "battery_monitor",
            "generator",
            "light",
            "neverdown",
            "power_distribution",
            "power_source",
            "rack_control",
            "ups"
        ],
        [ENVIRONMENTAL]: [
            "hvac",
            "rack_temperature",
            "weather_station"
        ],
        [MECHANICAL]: [
            "awning",
            "door_lock",
            "floor",
            "leveling",
            "light_tower",
            "mast",
            "mechanical_information",
            "outrigger",
            "privacy_glass",
            "slideout",
            "vsat"
        ],
        [SENSOR]: [
            "sensor"
        ],
        [INTERLOCK]: [
            "interlock"
        ]
    };

    // Transpose the system lookups for ease of use in certain situations
    export const CATEGORIES_BY_SYSTEM: { [system: string]: string } = {};
    for (const category in SYSTEMS_BY_CATEGORY) {
        const systems = SYSTEMS_BY_CATEGORY[category];
        if (Array.isArray(systems)) {
            // Iterate over each system in the array
            systems.forEach((system: string) => {
                // Assign the category as the value for the system key in the new hash
                CATEGORIES_BY_SYSTEM[system] = category;
            });
        } else if (systems instanceof Set) {
            // Iterate over each system in the set
            systems.forEach((system: string) => {
                // Assign the category as the value for the system key in the new hash
                CATEGORIES_BY_SYSTEM[system] = category;
            });
        }
    }
}
