import React, { Fragment } from 'react'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'

interface FormCheckBoxControlProps {
    control?: any,
    errors?: any,
    id: string,
    name: string,
    label: string,
    defaultValue?: boolean,
    readonly?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize="medium"/>
const checkedIcon = <CheckBoxIcon fontSize="medium"/>
const FormCheckBoxControl = ({
    control,
    errors,
    name,
    id,
    label,
    defaultValue,
    readonly
}: FormCheckBoxControlProps) => {
    const theme = useTheme()
    const isError = errors[name] ? true : false
    const color = isError ? theme.palette.custom.nomadRed : `${theme.palette.custom.moduleTitle} !important`

    return (
        <Fragment>
            <FormControlLabel
                control={
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={defaultValue}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox
                                disabled={readonly}
                                checked={value}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        )}
                    />
                }
                sx={{
                    color: theme.palette.custom.nomadRed,
                    '& .Mui-checked': { color: `${theme.palette.custom.nomadRed} !important` }
                }}
                label={
                    <Typography color={color}>
                        {label}
                    </Typography>
                }
            />
        </Fragment>
    )
}

export default FormCheckBoxControl