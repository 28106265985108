import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Theme, Typography, useTheme } from '@mui/material'
import { LineLayer, MapProvider, MapRef } from 'react-map-gl'
import { Feature, FeatureCollection } from 'geojson'

import bbox from '@turf/bbox' // https://docs.mapbox.com/help/glossary/turf/

import LiveLocationLayer, { generateLiveLocations } from './layers/LiveLocationLayer'
import RoutePathLayer, { generateLineLayerBody } from './layers/RoutePathLayer'
import MapControlPanel from './MapControlPanel'
import BaseMapLayer from './BaseMapLayer'

import { AssetTrackerConfig } from '../UiModuleConfig'
import { AssetApiService } from 'utils/HTTP/AssetUtils'
import { DefaultMap } from 'constants/Map'
import { getDatePart } from './utils'

export const AssetMapControl = ({ id, config, dateRange, setDateRange }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const mapRef = useRef<MapRef>()

    const [assetConfig, setAssetConfig] = useState<AssetTrackerConfig>(config)
    const [assetRoutes, setAssetRoutes] = useState<{ layer: LineLayer, geoJson: FeatureCollection }[]>([])
    const [assetLiveLocations, setAssetLiveLocations] = useState<FeatureCollection>({ type: 'FeatureCollection', features: [] })

    const handleLayerChange = (layer: string, value: any) => {
        setAssetConfig(prevData => ({
            ...prevData,
            layerSettings: {
                ...prevData.layerSettings, [layer]: value,
            },
        }))
    }

    async function getAssetAndLocationData(dateRange: { startDate: any, endDate: any }) {
        // Get all assets
        const assets = await AssetApiService.fetchAllAssets()

        // Get asset key map with locations data
        const assetLocationsKeyMap = await AssetApiService.getAssetLocations(
            dateRange?.startDate?.toString(),
            dateRange.endDate?.toString()
        )

        // Create new array to merge asset and locations data
        const assetLocations = assets.map((asset) => {
            const locations = assetLocationsKeyMap[asset.id]
            return locations?.length ? { ...asset, locations } : null
        })

        const assetsWithLocations = assetLocations.filter(Boolean)
        const linePaths = generateLineLayerBody(assetsWithLocations, theme)
        const liveLocations = generateLiveLocations(assetsWithLocations)
        setAssetRoutes(linePaths)
        setAssetLiveLocations(liveLocations)
    }

    useEffect(() => {
        getAssetAndLocationData(dateRange)
    }, [])

    useEffect(() => {
        const map = mapRef?.current

        if (!map || !assetRoutes.length) return

        // Calculate bounding box for all coordinates
        const bboxCoords = bbox({
            type: 'FeatureCollection',
            features: assetRoutes.flatMap(feature => feature.geoJson.features)
        })

        // Fit map to calculated bounds
        map.fitBounds([
            [bboxCoords[0], bboxCoords[1]], // Southwest corner [minLng, minLat]
            [bboxCoords[2], bboxCoords[3]]  // Northeast corner [maxLng, maxLat]
        ])

    }, [assetRoutes])

    const handleFilterChange = async (filterDate: any) => {
        const startDate = filterDate.startDate.toDate ? filterDate.startDate.toDate() : filterDate.startDate
        const endDate = filterDate.endDate.toDate ? filterDate.endDate.toDate() : filterDate.endDate
        const range = { startDate: getDatePart(startDate), endDate: getDatePart(endDate) }
        setDateRange(range)
        await getAssetAndLocationData(range)
    }

    return (
        <Box sx={{ '& .MuiSvgIcon-root': { fill: theme.palette.custom.nomadRed, width: '2rem', cursor: 'pointer' } }}>
            <MapProvider>
                <BaseMapLayer {...{
                    id, mapRef,
                    containerStyle: { height: config?.dimension?.height || DefaultMap.height, width: DefaultMap.width }
                }}>
                    {assetConfig?.layerSettings?.showLiveLocation &&
                        <LiveLocationLayer geoJson={assetLiveLocations} showMarkerPoup={true} />}

                    {assetConfig?.layerSettings?.showRoutePath &&
                        <RoutePathLayer mapLinePaths={assetRoutes} />}

                    <MapControlPanel
                        handleLayerChange={handleLayerChange}
                        handleFilterChange={handleFilterChange}
                        settings={assetConfig?.layerSettings}
                        filterDate={dateRange} />
                </BaseMapLayer>
            </MapProvider>
        </Box>
    )
}