import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import WeatherLayout from '../layout/WeatherLayout'
import { getDayMonthFromDate } from '../WeatherConstants'
import { useTranslation } from 'react-i18next'

const Detail = ({ data }) => {
    const { t } = useTranslation() 
    const dayMonth = getDayMonthFromDate()

    return (
        <WeatherLayout title={t('modules.weather.current.header')} >
            <DetailItem title={data?.location?.name} subtitle={`${t('modules.weather.current.today')} ${dayMonth}`} />
            <DetailItem title={`${data?.current?.temp_c} °C`} subtitle={data?.current?.condition.text} />

            <Grid item xs={4}>
                <Box component={'img'} src={data?.current?.condition.icon} width={60} />
            </Grid>
        </WeatherLayout >
    )
}

function DetailItem({ title, subtitle }) {
    return (
        <Grid item xs={4}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom sx={{
                    flexGrow: 1,
                    textTransform: 'upperCase',
                    fontSize: '1rem',
                }}>
                    {title || 'N/A'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {subtitle || 'N/A'}
                </Typography>
            </Box>
        </Grid>
    )
}

export default Detail