import * as React from 'react'
import { useTheme } from '@mui/material'
import { Fragment } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { styled, lighten, darken } from '@mui/system';

import Typography from '@mui/material/Typography'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FormMultiSelectGroupControlProps {
    control?: any,
    errors?: any,
    name: string,
    label: string,
    options: { label: string, value: string, groupBy: string, disabled?: boolean }[],
    readonly?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize={'medium'} />
const checkedIcon = <CheckBoxIcon fontSize={'medium'} />
const isOptionEqualToValue = (option, value) => option.value === value.value

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '8px 10px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    zIndex: '10',
    color: theme.palette.custom.defaultText,
    backgroundColor: theme.palette.custom.appbarHeader
}))

const GroupItems = styled('ul')({
    padding: 0,
});



const FormMultiSelectGroupControl = ({
    control,
    errors,
    name,
    label,
    options = [],
    readonly
}: FormMultiSelectGroupControlProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isError = errors[name] ? true : false
    const color = isError ? theme.palette.custom.nomadRed : `${theme.palette.custom.moduleTitle} !important`

    return (
        <Fragment>
            <Controller
                name={name}
                control={control}
                render={({ field }) =>
                    <Autocomplete
                        {...field}
                        options={options}
                        multiple
                        disabled={readonly}
                        disableCloseOnSelect
                        getOptionDisabled={(option) => option.disabled}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={isOptionEqualToValue}
                        groupBy={(option) => option.groupBy}
                        onChange={(event, value) => {
                            field.onChange(value)
                        }}
                        renderOption={(props, option, { selected }) => {
                            return <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    sx={{
                                        '&.Mui-checked': { color: `${theme.palette.custom.nomadRed} !important` }
                                    }}
                                />
                                {option.label}
                            </li>
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={label} placeholder={label} sx={{
                                '& .MuiFormLabel-root': { color: color },
                                '& fieldset': { borderColor: color }
                            }} />
                        )}
                        renderTags={(params) => (
                            <>
                                {params.length ? `${t('forms.role.field.permissions.selectedInfo', { count: params.length})}` : ''}
                            </>
                        )}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}

                    />
                }
            />
            <Typography variant="inherit" sx={
                { color: color }}>
                {errors[name]?.message}
            </Typography>
        </Fragment>
    )
}

export {
    FormMultiSelectGroupControl
}
