import React, { useContext, useState, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { SideMenuDrawerWidth } from 'constants/Layout'
import { ClientContext, ReauthenticationContext } from './Context'

import MasterLayout from 'components/layout/MasterLayout'
import portalMenuItems from 'components/layout/navbar/menu/portalMenuItems'
import { NotFoundPage, getRouteDefinitions } from 'routes/RouteDefinitions'

const AppRouter = () => {
    const [showReauth, setShowReauth] = useState(false)
    const { client } = useContext(ClientContext)

    const generateRouteElements = (routes: any) => {
        return routes.map((route: any) => {
            if (!route.authorize) {
                return null
            }

            const parentRoute = <Route key={route.path} path={route.path} element={<route.component />} />

            // If the route has children, render them recursively
            if (route.children && route.children.length > 0) {
                const childRoutesElements = generateRouteElements(route.children)
                return (
                    <Route key={route.path} path={route.path} element={<route.component />}>
                        {childRoutesElements}
                    </Route>
                )
            }

            return parentRoute
        })
    }
    
    return (
        <BrowserRouter>
            <ReauthenticationContext.Provider value={{ open: showReauth, setOpen: setShowReauth }}>
                <MasterLayout drawerWidth={SideMenuDrawerWidth} menuData={portalMenuItems}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            {generateRouteElements(getRouteDefinitions(client))}
                          
                            {/* Add more routes here OR in route definition */}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                </MasterLayout>
            </ReauthenticationContext.Provider>
        </BrowserRouter>
    )
}

export default AppRouter
