import React from 'react'
import Detail from './Detail'
import TodayAirConditions from './TodayAirConditions'
import { Box } from '@mui/material'
import WeatherSearchInput from './SearchInput'

const TodayWeather = ({ data }) => {
    return <Box mt={2}>
        <Detail data={data} />
        <TodayAirConditions data={data} />
    </Box>
}

export default TodayWeather