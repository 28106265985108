import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import BaseModalDialog from 'components/display/modals/BaseModalDialog'
import NTCButton from 'components/inputs/buttons/NTCButton'
import { Group } from 'generated/auth/Group'
import GroupApiService from 'utils/HTTP/GroupUtils'
import { Stack } from '@mui/system'
import { Chip, Divider } from '@mui/material'
import { t } from 'i18next'

const DetailDivider = ({ headerLabel }) => {
    const { t } = useTranslation()
    return <Grid item xs={12} sm={12} mt={1}>
        <Divider textAlign='left'>{t(headerLabel)}</Divider>
    </Grid>
}

const GroupAssociatedItem = ({ items, itemLabelKey }) => {
    return (
        <Grid item xs={12} sm={12}>
            <Stack direction='row' spacing={1}>
                {items?.map(item => <Chip key={item.id} label={item[itemLabelKey]} />)}
                {!items?.length && (<Typography>{t('common.message.noRecord')}</Typography>)}
            </Stack>
        </Grid>
    )
}

const ViewGroupDetails = ({ groupId, mode, showResponseStatus, handleCloseModal }) => {
    const { t } = useTranslation()
    const [openFormDialog, setOpenFormDialog] = useState(true)
    const [group, setGroup] = useState<Group>(null)

    useEffect(() => {
        GroupApiService.getGroupById(groupId)
            .then(setGroup)
            .catch(ex => {
                showResponseStatus({ success: false, entity: 'Group', message: ex.message })
            })

    }, [groupId, mode])

    const handleCancel = () => {
        setOpenFormDialog(false)
        handleCloseModal()
    }

    return (
        <BaseModalDialog
            modalTitle={t(`common.actions.${mode}`) + ' ' + t(`forms.group.title`)} maxWidth='sm'
            open={openFormDialog}
            handleClose={() => handleCancel()}>

            <Box px={1} py={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6' gutterBottom>{group?.name}</Typography>
                    </Grid>

                    <DetailDivider headerLabel={t('forms.group.field.users.label')} />
                    <GroupAssociatedItem items={group?.users} itemLabelKey={'name'} />

                    <DetailDivider headerLabel={t('forms.group.field.ntc_assets.label')} />
                    <GroupAssociatedItem items={group?.ntc_assets} itemLabelKey={'name'} />

                    <DetailDivider headerLabel={t('forms.group.field.children.label')} />
                    <GroupAssociatedItem items={group?.children} itemLabelKey={'name'} />

                    <Grid item xs={12} gap={2} sx={{ mt: 2 }} justifyContent={'end'} display={'flex'}>
                        <NTCButton text={t('common.actions.ok')} onClick={handleCancel} />
                    </Grid>
                </Grid>
            </Box>
        </BaseModalDialog>
    )
}

export default ViewGroupDetails