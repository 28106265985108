import React, { useEffect, useState } from 'react'
import './App.css'
import './i18n/i18n'
import { useSelector } from 'react-redux'
import AppRouter from './AppRouter'
import { CssBaseline } from '@mui/material'
import LoginVerifier from './LoginVerifier'
import { NTCThemeProvider } from './components/display/NTCThemeProvider'
import { darkTheme, lightTheme } from './styles/Themes'

function App() {
    const themeTracker = useSelector((state) => state['themeTracker'].value)
    return (
        <NTCThemeProvider theme={themeTracker === 'lightTheme' ? lightTheme : darkTheme}>
            <CssBaseline />
            <LoginVerifier>
                <AppRouter />
            </LoginVerifier>
        </NTCThemeProvider>
    )
}

export default App
