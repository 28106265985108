export enum HTTPMethod {
    CONNECT = 'CONNECT',
    DELETE = 'DELETE',
    GET = 'GET',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT',
    TRACE = 'TRACE'
}

export enum HTTPContentType {
    APPLICATION_JSON = 'application/json'
}

export interface HttpOptionProps {
    method: HTTPMethod,
    type?: HTTPContentType
}