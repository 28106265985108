import React from 'react'
import { useTranslation } from 'react-i18next'

import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import NTCButton from 'components/inputs/buttons/NTCButton'
import AddIcon from '@mui/icons-material/Add'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ViewListIcon from '@mui/icons-material/ViewList'

import { flexJustifySpaceBetween } from 'styles/GlobalSXProps'
import { CREATE, GROUP, isAuthorized } from 'generated/auth/Permissions'
import SearchBoxControl from 'components/inputs/forms/controls/SearchControl'
import { FormMode } from 'components/inputs/forms/Forms'

function ToggleDataView({ handleViewChange }) {
    const [viewMode, setViewMode] = React.useState<string | null>('list')

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string | null
    ) => {
        setViewMode(newMode)
        handleViewChange(newMode)
    }

    return (
        <ToggleButtonGroup
            sx={{ mr: 2, ml: 2 }}
            value={viewMode}
            exclusive
            onChange={handleAlignment}
            aria-label="Group View"
        >
            <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="tree" aria-label="tree view">
                <AccountTreeIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

/**
 * GroupToolbar component renders a toolbar for managing groups.
 * It includes a search input and an "Add Group" button if the user has permission.
 * 
 */

function GroupToolbar({ handleClick, keyword, setKeyword, handleViewChange, viewMode }) {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <Box sx={{ ...flexJustifySpaceBetween, mb: 2 }}>
            <Box> {viewMode === 'list' ? <SearchBoxControl {...{ keyword, setKeyword }} /> : <></>} </Box>
            
            <Box>
                {isAuthorized(GROUP, CREATE) && (
                    <NTCButton
                        text={`${t('common.actions.add')} ${t('forms.group.title')}`}
                        backgroundColor={theme.palette.custom.nomadRed}
                        onClick={() => handleClick(FormMode.CREATE)}
                        startIcon={<AddIcon sx={{ color: theme.palette.custom.whiteText }} />}
                    />
                )}
                <ToggleDataView handleViewChange={handleViewChange} />
            </Box>
        </Box>
    )
}

export default GroupToolbar
