import { string } from 'yup'
import { Translation } from '../Translation'
import { MAXIMUM_PASSWORD_LENGTH, NON_STIG_MINIMUM_PASSWORD_LENGTH, PASSWORD_HISTORY_SIZE, SPECIAL_CHARS, STIG_MINIMUM_PASSWORD_LENGTH } from 'constants/Password'

export let english: Translation = {
    // Put all translations in 'Title Case' and in Alphabetical order across every file
    translation: {
        errors: {
            general: 'Error',
            404: 'Resource not found',
            noData: 'No data available for this asset'
        },
        reauthenticate: {
            modalTitle: 'Reauthenticate for elevated privileges',
            success: 'Successfully reauthenticated',
            info: 'You will have elevated privileges for 30 minutes'
        },
        logoff: {
            message: 'User has been logged out and session has been terminated'
        },
        buttons: {
        },
        common: {
            name: 'Name',
            system: 'System',
            type: 'Type',
            subtype: 'Sub-type',
            room: 'Room {{room}}',
            start: 'Start',
            stop: 'Stop',
            starting: 'Start',
            stopping: 'Stop',
            status: 'Status',
            temperature: 'Temperature',
            temp: 'Temp.',
            volts: 'Volts',
            stopAll: 'Stop',
            top: 'Top',
            bottom: 'Bottom',
            on: 'On',
            off: 'Off',
            cancel: 'Cancel',
            clear: 'Clear',
            never: 'Never',
            actions: {
                label: 'Actions',
                save: 'Save',
                create: 'Create',
                delete: 'Delete',
                cancel: 'Cancel',
                assign: 'Assign Role',
                add: 'Add',
                submit: 'Submit',
                reset: 'Reset',
                ok: 'Ok',
                forcePassword: 'Update Password',
                addAnother: 'Add Another',
                upload: 'Upload',
                uploadAnother: 'Upload Photo/Video',
                login: 'Login',
                saveAndContinue: 'Save & Continue',
                saveAndExit: 'Save & Exit',
                view: 'View',
                edit: 'Edit',
                clone: 'Duplicate',
                next: 'Next',
                back: 'Back',
                return: 'Return',
                lock: 'Lock Account',
                unlock: 'Unlock Account',
                changePassword: 'Change Password',
                generatePassword: 'Generate Password',
                pair: 'Pair',
                keypair: 'Show KeyPair',
                forgotPassword: 'Forgot Password',
                resetPasswordLink: 'Send Reset Link',
                confirm_account: 'Re-send confirmation email',
                user_confirmed: 'User confirmed',
                impersonate: 'Impersonate',
                hierarchy: 'Hierarchy',
                duplicate: 'Duplicate',
                assignment: 'Assignment',
                transfer: 'Transfer',
            },
            notifications: {
                edit: '{{name}} updated successfully',
                delete: '{{name}} deleted successfully',
                create: '{{name}} created successfully',
                clone: '{{name}} copied successfully',
                transfer: '{{name}} transfered successfully'
            },
            message: {
                noRecord: 'No record found.'
            }
        },
        contact: {
            phone: 'Phone',
            email: 'Email',
            mailingAddress: 'Mailing Address',
            headQuarters: 'Headquarters',
            linkText: 'Support'
        },
        dropDowns: {
            moduleHeader: {
                close: 'Close',
                collapse: 'Collapse',
                addToDashboard: 'Add to Dashboard',
                graphView: 'Graph View',
                expand: 'Expand',
                lock: 'Lock',
                settings: 'Settings',
                sliderType: 'Slider Type'
            }
        },
        forms: {
            hidden: '(hidden)',
            header: {
                label: { list: 'List' }
            },
            permissions: {
                field: {
                    resource: { label: 'Resource', validation: { required: '' } },
                    action: { label: 'Action', validation: { required: '' } },
                    description: { label: 'Description', validation: { required: '' } }
                }
            },
            role: {
                title: 'Role',
                modal: {
                    confirm: {
                        delete: {
                            message: 'This will permanently delete this role and affect everyone assigned this role. Do you wish to proceed ?',
                            title: 'Delete Role'
                        }
                    },
                    view: {
                        header: {
                            roles: 'Roles',
                            modules: 'Assigned Modules and Actions',
                            actions: 'User Actions',
                            users: 'Individuals Currently Assigned to Roles'
                        }
                    }
                },
                field: {
                    name: {
                        label: 'Role Name',
                        validation: { required: 'Role name is required' }
                    },
                    level: {
                        label: 'Level',
                        validation: {
                            required: '',
                            optional: 'Role level must be a positive whole number'
                        },
                        notes: 'Note: A smaller value is expected for a \'Higher\' role, and only positive integers greater than zero are allowed'
                    },
                    description: {
                        label: 'Role Description',
                        validation: { required: 'Role description is required' }
                    },
                    permissions: {
                        label: 'Role Permissions',
                        validation: { required: 'Role permission(s) is required' },
                        selectedInfo: '{{count}} item(s) selected'
                    },
                    active: {
                        label: 'Active',
                        validation: { required: '' }
                    },
                    organizationId: {
                        label: 'Organization',
                        validation: { required: '' }
                    },
                    userId: {
                        label: 'User',
                        validation: { required: 'User is required' },
                        dropdownLabel: 'Select User'
                    },
                    roles: {
                        label: 'Roles',
                        validation: { required: 'Role(s) are required' }
                    }
                },
                name: {
                    mechanics: 'Mechanics',
                    drivers: 'Drivers',
                    operators: 'Operators',
                    administrators: 'Administrators',
                    viewer: 'Viewer',
                    owner: 'Owner'
                }
            },
            group: {
                title: 'Group',
                modal: {
                    confirm: {
                        delete: {
                            message: 'Are you sure to delete the selected item(s) ?',
                            title: 'Delete Group'
                        }
                    }
                },
                search: 'Search Group',
                basicInfo: 'Basic Info',
                field: {
                    name: { label: 'Group Name', validation: { required: 'Group name is required' } },
                    roles: { label: 'Roles', validation: {} },
                    parentId: { label: 'Parent Group', dropdownLabel: 'Select Parent Group', validation: {} },
                    users: { label: 'Users', validation: {} },
                    ntc_assets: { label: 'Assets', validation: {} },
                    children: { label: 'Sub-Groups', validation: {} }
                },
                name: {
                    roles: 'Group of Roles',
                    users: 'Group of Users'
                }
            },
            user: {
                title: 'User',
                modal: {
                    confirm: {
                        delete: {
                            message: 'Do you want to permanently delete this user: {{user.name}}? All data will be lost.',
                            title: 'Delete User'
                        },
                        lock: {
                            message: 'Do you want to temporarily lock this user\'s account: {{user.name}}?',
                            title: 'Lock Account'
                        },
                        unlock: {
                            message: 'Do you want to unlock this user\'s account: {{user.name}}?',
                            title: 'Unlock Account'
                        },
                        confirm_account: {
                            message: 'Do you want to resend the account confirmation email to: {{user.name}}?',
                            title: 'Resend Account Confirmation'
                        },
                        impersonate: {
                            message: 'Do you want to impersonate this user: {{user.name}}?',
                            title: 'Impersonate User'
                        }
                    }
                },
                preferences: {
                    weather: 'Weather Preferences',
                    news: 'News Preferences',
                },
                notifications: {
                    lock: 'User locked successfully!',
                    unlock: 'User unlocked successfully!',
                    delete: 'User deleted successfully!',
                    edit: 'User updated successfully!',
                    create: 'A confirmation email has been sent to {{email}}. The account will not be active until the user confirms their account via the link in that email',
                    roleUpdate: 'User roles updated successfully!',
                    confirm_account: 'Account confirmation email sent!',
                    impersonate: 'User impersonated successfully!',
                    impersonating: 'Impersonating'
                },
                field: {
                    firstName: { label: 'First Name', validation: { required: 'Firstname is required' } },
                    lastName: { label: 'Last Name', validation: { required: 'Lastname is required' } },
                    name: { label: 'Name', validation: { required: 'Name is required' } },
                    email: {
                        label: 'Email Address',
                        validation: {
                            required: 'Email is required',
                            email: 'Email is invalid'
                        },
                        accountConfirmed: 'Your email has been successfully confirmed. Thank you for verifying your account. You can now proceed to log in to your account and access all the features and services available to you.'
                    },
                    username: {
                        label: 'Username',
                        validation: {
                            required: 'Username is required',
                            min: 'Username must be at least 6 characters',
                            max: 'Username must not exceed 20 characters'
                        }
                    },
                    phone: { label: 'Phone', validation: { required: 'Phone number is required' } },
                    existingPassword: { label: 'Original Password', required: 'Original password is required' },
                    password: {
                        sentResetLink: 'Reset password email link sent',
                        sentResetEmailInfo: 'An email containing a link to reset your password has been sent. Please check your email inbox, including your spam or junk folder, to proceed with resetting your password',
                        resourceNotFound: 'Resource Not Found',
                        updateMessage: 'User password updated',
                        updateMessageInfo: 'Your password has been successfully reset. You can now log in using your new password. If you have any further questions or need assistance, please feel free to contact us. Thank you!',
                        failedMessage: 'Failed to update password. Please try again.',
                        security: 'Security',
                        stig: 'Enable STIG password requirements',
                        passwordManagement: 'Password Management',
                        label: 'Password',
                        labelNew: 'NewPassword',
                        accountLocked: 'Your account has been locked',
                        tooManyLoginAttempts: 'You have attempted 3 logins in 15 minutes',
                        forcePasswordTitle: 'Your password has expired. Please create a new one in order to be able to use the application',
                        validation: {
                            required: 'A password is required',
                            invalid: 'Invalid username or password',
                            min: `The password must contain at least ${NON_STIG_MINIMUM_PASSWORD_LENGTH} characters`,
                            stigMin: `The password must contain at least ${STIG_MINIMUM_PASSWORD_LENGTH} characters`,
                            upperCase: `At least one upper case letter [ A-Z ]`,
                            lowerCase: `At least one lower case letter [ a-z ]`,
                            specialChar: `At least one symbol [ ${SPECIAL_CHARS.split('').join(' ')} ]`,
                            number: `At least one number [ 0-9 ]`,
                            max: `The password must not exceed ${MAXIMUM_PASSWORD_LENGTH} characters`,
                            stigPatternMessage: 'The password must contain at least 15 characters, and at least one of the following: ' +
                                `a lowercase letter, an uppercase letter, a number, and a special character from ${SPECIAL_CHARS.split('').join(' ')} `,
                            nonStigPatternMessage: 'The password must contain at least 8 characters, and at least one of the following: ' +
                                `a lowercase letter, an uppercase letter, a number, and a special character from ${SPECIAL_CHARS.split('').join(' ')} `,
                            passwordReused: `The password can not match any of the last ${PASSWORD_HISTORY_SIZE} passwords used`,
                            password8CharsMustChange: 'At least 8 characters of the existing password must be changed',
                            passwordTooNew: 'You must wait at least 24 hours before changing your password',
                            passwordConfirmationMissing: 'The password confirmation was missing',
                            passwordConfirmationNoMatch: 'The password confirmation does not match the new password',
                            existingPasswordNoMatch: 'The existing password does not match the stored password'
                        }
                    },
                    confirmPassword: {
                        label: 'Confirm Password',
                        validation: {
                            required: 'Confirm Password is required',
                            match: 'Confirm Password does not match'
                        }
                    },
                    roles: { label: 'User Roles', validation: { required: 'Role(s) are required' } },
                    groups: { label: 'User Groups', validation: { required: 'Group(s) are required' } },
                    active: { label: 'Active', validation: { required: '' } },
                    company: { label: 'Company', validation: { required: 'Company name is required' } },
                    weather_country: { label: 'Country', validation: { required: '' }, helpText: 'Please enter the country name (e.g., United States of America, Mexico, Brazil, etc.).' },
                    weather_state: { label: 'State', validation: { required: '' }, helpText: 'Please enter the state name (for example, Montana, Texas, etc.).' },
                    weather_city: { label: 'City', validation: { required: '' }, helpText: 'Please enter the city name (for example, Missoula, Kalispell, etc.).' },
                    news_country: { label: 'Country', validation: { required: '' }, helpText: 'Please enter the country name (e.g., United States of America, Mexico, Brazil, etc.).', dropdownLabel: 'Choose a country' },
                    news_state: { label: 'State', validation: { required: '' }, helpText: 'Enter the name of the state (e.g., Montana, Texas, etc.).' },
                    news_city: { label: 'City', validation: { required: '' }, helpText: 'Enter the city name (e.g., Missoula, Kalispell, etc.).' },
                    news_keywords: { label: 'News Keyword(s)', validation: { required: '' }, helpText: 'Enter comma-separated keywords to filter specific types of news, e.g., sports, election, traffic, adventure.' }
                },
            },
            organization: {
                title: 'Organization',
                name: 'Organization',
                search: 'Search',
                modal: {
                    confirm: {
                        delete: { message: 'Are you sure you want to delete this item?', title: 'Delete Organization' }
                    },
                    association: {
                        user: { title: 'Add or Remove Users from Organization', label: 'Select/UnSelect Users' },
                        asset: { title: 'Add or Remove Assets from Organization', label: 'Select/UnSelect Assets' }
                    }
                },
                field: {
                    name: { label: 'Organization Name', validation: { required: 'Organization name is required' } },
                    typeName: { label: 'Organization Type', validation: {} },
                    users: { label: 'Users', validation: {} },
                    assets: { label: 'Assets', validation: {} }
                },
                buttons: {
                    linkUnlinkUser: 'Link/UnLink'
                }
            },
            asset: {
                title: 'Asset',
                search: 'Search Assets',
                warning: 'NomadGCS warns customers the Portal is under development and users accept all risks associated with remote control of their Connected Mobile Operations Centers',
                modal: {
                    confirm: {
                        delete: {
                            message: 'Are you sure you want to delete this item?',
                            title: 'Delete Asset'
                        }
                    }
                },
                field: {
                    name: { label: 'Asset name', validation: { required: 'Asset name is required' } },
                    description: { label: 'Description', validation: {} },
                    connected: { label: 'Currently online', validation: {} },
                    last_online: { label: 'Last online', validation: {} },
                    status: { label: 'Status', validation: {} },
                    remote_control: { label: 'Remote Control', validation: {} },
                    allow_remote_control: { label: 'Allow Remote Control', validation: {} },
                    key: { label: 'API Key' },
                    secret: { label: 'Secret' },
                    hardware_key: { label: 'Hardware ID' },
                    project_id: { label: 'Project ID' },
                    ssh_tunnel_port: { label: 'SSH Tunnel Port' },
                    show_starlink: { label: 'Show Starlink Attributes' },
                    serial_number: { label: 'Serial #' },
                    asset_id: { label: 'Asset ID' },
                    business_unit: { label: 'Business Unit' },
                    unit_number: { label: 'Unit #' },
                    customer_number: { label: 'Customer #' },
                    transfer_org: { label: 'Target Organization', dropdownLabel: 'Select Organization', validation: { required: 'Target organization is required' } },
                }
            },
            training: {
                title: 'Training',
                modal: {
                    confirm: {
                        delete: {
                            message: 'Are you sure you want to delete this item?',
                            title: 'Delete Training'
                        }
                    }
                },
                field: {
                    name: { label: 'Training Name', validation: { required: 'Training name is required' } },
                    description: { label: 'Description', validation: {} },
                    repeat_count: {
                        label: 'Repeat Count', validation:
                        {
                            required: 'Repeat count is required',
                            min: 'Repeat count must be a value greater than 0',
                            max: 'Repeat count should not exceed 100'
                        }
                    },
                    source_id: {
                        label: 'Training Source',
                        validation: { required: 'Source is required' },
                        dropdownLabel: 'Select Source'
                    }
                }
            }
        },
        lang: {
            en: { us: 'English (US)' },
            es: { mx: 'Spanish (Mexico)' }
        },
        menu: {
            settings: 'Settings',
            users: 'Users',
            roles: 'Roles',
            ntc_assets: 'Assets',
            groups: 'Groups',
            overview: 'Overview',
            organizations: 'Organizations',
            profileSettings: 'Profile Settings',
            languageUpdated: 'Language updated',
            themeUpdated: 'Theme updated',
            darkMode: 'Dark Mode',
            language: 'Language',
            login: 'Login',
            logout: 'Logout',
            lastLogin: 'Last login',
            firstLoginMessage: 'First login',
            trainings: 'Trainings'
        },
        modules: {
            dashboard: {
                name: 'Dashboard',
                roles: 'Roles',
                active: 'Active Modules',
                modal: { confirm: { delete: { message: 'Are you sure you want to delete this item?' } } },
                button: { saveLayout: 'Save page layout' },
                empty: 'Add modules to your dashboard and they will show up here.'
            },
            map: {
                filter: { startDate: 'Start Date', endDate: 'End Date', search: 'Search', allAsset: 'All Assets' },
                layer: {
                    location: 'Live Locations',
                    route: 'Route Path'
                },
                type: {
                    assetTracker: 'Asset Tracker Map'
                },
                marker: {
                    connected: 'Online',
                    notConnected: 'Offline',
                    stationary: 'Stationary since',
                    lastOnline: 'Last online',
                    latitude: 'Latitude',
                    longitude: 'Longitude'
                }
            },
            news: {
                name: 'News Feeds',
                showLess: 'Show Less',
                showMore: 'Show More'
            },
            weather: {
                name: 'Weather',
                search: 'Enter city name or zipcode',
                current: {
                    header: 'CURRENT WEATHER',
                    today: 'Today'
                },
                aircondition: {
                    header: 'AIR CONDITIONS',
                    realfeel: 'REAL FEEL',
                    wind: 'WIND',
                    clouds: 'CLOUDS',
                    humidity: 'HUMIDITY'
                }
            }
        },
        time: {
            day: 'day{{plural}}',
            hour: 'hour{{plural}}',
            minute: 'minute{{plural}}',
            second: 'second{{plural}}',
            week: 'week{{plural}}',
            ago: 'ago',
        },
    }
}
