export const AuthRoutes = {
    user: '/users/tokens/sign_in',
    refresh: '/users/tokens/refresh',
    exit: '/users/sign_out',
    resetPasswordLink: '/users/password',
    resetPassword: '/users/password',
    confirmAccount: '/users/confirm',
    checkAccountConfirmed: '/users/confirm_status'
}

export const NodeRoutes = {
    nodes: '/v1/nodes',
    node: (id) => `/v1/nodes/${id}`,
    pair: (id) => `/v1/nodes/${id}/pair`
}

export const RoleRoutes = {
    roles: '/roles',
    role: (id: any) => `/roles/${id}`,
    clients: (id: any) => `/roles/${id}/clients`,
    name: (id: any) => `/roles/${id}/name`,
    description: (id: any) => `/roles/${id}/description`,
    priority: (id: any) => `/roles/${id}/priority`,
    permissions: (id: any) => `/roles/${id}/permissions`
}

export const EnvRoutes = {
    env: (key) => `/v1/env?key=${key}`
}

export const MediaApiRoutes = {
    base: `/v1/media`
}

export const SYSTEM_WS_PATH = '/v1/stream/systems'
export const ALERT_WS_PATH = '/v1/stream/alerts' // FIXME: should be /v1/stream/alerts - notifications should be reserved
export const NOTIFICATION_WS_PATH = '/v1/stream/notifications'
export const REAUTHENTICATION_PATH = '/reauthenticate'