import React, { useContext } from 'react'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import NTCButton from '../inputs/buttons/NTCButton'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { isSuperAdmin } from 'generated/auth/Permissions'
import { useTranslation } from 'react-i18next'
import { ClientContext } from '../../Context'
import { RenderActionItems } from 'components/renderActionItems/RenderActionItems'


interface OrganizationListProps {
    actionButtonHandler: any
    items?: any[]
}

const OrganizationList = ({ items, actionButtonHandler }: OrganizationListProps) => {
    const theme = useTheme()
    const { client } = useContext(ClientContext)
    const { t } = useTranslation()
    return <>
        <Grid container sx={{ pt: 2 }}>
            {items.map(item => (
                <RenderActionItems
                    key={item.id}
                    item={item}
                    itemType={'org'}
                    permissions={{
                        edit: '',
                        view: '',
                        delete: ''
                    }}
                    actionButtonHandler={actionButtonHandler}
                />
            ))}
        </Grid>
        <Grid container spacing={2} sx={{ pt: 2 }}>
            {isSuperAdmin(client) ?
                <Grid item xs={12} sm={6} md={2}>
                    <NTCButton
                        endIcon={<AddOutlinedIcon />}
                        text={`${t('common.actions.create')} ${t('forms.organization.title')}`}
                        sx={{ px: 4, textTransform: 'none' }}
                        backgroundColor={theme.palette.custom.nomadRed}
                        onClick={() => actionButtonHandler('create')}
                    />
                </Grid> : <></>
            }
        </Grid>
    </>
}

export default OrganizationList
