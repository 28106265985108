import React, { useContext, useEffect, useState } from 'react'
import ModuleWrapper from '../ModuleWrapper'
import { Box, CircularProgress, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UiModuleProps, UiModuleType } from '../UiModuleProps'
import TodayWeather from './TodayWeather/TodayWeather'
import WeatherSearchInput from './TodayWeather/SearchInput'
import { fetchCurrentWeathe, weatherResposne } from 'utils/HTTP/WeatherHttpUtils'
import { ClientContext } from 'Context'

const createWeatherQuery = (client) => {
    return [client.weather_city, client.weather_state, client.weather_country]
        .filter(value => value) // Filter out falsy values (null, undefined, empty string)
        .join(', ');
}


const Weather = ({ id, name, type, config }: UiModuleProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { client } = useContext(ClientContext)

    const [keyword, setKeyword] = useState(createWeatherQuery(client))
    const [loading, setLoading] = useState(false)
    const [currentWeather, setCurrentWeather] = useState({})

    async function getCurrentWeather(keyword) {
        try {
            setLoading(true)
            const data = await fetchCurrentWeathe(keyword)
            setCurrentWeather(data)
        } catch (ex) {
            setCurrentWeather(weatherResposne)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCurrentWeather(keyword)
    }, [keyword])

    return (
        <ModuleWrapper type={type} name={name} id={id} statusElement={null}>
            <WeatherSearchInput setKeyword={setKeyword} keyword={keyword} />

            {loading ? LoadingPanel(theme) :
                <TodayWeather data={currentWeather} />
            }
        </ModuleWrapper>
    )
}

function LoadingPanel(theme) {
    return <Box justifyContent={'center'} display={'flex'} mt={5}>
        <CircularProgress sx={{ color: theme.palette.custom.nomadRed }} />
    </Box>
}

export default Weather