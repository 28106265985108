import React, { useContext } from 'react'
import { Permission } from "generated/auth/Permission"
import { Role } from "generated/auth/Role"
import { Client } from "generated/client/Client"
import { ClientContext } from 'Context'

// Use this function to restrict super admin resource access
export function isSuperAdmin(client: Client): boolean {
    return !!client.roles?.find((role: Role) => role.name === 'super_admin')
}

export function isOrgAdmin(client: Client): boolean {
    return !!client.roles?.find((role: Role) => role.name === 'org_admin')
}

export function isAuthorized(resource: Resource, action: Action): boolean {
    const { client } = useContext(ClientContext)
    if (isSuperAdmin(client)) {
        return true
    }

    return client.permissions.some((permission: Permission) => {
        return permission.resource.toLowerCase() === resource?.toLowerCase()
            && permission.action.toLowerCase() === action?.toLowerCase()
    })
}

enum Resource {
    User = 'User',
    Role = 'Role',
    NtcAsset = 'NtcAsset',
    Training = 'Training',
    Group = 'Group'
}

enum Action {
    List = 'list',
    Read = 'read',
    Update = 'update',
    Create = 'create',
    Delete = 'delete',
    RemoteView = 'remote_view',
    RemoteControl = 'remote_control',
}

export const USER = Resource.User
export const ASSET = Resource.NtcAsset
export const ROLE = Resource.Role
export const TRAINING = Resource.Training
export const GROUP = Resource.Group

export const CREATE = Action.Create
export const LIST = Action.List
export const READ = Action.Read
export const UPDATE = Action.Update
export const DELETE = Action.Delete
export const REMOTE_VIEW = Action.RemoteView
export const REMOTE_CONTROL = Action.RemoteControl