import React from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { InputBase, Paper } from '@mui/material'

/**
 * SearchBoxControl component renders a search box with an icon button.
 * 
 */

const SearchBoxControl = ({ keyword, setKeyword }) => {
    const { t } = useTranslation()

    return (
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, float: 'left' }}>
            <IconButton type='button' sx={{ p: '10px' }} aria-label={t('forms.group.search')}>
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('forms.group.search')}
                inputProps={{ 'aria-label': t('forms.group.title') }}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
            />
        </Paper>
    )
}

export default SearchBoxControl
