import { Asset } from 'generated/asset/Asset'
import { HTTPMethod } from '../HttpType'
import { requestEndpoint } from '../HTTPUtils'
import useFetch from './useFetch'

export const defaultAssetsData = [
    {
        id: 'c814ff61-9b6e-4a93-8e6f-5b7a16cf2a61',
        name: 'Trailer 1'
    },
    {
        id: '4e30c3c4-7e6d-4e2b-94bf-5293e4bce52e',
        keyId: 'tcv2',
        name: 'Trailer 2',
        operation: 'Offline',
        activity: '4 days ago',
        status: 'Stowed'
    },
    {
        id: '85a021d2-0c76-42c8-b1f7-14f29a6f2ea1',
        keyId: 'tcv3',
        name: 'Trailer 3',
        operation: 'Offline',
        activity: '5 minutes ago',
        status: 'Ready'
    }
]

const assetRoutes = '/ntc_assets'

/**
 * HTTP request to get the list of assets, use this function where refresh required specially on List pages
 * @param controller
 */
function fetchAssets() {
    return useFetch<any, Asset[]>(assetRoutes)
}

function getAssetsByGroupId(groupId: any) {
    return useFetch<any, Asset[]>(`${assetRoutes}/groups/${groupId}`)
}

async function fetchAllAssets(data?: any, controller?: AbortController) {
    const endpoint = assetRoutes

    return await requestEndpoint<any, Asset[]>(
        endpoint, data, HTTPMethod.GET, controller
    )
}

async function getAssetById(id: any, controller?: AbortController) {
    const endpoint = `${assetRoutes}/${id}`

    return await requestEndpoint<string, Asset>(
        endpoint, id, HTTPMethod.GET, controller
    )
}

/**
 * Submit the asset basic information to create the entry
 * @param assetEntity
 * @param controller
 */
async function createAsset(assetEntity: any, controller?: AbortController) {
    const endpoint = assetRoutes

    return await requestEndpoint<any, Asset>(
        endpoint, assetEntity, HTTPMethod.POST, controller
    )
}

/**
 * Submit the asset basic information to update the entry
 * @param assetEntity
 * @param controller
 */
async function updateAsset(assetEntity: any, controller?: AbortController) {
    const endpoint = `${assetRoutes}/${assetEntity.id}`
    delete (assetEntity.id) // ID must be in the route path, NOT the form params

    return await requestEndpoint<any, Asset>(
        endpoint, { ntc_asset: assetEntity }, HTTPMethod.PUT, controller
    )
}

/**
 *
 * @param id
 * @param controller
 */
async function deleteAsset(id: string, controller?: AbortController) {
    const endpoint = `${assetRoutes}/${id}`

    return await requestEndpoint<any, any>(
        endpoint, id, HTTPMethod.DELETE, controller
    )
}

async function getAssetLocations(startDate?: string, endDate?: string, controller?: AbortController) {
    const range = `start_date=${startDate}&end_date=${endDate}`
    const endpoint = `/locations?${range}`

    return await requestEndpoint<any, any>(
        endpoint, null, HTTPMethod.GET, controller
    )
}

async function getJwt(assetId: string, controller?: AbortController) {
    const endpoint = `${assetRoutes}/${assetId}/jwt`
    return await requestEndpoint<any, any>(endpoint, null, HTTPMethod.GET, controller)
}

async function transferOwner(data, controller?: AbortController) {
    const endpoint = `${assetRoutes}/${data.asset_id}/transfer`
    delete data.asset_id
    return await requestEndpoint<any, any>(
        endpoint, { ntc_asset: data }, HTTPMethod.PATCH, controller
    )
}

export const AssetApiService = {
    fetchAssets,
    getAssetsByGroupId,
    fetchAllAssets,
    getAssetById,
    createAsset,
    updateAsset,
    deleteAsset,
    getAssetLocations,
    getJwt,
    transferOwner
}