import React, { Fragment, memo } from 'react'
import Typography from '@mui/material/Typography'
import { FormControl, Input, InputAdornment, InputLabel, useTheme } from '@mui/material'
// @ts-ignore
import { ErrorMessage } from '@hookform/error-message'
import IconButton from '@mui/material/IconButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

interface FormPasswordControlProps {
    autoFocus?: boolean,
    register?: any,
    errors?: any,
    watch?: any,
    id: string,
    name: string,
    label: string,
    defaultValue?: string,
    readonly?: boolean,
    type?: string,
    required?: boolean,
    hasCustomError?: boolean
    showPassword?: any,
    handleClickShowPassword?: any,
}

const FormPasswordControl = memo(({
    autoFocus,
    register,
    errors,
    watch,
    name,
    id,
    label,
    defaultValue,
    readonly,
    showPassword,
    handleClickShowPassword,
    type,
    required = true,
    hasCustomError
}: FormPasswordControlProps) => {
    const theme = useTheme()
    const isError = !!errors?.[name]
    const textColor = isError ? theme.palette.custom.nomadRed : `${theme.palette.custom.moduleTitle} !important`
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }
    return (
        <Fragment>
            <FormControl variant="standard" fullWidth={true} sx={{
                '& .MuiFormLabel-root': {
                    color: textColor,
                    position: 'relative',
                    transform: 'translate(0, 1.25rem) scale(0.90)'
                },
                '& .MuiInput-underline::after': {
                    borderBottomColor: theme.palette.custom.defaultText
                }
            }}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Input
                    autoComplete='new-password'
                    autoFocus={autoFocus}
                    inputlabelprops={{ shrink: false }}
                    id={id}
                    name={name}
                    disabled={readonly}
                    required={required}
                    defaultValue={defaultValue}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            {watch(name) &&
                                <IconButton edge="end" onClick={() => navigator.clipboard.writeText(watch(`${name}`))}>
                                    <ContentCopyOutlinedIcon/>
                                </IconButton>}
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    {...register(`${name}`)}
                    error={isError}
                />
            </FormControl>
            {
                !hasCustomError &&
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <Typography sx={
                        { color: theme.palette.custom.nomadRed }}>
                        {message}
                    </Typography>}
                />
            }

        </Fragment>
    )
})

export default FormPasswordControl
