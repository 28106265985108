import React, { useContext, useEffect, useState } from 'react'
import { PageContext, setClientSession, setImpersonating } from '../../Context'
import UserList from './UserList'
import ConfirmModalDialog from '../display/modals/ConfirmModalDialog'
import { useTranslation } from 'react-i18next'
import UserForm from './UserForm'
import UserApiService from 'utils/UserHttpUtils'
import { UserRoleAssignment } from './UserRoleAssignment'
import { handleException } from 'utils/HTTPUtils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormMode } from 'components/inputs/forms/Forms'

export interface UserManagementProps {
    showResponseStatus?: any
}

const UserManagement = ({ showResponseStatus }: UserManagementProps) => {

    const { t } = useTranslation()
    const [action, setAction] = useState(null)
    const [selectedId, setSelectedId] = useState('')
    const [openConfirmDialog, setOpenConfirmDialog] = useState(true)
    const { setLoadingPanel } = useContext(PageContext)

    const responses = UserApiService.fetchUsers()

    const navigate = useNavigate()
    const { hash, pathname } = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            setAction(FormMode.EDIT)
            setSelectedId(id)
        } else if (pathname.includes('create')) {
            setSelectedId(null)
            setAction(FormMode.CREATE)
        } else {
            navigate('/users')
            setAction(null)
            setSelectedId(null)
        }
    }, [hash, navigate])

    const actionButtonHandler = (command, item) => {
        setAction(command)
        setSelectedId(item?.id)
        if (['delete', 'lock', 'unlock', 'confirm_account', 'impersonate'].includes(command)) {
            setOpenConfirmDialog(true)
        } else if (command === 'edit') {
            navigate(`${item.id}/edit`)
        } else if (command === 'create') {
            navigate(`create`)
        } else if (command === 'assign') {
            navigate(`${item.id}/assign`)
        }
    }

    const handleCloseModal = () => {
        navigate('/users')
        responses.refetch()
        setAction(null)
    }

    function filterItemById(id: any) {
        return responses.result?.find(user => user.id === id)
    }

    const onActionClick = (action: string) => {
        let requestPromise
        setLoadingPanel(true)
        if (action === 'delete') {
            requestPromise = UserApiService.deleteUser(selectedId)
        } else if (action === 'confirm_account') {
            requestPromise = UserApiService.resendAccountConfirmEmail({ user: { email: filterItemById(selectedId)?.email } })
        } else if (action === 'lock' || action === 'unlock') {
            requestPromise = UserApiService.updateUserLockStatus(selectedId, action === 'lock')
        } else if (action === 'impersonate') {
            requestPromise = UserApiService.impersonate(responses.result.find(user => user.id === selectedId))
        }

        requestPromise
            .then((res) => {
                showResponseStatus({ success: true, entity: 'User', message: t(`forms.user.notifications.${action}`) })
                if (action === 'impersonate') {
                    setClientSession(res.id)
                    setImpersonating(true)
                    window.location.href = '/'
                }
            })
            .catch(async (ex: any) => {
                const errorMessage = await handleException(ex)
                showResponseStatus({ success: false, entity: 'User', message: errorMessage })
            })
            .finally(() => {
                setAction(null)
                setOpenConfirmDialog(false)
                handleCloseModal()
                setLoadingPanel(false)
            })
    }

    return <>
        <UserList users={responses.result || []}
            actionButtonHandler={actionButtonHandler} />

        {(() => {

            if (action === 'create' || action === 'edit') {
                return (
                    <UserForm
                        userId={selectedId}
                        mode={action}
                        showResponseStatus={showResponseStatus}
                        handleCloseModal={handleCloseModal}
                    />
                )
            }

            if (['delete', 'lock', 'unlock', 'confirm_account', 'impersonate'].includes(action)) {
                return <ConfirmModalDialog
                    modalTitle={t(`forms.user.modal.confirm.${action}.title`)}
                    open={openConfirmDialog}
                    maxWidth={'md'}
                    handleClose={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleCancel={() => {
                        setOpenConfirmDialog(false)
                        handleCloseModal()
                    }}
                    handleConfirm={() => {
                        onActionClick(action)
                    }}
                    confirmMessage={t(`forms.user.modal.confirm.${action}.message`, { user: filterItemById(selectedId) })}
                />
            }

            if (action === 'assign') {
                return <UserRoleAssignment userId={selectedId}
                    handleCloseModal={handleCloseModal} showResponseStatus={showResponseStatus}
                />
            }

            return null
        })()}
    </>
}

export default UserManagement
