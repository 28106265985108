import React, { Fragment, useState } from 'react'
import { Select, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import { ErrorMessage } from '@hookform/error-message'
import { SelectChangeEvent } from '@mui/material/Select'

interface FormSelectControlProps {
    control?: any,
    errors?: any,
    id: string,
    name: string,
    options: { label: string, value: string, disabled?: boolean }[],
    label: string,
    defaultOptionLabel: string,
    defaultValue?: string,
    readonly?: boolean,
    type?: string
    handleChange?
}

const FormSelectControl = ({
    control,
    errors,
    name,
    id,
    label,
    defaultOptionLabel,
    defaultValue,
    options,
    readonly,
    handleChange
}: FormSelectControlProps) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const isError = !!errors[name]

    const [value, setValue] = useState(defaultValue)

    const onChange = (event: SelectChangeEvent) => {
        setValue(event.target.value)
        handleChange(event.target.value)
    }

    return (
        <Fragment>
            <Controller
                control={control}
                name={name}
                defaultValue={value}
                render={({ field }) =>
                    <Select {...field} native id={id} fullWidth onChange={onChange}
                            sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: theme.palette.custom.moduleBorder
                                }
                            }}>

                        <option value="">{defaultOptionLabel}</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}

                    </Select>
                }/>
            <ErrorMessage errors={errors} name={name}
                          render={({ message }) => <Typography variant="inherit"
                                                               sx={{ color: theme.palette.custom.nomadRed }}>{message} </Typography>}
            />

        </Fragment>
    )
}

export {
    FormSelectControl
}
