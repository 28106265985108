import * as React from 'react'
import Grid from '@mui/material/Grid'
import PersonalInformation from './PersonalInformation'
import Security from './Security'
import { Box, useTheme } from '@mui/material'
import WeatherPreferences from './WeatherPreferences'
import NewsPreferences from './NewsPreferences'

interface ProfileProps {
    showResponseStatus: any
    actionHandler: any
}

const Profile = ({ actionHandler, showResponseStatus  }: ProfileProps) => {
    const theme = useTheme()
    return (
        <Box>
            <Grid container justifyContent="start" sx={{ padding: '10px 0px' }}>
                <Grid item sm={12} md={7}>
                    <PersonalInformation {...{ actionHandler, showResponseStatus }}/>
                    <Security showResponseStatus={showResponseStatus}/>
                    <WeatherPreferences showResponseStatus={showResponseStatus}/>
                    <NewsPreferences showResponseStatus={showResponseStatus}/>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Profile
